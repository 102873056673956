import axios, {AxiosRequestConfig} from "axios";
import { Location, NavigateFunction, useNavigate } from "react-router-dom";

export const commonHeader = {
  headers: {
    'api-version' : '1'
  }
} as AxiosRequestConfig

export const tokenAxios = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true
})

export const basicAxios = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true,
  headers: {
    'Authorization' : 'Basic aG9hbmdnaWE6dGhhbmc='
  }
})

export const simpleAxios = axios.create()

const AxiosInterceptorsSetup = (navigate: NavigateFunction, location: Location) => {
  tokenAxios.interceptors.response.use(
      response => response,
      error => {
          if(error.response.status === 401) {
            navigate('/login', {
              state: {from: location},
              replace: true,
            });
          }
          
          return Promise.reject(error);
      }
  );
};

export default AxiosInterceptorsSetup;