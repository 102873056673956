export const genders = [
  {
    id: 0,
    name: 'Nam'
  },
  {
    id: 1,
    name: 'Nữ'
  },
  {
    id: 2,
    name: 'Khác'
  },
]