import { Clear, ClearAll, Close, Search } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import React from "react";

interface SearchTextFieldProps {
  value: string
  placeholder: string
  onChange: React.ChangeEventHandler
  onSubmit: (value: string) => void
}

export function SearchTextField({value, placeholder, onChange, onSubmit,...props}: SearchTextFieldProps) {
  const clear = () => {
    onSubmit('')
  }

  return <TextField
    id="outlined-basic" 
    variant="outlined"
    size="small"
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    onKeyPress={(e: any) => {
      if (e.key === "Enter") {
        e.preventDefault();
        e.target.blur()

        onSubmit(value)
      }
    }}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <Search />
        </InputAdornment>
      ),
      endAdornment: value && value.length > 0 && (
        <InputAdornment position="end" sx={{mr: -1}}>
          <IconButton
            onClick={clear}
          >
            <Clear sx={{fontSize: 20}}/>
          </IconButton>
        </InputAdornment>
      )
    }}
    sx={{
      mr: 1,
      minWidth: 280,
    }}
  />
}