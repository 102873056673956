import {Box, Button, Typography} from "@mui/material";
import { openInNewTabWinBrowser } from "../../common/util/common-utils";

function Footer(props: any) {
  return (
      <Box sx={{
      mb : 2,
      left: 0,
      right: 0,
      bottom: 0,
      position: 'fixed',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>
      <Typography variant="body2" color="text.secondary" align="center">
        Tải ứng dụng Hoàng Gia tại
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          mt: 1,
          mb: 2,
        }}
      >
        <Button
          onClick={() => openInNewTabWinBrowser('https://apps.apple.com/vn/app/b%E1%BA%A5t-%C4%91%E1%BB%99ng-s%E1%BA%A3n-ho%C3%A0ng-gia/id1623141233')}
        >
          <Box component='img' src='/appstore.png'
            sx={{
              height: 50,
              mr: 2,
            }}
          />
        </Button>
        <Button
          onClick={() => openInNewTabWinBrowser('https://play.google.com/store/apps/details?id=vn.hoanggia.app')}
        >
          <Box component='img' src='/playstore.png'
            sx={{
              height: 50,
            }}
          />
        </Button>
      </Box>
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © Hoàng Gia Land '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    </Box>
  )
}

export default Footer