import { Grid } from "@mui/material";
import { useRecoilValueLoadable } from "recoil";
import { CardTitle, HgCard, HgCardContent } from "../../common/components/cards";
import { DropDownLabelButton } from "../../common/components/dropdown_label.button";
import { LabelNumberField } from "../../common/components/label_number_field";
import { LabelTextField } from "../../common/components/text_field_label";
import { citiesState, districtsState, wardsesState } from "../../common/state/location.state";
import { LocationType } from "../../common/types";
import { PhoneNumberFormat } from "../../common/util/number_format";
import { genders } from "../constants";
import { DateLabelButton } from "./date_label.button";

export function PersonalInfoCard(props: any) {
  const {formik} = props
  const cityList = useRecoilValueLoadable(citiesState)
  const cities: LocationType[] = cityList.state === 'hasValue' ? cityList.contents : []
  const districtsAV = useRecoilValueLoadable(districtsState(formik.values.city?.id ?? -1))
  const districts: LocationType[] = districtsAV.state === 'hasValue' ? districtsAV.contents : []
  const wardsesAV = useRecoilValueLoadable(wardsesState(formik.values.district?.id ?? -1))
  const wardses: LocationType[] = wardsesAV.state == 'hasValue' ? wardsesAV.contents : []

  const handleCityChanged = (e: any) => {
    formik.setFieldValue('district', null)
    formik.setFieldValue('wards', null)
    formik.setFieldValue('city', e)
  }

  const handleDistrictChanged = (e: any) => {
    formik.setFieldValue('wards', null)
    formik.setFieldValue('district', e)
  }

  const handleWardsChanged = (e: any) => {
    formik.setFieldValue('wards', e)
  }

  const handleGenderChange = (e: any) => formik.setFieldValue('gender', e)

  return <Grid
    container
    rowSpacing={2}
    columnSpacing={4}
    columns={2}
    sx={{
      mt: 2,
    }}
  >
    <Grid item sm={2}>
      <LabelTextField
        id='fullName'
        text={formik.values.fullName}
        handleChange={formik.handleChange}
        error={formik.touched.fullName && Boolean(formik.errors.fullName)}
        helperText={formik.touched.fullName && formik.errors.fullName}
        label='Họ và tên'
        required={true}
        placeholder='Nhập đầy đủ họ và tên...'
      />
    </Grid>
    <Grid item sm={2}>
      <LabelTextField
        id='email'
        text={formik.values.email}
        handleChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
        label='Email'
        required={true}
        placeholder='Nhập email...'
      />
    </Grid>
    <Grid item sm={2}>
      <LabelNumberField
        id='phone'
        text={formik.values.phone}
        handleChange={formik.handleChange}
        error={formik.touched.phone && Boolean(formik.errors.phone)}
        helperText={formik.touched.phone && formik.errors.phone}
        label='Số điện thoại'
        required={true}
        placeholder='Nhập số điện thoại...'
        numberFormat={PhoneNumberFormat}
      />
    </Grid>
    <Grid item sm={2}>
      <LabelTextField
        id='address'
        text={formik.values.address}
        handleChange={formik.handleChange}
        error={formik.touched.address && Boolean(formik.errors.address)}
        helperText={formik.touched.address && formik.errors.address}
        label='Địa chỉ'
        required={false}
        placeholder='Nhập số nhà, ngõ, ngách, hẻm...'
        inputProps={{
          style: {textTransform: 'capitalize'}
        }}
      />
    </Grid>
    <Grid item sm = {1}>
      <DropDownLabelButton
        id='city'
        item={formik.values.city}
        handleChange={handleCityChanged}
        items={cities}
        label='Tỉnh/Thành phố'
        required={true}
        placeholder='Chọn tỉnh/thành phố'
        error={formik.touched.city && Boolean(formik.errors.city)}
        helperText={formik.touched.city && formik.errors.city}
      />
    </Grid>
    <Grid item sm = {1}>
      <DropDownLabelButton
        id='district'
        item={formik.values.district}
        handleChange={handleDistrictChanged}
        items={districts}
        label='Quận/Huyện'
        required={true}
        placeholder='Chọn quận/huyện'
        error={formik.touched.district && Boolean(formik.errors.district)}
        helperText={formik.touched.district && formik.errors.district}
      />
    </Grid>
    <Grid item sm = {1}>
      <DropDownLabelButton
        id='wards'
        item={formik.values.wards}
        handleChange={handleWardsChanged}
        items={wardses}
        label='Phường/Xã'
        required={true}
        placeholder='Chọn phường/xã'
        error={formik.touched.wards && Boolean(formik.errors.wards)}
        helperText={formik.touched.wards && formik.errors.wards}
      />
    </Grid>
    <Grid item sm = {1}>
    </Grid>
    <Grid item sm = {1}>
      <DropDownLabelButton
        id='gender'
        item={formik.values.gender}
        handleChange={handleGenderChange}
        items={genders}
        label='Giới tính'
        required={true}
        placeholder='Nhập giới tính'
      />
    </Grid>
    <Grid item sm={1}>
      <DateLabelButton
        id='birthday'
        value={formik.values.birthday}
        handleChange={(value: any) => formik.setFieldValue('birthday', value)}
        label='Ngày sinh'
      />
    </Grid>
  </Grid>
}