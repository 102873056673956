import { Box, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { style } from "@mui/system";
import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { RecoilRoot, useRecoilRefresher_UNSTABLE, useRecoilState, useRecoilValue, useRecoilValueLoadable } from "recoil";
import { HgPaper } from "../../common/components/cards";
import DrawerHeader from "../../common/components/drawer.header";
import { ErrorBoundary } from "../../common/components/error_boundary";
import { HGAvatar } from "../../common/components/hg_avatar";
import { Suspense } from "../../common/components/suspense";
import { ViewTitle } from "../../common/components/views";
import { MemberSearchPanel } from "../components/search_panel";
import { currentPageState, memberListState, membersState, paginationState } from "../states/member_list.state";

export function MemberListView() {
  return <RecoilRoot>
    <DrawerHeader />
    <ViewTitle>
      Danh sách thành viên
    </ViewTitle>
    <MemberSearchPanel
      sx={{
        mt: 2,
        mb: 2,
      }}
    />
    <ErrorBoundary>
        <Suspense>
          <MemberTable/>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              mb: 2,
              mt: 4
            }}
          >
            <MemberListPagination/>
          </Box>
        </Suspense>
      </ErrorBoundary>
  </RecoilRoot>
}

function MemberTable() {
  const members = useRecoilValue(membersState)
  const dataLoadable = useRecoilValueLoadable(memberListState)
  const refresh = useRecoilRefresher_UNSTABLE(memberListState)
  const tableRef = React.createRef<HTMLTableElement>()
  const navigate = useNavigate()

  const handleRowClick = (member: any) => () => {
    navigate(`/employee/member/${member.id}`)
  }

  useEffect(() => {
    if(dataLoadable.state != 'loading') {
      refresh()
    }
  }, [])

  useEffect(() => {
    tableRef?.current?.scrollIntoView()
  }, [members])

  return <HgPaper>
    <TableContainer 
      style={{
        height: "calc(100vh - 340px)"
      }}
    >
    <Table 
      stickyHeader 
      aria-label="sticky table"
      ref={tableRef}
    >
      <TableHead>
        <TableRow>
          {columns.map((column) => (
            <TableCell
              key={column.id}
              align={column.align}
              style={{ 
                minWidth: column.minWidth,
                fontSize: 14,
                fontWeight: 700,
              }}
            >
              {column.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody
      >
        {members.map((row: any, index: number) => (
          <TableRow key={index} hover onClick={handleRowClick(row)}>
            {columns.map((col) => {
              const value = row[col.id]
              return (
                <TableCell 
                  key={col.id} 
                  align={col.align} 
                  sx={{
                    fontSize: col.fontSize,
                    fontWeight: col.fontWeight
                  }}
                >
                    {col.id === 'avatar' ? <HGAvatar 
                      fullName={row['fullName']}
                      avatarUrl={value}
                    /> : value}
                </TableCell>
              )
            })}
          </TableRow>
        ))}
      </TableBody>
    </Table>
    </TableContainer>
  </HgPaper>
}

function MemberListPagination(props: any) {
  const pagin = useRecoilValue(paginationState)
  const [_, setCurrentPage] = useRecoilState(currentPageState)

  const handleOnPageChanged = (event: any, page: number) => {
    setCurrentPage(page)
  }

  return pagin.total === 0 ? null : <Pagination
    count={pagin.total}
    variant="outlined"
    shape="rounded"
    onChange={handleOnPageChanged}
  />
}

interface Column {
  id: 'order' | 'avatar' | 'fullName' | 'title' | 'dept' | 'hometown' | 'joinedDate'
  label: string
  minWidth?: number
  align?: 'right' | 'center' | 'left'
  fontSize: number
  fontWeight: number
}

const columns: readonly Column[] = [
  {
    id: 'order',
    label: 'STT',
    minWidth: 30,
    align: 'center',
    fontSize: 16,
    fontWeight: 500
  },
  {
    id: 'avatar',
    label: '',
    minWidth: 40,
    align: 'center',
    fontSize: 16,
    fontWeight: 500
  },
  {
    id: 'fullName',
    label: 'Họ và tên',
    minWidth: 140,
    align: 'left',
    fontSize: 16,
    fontWeight: 600
  },
  {
    id: 'title',
    label: 'Chức danh',
    minWidth: 100,
    align: 'center',
    fontSize: 16,
    fontWeight: 500
  },
  {
    id: 'dept',
    label: 'Khối/Phòng',
    minWidth: 140,
    align: 'left',
    fontSize: 16,
    fontWeight: 500
  },
  {
    id: 'hometown',
    label: 'Quê quán',
    minWidth: 160,
    align: 'left',
    fontSize: 16,
    fontWeight: 500
  },
  {
    id: 'joinedDate',
    label: 'Ngày gia nhập',
    minWidth: 100,
    align: 'center',
    fontSize: 16,
    fontWeight: 500
  }
]