import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel, List, ListItemButton, ListItemText,
  MenuItem,
  Popover,
  Select,
  SelectChangeEvent, Slider, TextField,
  Typography
} from "@mui/material";
import {ArrowDropDown} from "@mui/icons-material";
import {useRecoilState, useRecoilTransaction_UNSTABLE} from "recoil";
import {
  furnitureFilterState,
  legalFilterState, numBedFilterState, numFloorFilterState, numToiletFilterState, rangeAreaFilterState,
  rangeFrontFilterState,
  rangePriceFilterState
} from "../state/property_list.state";
import {
  areaStep,
  frontStep,
  furnitureTypeFilters,
  legalTypeFilters,
  maxArea,
  maxFront,
  maxPrice, numBedTypes, numFloorTypes, numToiletTypes,
  priceStep, rangeAreas, rangeHouseFronts,
  rangePrices
} from "../constants";
import {
  rangeFromSliderValues,
  sliderValuesFromRange,
  titleFromRangeArea,
  titleFromRangeFront,
  titleFromRangePrice
} from "../utils";
import { grey } from "@mui/material/colors";
import styled from "@emotion/styled";

export function PropertyFilterPanel(props: any) {
  return <Grid
    container
    columnSpacing={{xs: 1, xl: 2}}
    rowSpacing={1}
    alignItems='flex-end'
  >
    <Grid item>
      <RangePrice/>
    </Grid>
    <Grid item>
      <RangeArea/>
    </Grid>
    <Grid item>
      <RangeFront/>
    </Grid>
    <Grid item>
      <LegalTypeSelect/>
    </Grid>
    <Grid 
      item
      // sx={{
      //   display: {sm: 'none', lg: 'block'}
      // }}
    >
      <FurnitureTypeSelect/>
    </Grid>
    <Grid 
      item
      // sx={{
      //   display: {sm: 'none', lg: 'block'}
      // }}
    >
      <NumFloorSelect/>
    </Grid>
    <Grid 
      item
      // sx={{
      //   display: {sm: 'none', lg: 'block'}
      // }}
    >
      <NumBedSelect/>
    </Grid>
    <Grid 
      item
      // sx={{
      //   display: {sm: 'none', lg: 'block'}
      // }}
    >
      <NumToiletSelect/>
    </Grid>
    <Grid 
      item
      // sx={{
      //   display: {sm: 'none', lg: 'block'}
      // }}
    >
      <ResetButton/>
    </Grid>
  </Grid>
}

function RangePrice(props: any) {
  const [rangePriceFilter, setRangePriceFilter] = useRecoilState(rangePriceFilterState)

  const handleRangeChanged = (value: any) => {
    setRangePriceFilter(value)
  }

  return <RangeValue
    label='Khoảng giá'
    range={rangePriceFilter}
    setRange={handleRangeChanged}
    transform={titleFromRangePrice}
    suggestRanges={rangePrices}
    max={maxPrice}
    min={0}
    step={priceStep}
    sx={{
      minWidth: 160,
    }}
  />
}

function RangeArea(props: any) {
  const [rangeAreaFilter, setRangeAreaFilter] = useRecoilState(rangeAreaFilterState)

  return <RangeValue
    label='Diện tích'
    range={rangeAreaFilter}
    setRange={setRangeAreaFilter}
    transform={titleFromRangeArea}
    suggestRanges={rangeAreas}
    max={maxArea}
    min={0}
    step={areaStep}
    sx={{
      minWidth: 160
    }}
  />
}

function RangeFront(props: any) {
  const [rangeFrontFilter, setRangeFrontFilter] = useRecoilState(rangeFrontFilterState)

  return <RangeValue
    label='Mặt tiền'
    range={rangeFrontFilter}
    setRange={setRangeFrontFilter}
    transform={titleFromRangeFront}
    suggestRanges={rangeHouseFronts}
    max={maxFront}
    min={0}
    step={frontStep}
    sx={{
      minWidth: 120
    }}
  />
}

function LegalTypeSelect(props: any) {
  const [legalFilter, setLegalFilter] = useRecoilState(legalFilterState)

  return <TypeSelect
    type={legalFilter}
    setType={setLegalFilter}
    types={legalTypeFilters}
    label='TT pháp lý'
    sx={{
      minWidth: 110
    }}
  />
}

function FurnitureTypeSelect(props: any) {
  const [furnitureFilter, setFurnitureFilter] = useRecoilState(furnitureFilterState)

  return <TypeSelect
    type={furnitureFilter}
    setType={setFurnitureFilter}
    types={furnitureTypeFilters}
    label='TT nội thất'
    sx={{
      minWidth: 100
    }}
  />
}

function NumFloorSelect(props: any) {
  const {sx} = props
  const [numFloor, setNumFloor] = useRecoilState(numFloorFilterState)

  return <TypeSelect
    type={numFloor}
    setType={setNumFloor}
    types={numFloorTypes}
    label='Số tầng'
    sx={{
      minWidth: 110
    }}
  />
}

function NumBedSelect(props: any) {
  const {sx} = props
  const [numBed, setNumBed] = useRecoilState(numBedFilterState)

  return <TypeSelect
    type={numBed}
    setType={setNumBed}
    types={numBedTypes}
    label='Số phòng ngủ'
    sx={{
      minWidth: 120
    }}
  />
}

function NumToiletSelect(props: any) {
  const {sx} = props
  const [numToilet, setNumToilet] = useRecoilState(numToiletFilterState)

  return <TypeSelect
    type={numToilet}
    setType={setNumToilet}
    types={numToiletTypes}
    label='Số phòng toilet'
    sx={{
      minWidth: 120
    }}
  />
}

function RangeValue(props: any) {
  const {label, range, setRange, suggestRanges, max, min, step, transform, sx} = props

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const [localRange, setLocalRange] = useState(range)

  useEffect(() => {
    setLocalRange(range)
  },[range])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event: Event, newValue: number | number[]) => {
    setLocalRange(rangeFromSliderValues(newValue as number[]))
  };

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    range: any
  ) => {
    setLocalRange(range)
    setAnchorEl(null)
  };

  const handleTextFromChanged = (event: React.ChangeEvent<HTMLInputElement>) => {

  };

  const handleTextToChanged = (event: React.ChangeEvent<HTMLInputElement>) => {

  };

  const handleExited = () => {
    setRange(localRange)
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return <Box
    sx={[
      {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch'
      },
      ...(Array.isArray(sx) ? sx : [sx])
    ]}
  >
    <Label
      sx={{
        mb: 0.5
      }}
    >
      {label}
    </Label>
    <GreyOutlineButton
      aria-describedby={id}
      variant="outlined"
      onClick={handleClick}
      endIcon={<ArrowDropDown/>}
      sx={{
        width: 1
      }}
    >
      {transform(localRange)}
    </GreyOutlineButton>
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      TransitionProps={{
        onExited: handleExited
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          height: 300,
        }}
      >
        <Box
          sx={{
            width: 300,
            p: 2,
            borderBottom: 0.5,
            borderColor: grey[400]
          }}
        >
          <Slider
            value={sliderValuesFromRange(localRange)}
            onChange={handleChange}
            valueLabelDisplay="off"
            max={max}
            min={min}
            step={step}
          />
        </Box>
        <List
          sx={{
            maxHeight: '100%',
            maxWidth: '100%',
            overflow: 'auto'
          }}
        >
          {suggestRanges.map((e: any, i: number) => <ListItemButton
            key={i}
            onClick={(event) => handleListItemClick(event, e)}
          >
            <ListItemText>
              {transform(e)}
            </ListItemText>
          </ListItemButton>)}
        </List>
      </Box>
    </Popover>
  </Box>
}

function TypeSelect(props: any) {
  const {type, setType, types, label, sx} = props

  const handleChange = (event: SelectChangeEvent) => {
    setType(event.target.value)
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    range: any
  ) => {
    setType(range)
    setAnchorEl(null)
  };

  return <Box
    sx={[
      {
        display: 'flex',
        flexDirection: 'column',
      },
      ...(Array.isArray(sx) ? sx : [sx])
    ]}
  >
    <Label
      sx={{
        mb: 0.5
      }}
    >
      {label}
    </Label>
    <GreyOutlineButton
      aria-describedby={id}
      variant="outlined"
      onClick={handleClick}
      endIcon={<ArrowDropDown/>}
    >
      {type.brief}
    </GreyOutlineButton>
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <List
        sx={{
          maxHeight: '100%',
          maxWidth: '100%',
          overflow: 'auto'
        }}
      >
        {types.map((e: any, i: number) => <ListItemButton
          key={i}
          onClick={(event) => handleListItemClick(event, e)}
        >
          <ListItemText>
            {e.description}
          </ListItemText>
        </ListItemButton>)}
      </List>
    </Popover>
  </Box>
}

function ResetButton() {
  const reset = useRecoilTransaction_UNSTABLE(({reset}) => () => {
    reset(rangePriceFilterState)
    reset(rangeAreaFilterState)
    reset(rangeFrontFilterState)
    reset(legalFilterState)
    reset(furnitureFilterState)
    reset(numFloorFilterState)
    reset(numBedFilterState)
    reset(numToiletFilterState)
  })

  return <Button
    style={{
      backgroundColor: '#e7e7e7',
      color: '#ff9c02',
      paddingLeft: 10, paddingRight: 10,
      fontSize: 12,
      paddingTop: 8,
      paddingBottom: 8
    }}
    onClick={reset}
  >
    Đặt lại
  </Button>
}

function NumberStepper(props: any) {

}

const GreyOutlineButton = styled(Button)(() => ({
  "&.MuiButton-root": {
    border: "0.5px grey solid"
  },
  "&.MuiButton-outlined": {
    color: "grey"
  },
  textTransform: 'none',
  justifyContent: 'space-between',
}))

const Label = styled(Typography)(() => ({
  fontSize: 12,
  fontWeight: 600,
  color: '#000',
}))