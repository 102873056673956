import { ArrowCircleLeft, ArrowCircleRight } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { ReactImageGalleryItem } from "react-image-gallery";

interface NavigationButton {
  onClick: React.MouseEventHandler<HTMLElement>
  disabled: boolean
}

export function RightNav({onClick, disabled} : NavigationButton) {
  return <Box
    sx={{
      height: 1,
      right: 0,
      position: 'absolute',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      zIndex: 2
    }}
  >
    <IconButton
    onClick={onClick}
    disabled={disabled}
  >
    <ArrowCircleRight style={{ fontSize: 50, color: disabled ? '#888888' : '#dcdcdc' }}/>
  </IconButton>
  </Box>
}

export function LeftNav({onClick, disabled} : NavigationButton) {
  return <Box
    sx={{
      height: 1,
      position: 'absolute',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      zIndex: 2
    }}
  >
    <IconButton
    onClick={onClick}
    disabled={disabled}
  >
    <ArrowCircleLeft style={{ fontSize: 50, color: disabled ? '#888888' : '#dcdcdc' }}/>
  </IconButton>
  </Box>
}

interface ImageProps {
  item: ReactImageGalleryItem
}

export function ImageContentBlur({item} : ImageProps) {
  return <Box
    sx={{
      width: 1,
      height: item.originalHeight ?? 'calc(91vh - 80px)',
      borderRadius: 2,
      bgcolor: '#eeeeee',
      overflow: 'hidden',
      position: 'relative'
    }}
  >
    <Box
      component='img'
      src={item.original}
      sx={{
        width: 1,
        height: 1,
        objectFit: 'cover',
        filter: 'blur(20px) brightness(50%)',
        position: 'absolute',
        top: 0,
        left: 0
      }}
      loading="lazy"
    />
    <Box
      component='img'
      src={item.original}
      sx={{
        width: 1,
        height: 1,
        objectFit: 'contain',
        position: 'absolute',
        top: 0,
        left: 0
      }}
      loading="lazy"
    />
  </Box>
}

export function ImageContent({item} : ImageProps) {
  return <Box
    component='img'
    src={item.original}
    sx={{
      width: 1,
      height: item.originalHeight ?? 'calc(91vh - 80px)',
      objectFit: 'contain',
    }}
    loading="lazy"
  />
  
}

export function ImageThumbnail({item} : ImageProps) {
  return <Box
    component='img'
    src={item.thumbnail}
    sx={{
      width: item.thumbnailWidth! - 8,
      height: item.thumbnailHeight!,
      objectFit: 'cover',
      ml: '-1px',
      mt: '2px'
    }}
    loading="lazy"
  />
}