interface ValueType {
  value: number
  brief: string
  description: string
}

export const propertyStatuses: ValueType[] = [
  {
    value: 1,
    brief: 'Đang bán',
    description: 'Đang bán',
  },
  {
    value: 2,
    brief: 'Đã bán',
    description: 'Đã bán bởi Hoàng Gia',
  },
  {
    value: 3,
    brief: 'Dừng bán',
    description: 'Dừng bán',
  },
  {
    value: 4,
    brief: 'Đã bán',
    description: 'Đã bán bởi chủ nhà',
  },
]

export const legalTypeFilters: ValueType[] = [
  {
    value: 0,
    brief: 'Tất cả',
    description: 'Tất cả',
  },
  {
    value: 1,
    brief: 'Sổ đỏ',
    description: 'Đã có sổ đỏ',
  },
  {
    value: 2,
    brief: 'Sổ hồng',
    description: 'Đã có sổ hồng',
  },
  {
    value: 3,
    brief: 'N/A',
    description: 'Chưa xác định',
  },
]

export const legalTypes: ValueType[] = [
  {
    value: 0,
    brief: 'Sổ đỏ',
    description: 'Đã có sổ đỏ',
  },
  {
    value: 1,
    brief: 'Sổ hồng',
    description: 'Đã có sổ hồng',
  },
  {
    value: 2,
    brief: 'N/A',
    description: 'Chưa xác định',
  },
]

export const furnitureTypeFilters: ValueType[] = [
  {
    value: 0,
    brief: 'Tất cả',
    description: 'Tất cả'
  },
  {
    value: 1,
    brief: 'Đẩy đủ',
    description: 'Đẩy đủ'
  },
  {
    value: 2,
    brief: 'N/A',
    description: 'Chưa xác định'
  },
]

export const furnitureTypes: ValueType[] = [
  {
    value: 0,
    brief: 'Đẩy đủ',
    description: 'Đẩy đủ'
  },
  {
    value: 1,
    brief: 'N/A',
    description: 'Chưa xác định'
  },
]

export const numFloorTypes: ValueType[] = [
  {
    value: 0,
    brief: 'Tất cả',
    description: 'Tất cả'
  },
  {
    value: 2,
    brief: '≥ 2 tầng',
    description: 'Từ 2 tầng trở lên'
  },
  {
    value: 3,
    brief: '≥ 3 tầng',
    description: 'Từ 3 tầng trở lên'
  },
  {
    value: 4,
    brief: '≥ 4 tầng',
    description: 'Từ 4 tầng trở lên'
  },
  {
    value: 5,
    brief: '≥ 5 tầng',
    description: 'Từ 5 tầng trở lên'
  },
  {
    value: 6,
    brief: '≥ 6 tầng',
    description: 'Từ 6 tầng trở lên'
  },
  {
    value: 7,
    brief: '≥ 7 tầng',
    description: 'Từ 7 tầng trở lên'
  },
  {
    value: 8,
    brief: '≥ 8 tầng',
    description: 'Từ 8 tầng trở lên'
  },
  {
    value: 9,
    brief: '≥ 9 tầng',
    description: 'Từ 9 tầng trở lên'
  },
]

export const numBedTypes: ValueType[] = [
  {
    value: 0,
    brief: 'Tất cả',
    description: 'Tất cả'
  },
  {
    value: 2,
    brief: '≥ 2 phòng',
    description: 'Từ 2 phòng ngủ trở lên'
  },
  {
    value: 3,
    brief: '≥ 3 phòng',
    description: 'Từ 3 phòng ngủ trở lên'
  },
  {
    value: 4,
    brief: '≥ 4 phòng',
    description: 'Từ 4 phòng ngủ trở lên'
  },
  {
    value: 5,
    brief: '≥ 5 phòng',
    description: 'Từ 5 phòng ngủ trở lên'
  },
  {
    value: 6,
    brief: '≥ 6 phòng',
    description: 'Từ 6 phòng ngủ trở lên'
  },
  {
    value: 7,
    brief: '≥ 7 phòng',
    description: 'Từ 7 phòng ngủ trở lên'
  },
  {
    value: 8,
    brief: '≥ 8 phòng',
    description: 'Từ 8 phòng ngủ trở lên'
  },
  {
    value: 9,
    brief: '≥ 9 phòng',
    description: 'Từ 9 phòng ngủ trở lên'
  },
]

export const numToiletTypes: ValueType[] = [
  {
    value: 0,
    brief: 'Tất cả',
    description: 'Tất cả'
  },
  {
    value: 2,
    brief: '≥ 2 phòng',
    description: 'Từ 2 phòng toilet trở lên'
  },
  {
    value: 3,
    brief: '≥ 3 phòng',
    description: 'Từ 3 phòng toilet trở lên'
  },
  {
    value: 4,
    brief: '≥ 4 phòng',
    description: 'Từ 4 phòng toilet trở lên'
  },
  {
    value: 5,
    brief: '≥ 5 phòng',
    description: 'Từ 5 phòng toilet trở lên'
  },
  {
    value: 6,
    brief: '≥ 6 phòng',
    description: 'Từ 6 phòng toilet trở lên'
  },
  {
    value: 7,
    brief: '≥ 7 phòng',
    description: 'Từ 7 phòng toilet trở lên'
  },
  {
    value: 8,
    brief: '≥ 8 phòng',
    description: 'Từ 8 phòng toilet trở lên'
  },
  {
    value: 9,
    brief: '≥ 9 phòng',
    description: 'Từ 9 phòng toilet trở lên'
  },
]

interface RangeValueType {
  from: number
  to: number
}

export const rangePrices: RangeValueType[] = [
  {
    from: 0,
    to: 0,
  },
  {
    from: 0,
    to: 500000000,
  },
  {
    from: 500000000,
    to: 800000000,
  },
  {
    from: 800000000,
    to: 1000000000,
  },
  {
    from: 1000000000,
    to: 2000000000,
  },
  {
    from: 2000000000,
    to: 3000000000,
  },
  {
    from: 3000000000,
    to: 5000000000,
  },
  {
    from: 5000000000,
    to: 7000000000,
  },
  {
    from: 7000000000,
    to: 10000000000,
  },
  {
    from: 10000000000,
    to: 20000000000,
  },
  {
    from: 20000000000,
    to: 30000000000,
  },
  {
    from: 30000000000,
    to: 30000000000,
  },
]

export const rangeAreas: RangeValueType[] = [
  {
    from: 0,
    to: 0
  },
  {
    from: 0,
    to: 30
  },
  {
    from: 30,
    to: 50
  },
  {
    from: 50,
    to: 80
  },
  {
    from: 80,
    to: 100
  },
  {
    from: 100,
    to: 150
  },
  {
    from: 150,
    to: 200
  },
  {
    from: 200,
    to: 250
  },
  {
    from: 250,
    to: 300
  },
  {
    from: 300,
    to: 500
  },
  {
    from: 500,
    to: 500
  },
]

export const rangeHouseFronts: RangeValueType[] = [
  {
    from: 0,
    to: 0
  },
  {
    from: 0,
    to: 3
  },
  {
    from: 3,
    to: 4
  },
  {
    from: 4,
    to: 5
  },
  {
    from: 5,
    to: 7
  },
  {
    from: 7,
    to: 10
  },
  {
    from: 10,
    to: 10
  },
]

export const priceStep = 200000000
export const maxPrice = 30000000000
export const areaStep = 5
export const maxArea = 500
export const frontStep = 0.5
export const maxFront = 10

export const DeptSorts = [
  {
    query: 'created_desc',
    description: 'Sắp xếp theo ngày tạo'
  },
  {
    query: 'most_member',
    description: 'Nhiều thành viên nhất'
  },
  {
    query: 'name_asc',
    description: 'Tên tăng dần'
  },
  {
    query: 'name_desc',
    description: 'Tên giảm dần'
  },
]

export const MemberSorts = [
  {
    query: 'title_desc',
    description: 'Sắp xếp theo chức danh'
  },
  {
    query: 'dept_desc',
    description: 'Sắp xếp theo khối/phòng'
  },
  {
    query: 'name_asc',
    description: 'Tên tăng dần'
  },
  {
    query: 'name_desc',
    description: 'Tên giảm dần'
  },
]

export const SortBys = [
  {
    query: 'recently',
    description: 'Cập nhật gần đây'
  },
  {
    query: 'most_view',
    description: 'Xem nhiều nhất'
  },
  {
    query: 'most_like',
    description: 'Yêu thích nhất'
  },
  {
    query: 'price_asc',
    description: 'Giá tăng dần'
  },
  {
    query: 'price_desc',
    description: 'Giá giảm dần'
  },
  {
    query: 'area_asc',
    description: 'Diện tích tăng dần'
  },
  {
    query: 'area_desc',
    description: 'Diện tích giảm dần'
  },
  {
    query: 'created_asc',
    description: 'Ngày đăng tin tăng dần'
  },
  {
    query: 'created_desc',
    description: 'Ngày đăng tin giảm dần'
  },
]