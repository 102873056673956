import { Dialog, DialogContent } from "@mui/material"
import { AxiosError } from "axios"
import { useFormik } from "formik"
import { useSnackbar } from "notistack"
import { useRecoilRefresher_UNSTABLE } from "recoil"
import * as yup from 'yup'
import { tokenAxios } from "../../common/axios"
import { DialogActionConfirm } from "../../common/components/confirm_action.dialog"
import { DialogTitleClose } from "../../common/components/title_close.dialog"
import { propertyDescriptionEndpoint } from "../../common/endpoints"
import { urlWithId } from "../../common/util/common-utils"
import { kUnknownError } from "../../common/util/constants"
import { setAlert } from "../../redux/alert.slice"
import { useAppDispatch } from "../../redux/hooks"
import { propertyDetailState } from "../state/property_detail.state"
import { DescriptionContent } from "./description.content"

export function PropertyDescriptionDialog(props: any) {
  const { open, setOpen, property} = props
  const refreshDetail = useRecoilRefresher_UNSTABLE(propertyDetailState(`${property.id}`))
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const formik = useFormik({
    initialValues: {
      description: property.description
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const {url, ...config} = propertyDescriptionEndpoint
      try {
        await tokenAxios.request({
          url: urlWithId(url, `${property.id}`),
          ...config,
          data: {
            description: values.description
          }
        })
        setOpen(false)
        enqueueSnackbar('Đã cập nhật mô tả BĐS')
        setTimeout(() => {
          refreshDetail()
        }, 300)
      } catch(e) {
        const msg = e instanceof AxiosError ? e.message : kUnknownError
        dispatch(setAlert({open: true, content: msg}))
      }
    }
  })

  const handleClose = () => {
    formik.resetForm()
    setOpen(false)
  }

  return <Dialog
    maxWidth='md'
    fullWidth
    open={open}
  >
    <DialogContent>
      <DialogTitleClose
        title='Sửa mô tả BĐS'
        onClose={handleClose}
      />
      <form onSubmit={formik.handleSubmit}>
        <DescriptionContent formik={formik}/>
        <DialogActionConfirm/>
      </form>
      </DialogContent>
  </Dialog>
}

const validationSchema = yup.object({
  description: yup
    .string()
    .min(30, 'Mô tả tối thiểu 30 ký tự')
    .max(5000, 'Mô tả tối đa 5000 ký tự')
    .required('Mô tả về bất động sản là bắt buộc'),
})