import { Box, Button, TextField } from "@mui/material";
import { CardLabel } from "../../common/components/cards";
import { MinusIcon, PlusIcon } from "../../icons";
import { NumberFormatCustom } from "../../common/util/number_format";

export function NumberStepperRow(props: any) {
  const {label, text, setText, sx} = props

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(event.target.value == '') {
      setText(0)
    } else {
      setText(event.target.value);
    }
    
  };

  const minusDisabled = text == 0

  const handlePlusClicked = () => {
    setText(parseInt(text) + 1)
  }

  const handleMinusClicked = () => {
    setText(parseInt(text) < 1 ? 0 : text - 1)
  }

  return <Box
    sx={[
      {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        pt: 1,
        pb: 1,
      },
      ...(Array.isArray(sx) ? sx : [sx])
    ]}
  >
    <CardLabel>
      {label}
    </CardLabel>
    <Box flexGrow={1}/>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      }}
    >
      <Button
        onClick={handleMinusClicked}
      >
        <MinusIcon/>
      </Button>
      <TextField
        id="outlined-basic" 
        variant="outlined"
        size="small"
        value={text ?? 0}
        onChange={handleChange}
        sx={{
          width: 70,
          input: {textAlign: "center"}
        }}
        InputProps={{
          inputComponent: NumberFormatCustom as any,
        }}
      />
      <Button
        onClick={handlePlusClicked}
      >
        <PlusIcon/>
      </Button>
    </Box>
  </Box>
}