import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import { NumberFormatCustom } from "../util/number_format";
import { CardLabel } from "./cards";

export function LabelNumberField(props: any) {
  const {id, text, setText, error, helperText, placeholder, label, required, unit, handleChange, numberFormat} = props

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  return <Box
    display='flex'
    flexDirection='column'
  >
    <CardLabel>
      {label}
      {(required ?? false) && <Typography
        component='span'
        display='inline'
        sx={{
          ml: 0.5,
        }}
        style={{
          color: 'rgba(255, 0, 0, 1)'
        }}
      >*</Typography>}
    </CardLabel>
    <TextField
      id={id ?? 'tf'}
      name={id ?? 'tf'}
      variant="outlined"
      size="small"
      placeholder={placeholder}      
      value={text ?? ''}
      onChange={handleChange ?? handleTextChange}
      error={error}
      helperText={helperText}
      sx={{
        mt: 0.5
      }}
      InputProps={{
        inputComponent: numberFormat ??  NumberFormatCustom as any,
        endAdornment: <InputAdornment position="end">{unit}</InputAdornment>
      }}
    />
  </Box>
}