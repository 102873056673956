import {Box, Container} from "@mui/material";
import Footer from "../components/footer";
import HgAppBar from "../components/appbar";

function PrivatePolicy() {
  return <Container maxWidth='md'>
    <HgAppBar/>
    <Box sx={{mt: 12,}}>
      <div>
        <span><p >
          <span>
          <h2 style={{textAlign: "center"}}>CHÍNH SÁCH BẢO MẬT THÔNG TIN</h2>
          </span></p>
          <br/>
          <p><strong>1. Mục đích và phạm vi thu thập thông tin:</strong></p><p
      >Hoàng Gia luôn cố gắng để những thông tin được đăng trên ứng dụng Hoàng Gia là hữu ích và chính xác nhất. Để thực hiện điều đó, Hoàng Gia yêu cầu thành viên phải cung cấp đầy đủ và chính xác mọi thông tin tại ứng dụng này.</p><p
      >Các thông tin mà ứng dụng Hoàng Gia có thể thu thập từ thành viên bao gồm: Họ tên, giới tính, ngày sinh, chứng minh thư/căn cước công dân, email, địa chỉ, điện thoại và các thông tin cần thiết khác.</p><p
      ><strong>2. Phạm vi sử dụng thông tin: Các thông tin được thành viên cung cấp có thể dùng vào các mục đích sau: </strong></p><p
      >+ Gửi thông tin giới thiệu dịch vụ trên Ứng dụng Hoàng Gia đến thành viên.</p><p
      >+ Phân tích, đánh giá và hoàn thiện sản phẩm, dịch vụ, công nghệ, quy trình.</p><p
      >+ Nâng cao mối tương tác và liên kết với thành viên.</p><p
      >+ Giải quyết các vẫn đề tranh chấp, khiếu nại phát sinh liên quan đến việc sử dụng Ứng dụng Hoàng Gia.</p><p
      >+ Ngăn chặn những hoạt động vi phạm pháp luật tại Việt Nam.</p><p
      >Nếu không có sự đồng ý của thành viên, Ứng dụng Hoàng Gia sẽ không cung cấp bất kỳ thông tin nào liên quan đến thành viên cho bên thứ ba để sử dụng với mục đích quảng cáo.</p><p
      >+ Các thông tin của thành viên được lưu trữ trong một thời gian cần thiết, nhằm phục vụ cho các yêu cầu thành viên đưa ra.</p><p
      >Thành viên có thể yêu cầu Ứng dụng Hoàng Gia xóa dữ liệu cá nhân khi đã chấm dứt là thành viên của Ứng dụng Hoàng Gia.</p>
          <p>Công ty Bất động sản Hoàng Gia</p><p
      >Địa chỉ: 133 Thái Hà, Đống Đa, TP Hà Nội.</p><p
      >Hotline: (+84) 904733789.</p>
      <p><strong>5. Phương tiện và công cụ để người dùng tiếp cận và chỉnh sửa dữ liệu thành viên:</strong></p><p
      >Các thành viên được cấp một tài khoản bao gồm tên tài khoản và mật khẩu để truy cập Ứng dụng Hoàng Gia. Sau khi đăng nhập, thành viên có quyền sử dụng mọi dịch vụ, tiện ích được cung cấp trên Ứng dụng này theo đúng chức năng, quyền hạn lựa chọn và được phân quyền.</p><p
      ><strong>6. Cam kết bảo mật thông tin thành viên: </strong></p><p
      >+ Ứng dụng Hoàng Gia cam kết sẽ bảo mật các thông tin của thành viên, nỗ lực và sử dụng các biện pháp thích hợp để bảo mật các thông tin mà thành viên cung cấp trong quá trình sử dụng dịch vụ trên Ứng dụng Hoàng Gia.</p><p
      >+ Không bán, chuyển giao dữ liệu thông tin cho bên thứ ba, khi chưa được sự cho phép của thành viên ngoại trừ trường hợp theo yêu cầu cung cấp thông tin thành viên của cơ quan nhà nước có thẩm quyền bằng văn bản hoặc pháp luật có quy định khác.</p><p
      >+ Trong trường hợp máy chủ lưu trữ thông tin thành viên bị tấn công dẫn dến mất dữ liệu thành viên, Ứng dụng Hoàng Gia sẽ có trách nhiệm thông báo vụ việc cho cơ quan chức năng điều tra xử lý kịp thời và thông báo cho thành viên được biết.</p><p
      >+ Nếu xét thấy thông tin của thành viên cung cấp trên Ứng dụng Hoàng Gia là không chính xác. Ứng dụng Hoàng Gia sẽ tiến hành hủy toàn bộ những nội dung của thành viên đó được đăng tải trên Ứng dụng này.</p><p
      >+ Thành viên cần cung cấp thông tin trong các trường hợp sau:</p><p
      >- Việc thu nhập và sử dụng thông tin thành viên chỉ được thực hiện khi có sự đồng ý của thành viên bằng cách đăng ký tài khoản trên Ứng dụng Hoàng Gia.</p><p
      >Quy định về Chính sách bảo mật thông tin của Ứng dụng Hoàng Gia chính thức có hiệu lực thi hành kế từ ngày 27/04/2021.</p></span>
      </div>
      <br/><br/><br/><br/><br/>
    </Box>
  </Container>
}

export default PrivatePolicy