import { ArrowForwardIos, ChevronRight } from "@mui/icons-material";
import { Box, Button, Container, Divider, Paper, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { AllowedTo, useAbac } from "react-abac";
import { useNavigate, useParams } from "react-router-dom";
import { RecoilRoot, useRecoilRefresher_UNSTABLE, useRecoilValueLoadable } from "recoil";
import { Permission } from "../../authz";
import { tokenAxios } from "../../common/axios";
import { ButtonItem } from "../../common/components/button_indicator";
import { HgPaper } from "../../common/components/cards";
import { ConfirmDialog } from "../../common/components/confirm.dialog";
import DrawerHeader from "../../common/components/drawer.header";
import { ErrorBoundary } from "../../common/components/error_boundary";
import { HGAvatar } from "../../common/components/hg_avatar";
import { Panel } from "../../common/components/panel";
import { SideBar } from "../../common/components/sidebar";
import { activateMemberEndpoint, deleteMemberEndpoint } from "../../common/endpoints";
import { titleFromWloc, urlWithId } from "../../common/util/common-utils";
import { kEmptyData, kUnknownError } from "../../common/util/constants";
import { setAlert } from "../../redux/alert.slice";
import { useAppDispatch } from "../../redux/hooks";
import { UpdateWorkDialog } from "../components/update_work.dialog";
import { memberDetailState, memberInfoItems, wlocsState } from "../states/member_detail.state";

export function MemberDetailView() {
  const params = useParams()
  const id = parseInt(params.id ?? '')
  const data = useRecoilValueLoadable(memberDetailState(id))
  const itemData = useRecoilValueLoadable(memberInfoItems(id))

  if(data.state != 'hasValue' || itemData.state != 'hasValue' ) {
    return <></>
  }

  const member = data.contents

  return <RecoilRoot>
    <DrawerHeader />
    <Container maxWidth='xl'>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <MemberDetail member={member} items={itemData.contents}/>
        <SideBar
          sx={{
            display: {xs: 'none', md: 'block'},
          }}
        >
          <ModifyCard member={member}/>
          <WlocCard/>
        </SideBar>
      </Box>
    </Container>
  </RecoilRoot>
}

function ModifyCard(props: any) {
  const {member} = props
  const abac = useAbac()

  const canEdit = abac.userHasPermissions(
    Permission.EDIT_MEMBER,
    member
  )

  const canDelete = abac.userHasPermissions(
    Permission.DELETE_MEMBER,
    member
  )

  const canActive = abac.userHasPermissions(
    Permission.ACTIVATE_MEMBER,
    member
  )

  const canAny = canEdit || canDelete || canActive

  if(!canAny) {
    return <></>
  }

  const children: React.ReactNode[] = []
  if(canEdit) {
    children.push(<UpdateInfo key={1} member={member}/>)
  }

  if(canActive && member.userId == null) {
    children.push(<ActivateMember key={2}/>)
  }

  if(canDelete) {
    children.push(<DeleteMember key={3}/>)
  }

  return <Panel 
    title='Chức năng'
    sx={{
      mb: 2,
    }}
  >
    <Stack
      spacing={1}
      divider={<Divider orientation="horizontal" flexItem />}
      sx={{
        p: 2
      }}
    >
      {children}
    </Stack>
  </Panel>
}

function UpdateInfo(props: any) {
  const {member} = props
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(true)
  }

  return <>
    <ButtonItem
      label='Thay đổi TT công việc'
      onClick={handleClick}
    />
    <UpdateWorkDialog
      open={open}
      setOpen={setOpen}
      member={member}
    />
  </>
}

function DeleteMember() {
  const params = useParams()
  const id = parseInt(params.id ?? '')
  const [open, setOpen] = useState(false)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const handleClick = () => {
    setOpen(true)
  }

  const onDialogClosed = async (positive: boolean) => {
    if(positive) {
      try {
        const {url, ...config} = deleteMemberEndpoint
        await tokenAxios.request({
          url: urlWithId(url, `${id}`),
          ...config
        })
        
        enqueueSnackbar('Đã vô hiệu hóa TK')
        navigate(-1)
      } catch (e) {
        const msg = e instanceof AxiosError ? e.message : kUnknownError
        dispatch(setAlert({open: true, content: msg}))
      }
    }
  }

  return <>
    <ButtonItem
      label='Vô hiệu hóa TK'
      onClick={handleClick}
    />
    <ConfirmDialog
      open={open}
      setOpen={setOpen}
      onClose={onDialogClosed}
      title='Vô hiệu hóa TK'
      content='Bạn có chắc chắn muốn vô hiệu hóa TK của thành viên này không?'
    />
  </>
}

function ActivateMember() {
  const params = useParams()
  const id = parseInt(params.id ?? '')
  const [open, setOpen] = useState(false)
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const refresh = useRecoilRefresher_UNSTABLE(memberDetailState(id))

  const handleClick = () => {
    setOpen(true)
  }

  const onDialogClosed = async (positive: boolean) => {
    if(positive) {
      try {
        const {url, ...config} = activateMemberEndpoint
        await tokenAxios.request({
          url: urlWithId(url, `${id}`),
          ...config
        })
        
        enqueueSnackbar('Đã kích hoạt tài khoản')
        refresh()
      } catch (e) {
        const msg = e instanceof AxiosError ? e.message : kUnknownError
        dispatch(setAlert({open: true, content: msg}))
      }
    }
  }

  return <>
    <ButtonItem
      label='Kích hoạt TK'
      onClick={handleClick}
    />
    <ConfirmDialog
      open={open}
      setOpen={setOpen}
      onClose={onDialogClosed}
      title='Kích hoạt tài khoản'
      content='Bạn có chắc chắn muốn kích hoạt tài khoản của thành viên này không?'
    />
  </>
}

function WlocCard() {
  const params = useParams()
  const id = parseInt(params.id ?? '')
  const data = useRecoilValueLoadable(wlocsState(id)) ?? []

  if(data.state != 'hasValue') {
    return <></>
  }

  const wlocs = data.contents
  if(wlocs == null || wlocs.length == 0 || wlocs[0].city == null) {
    return <></>
  }

  return <Panel key={2} title='Kho hàng'>
    <Stack
      spacing={1}
      divider={<Divider orientation="horizontal" flexItem />}
      sx={{
        p: 2
      }}
    >
      {wlocs.map((e: any, index: number) => <Typography
        key={index}
        sx={{
          fontSize: 16,
          fontWeight: 500,
        }}
      >
        {titleFromWloc(e)}
      </Typography>)}
    </Stack>
  </Panel>
}

function MemberDetail(props: any) {
  const {items, member} = props

  return <Box maxWidth='md'>
    <HgPaper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        p: {xs: 1 , sm: 2, md: 4,},
        mr: {xs: 1, sm: 2, md: 4,},
      }}
    >
      <HGAvatar
        fullName={member.fullName}
        avatarUrl={member.avatarUrl}
        sx={{
          width: 100,
          height: 100
        }}
      />
      <Typography
        sx={{
          fontSize: 16,
          fontWeight: 500,
          mt: 2,
          mb: 0.5
        }}
      >
        {member.titleName}
      </Typography>
      <Typography
        sx={{
          fontSize: 20,
          fontWeight: 800,
          mb: 6
        }}
      >
        {member.fullName}
      </Typography>
      <Stack 
        spacing={2}
        divider={<Divider orientation="horizontal" flexItem />}
      >
        {items.map((e: any, index: number) => <LineInfo
          key={index}
          label={e.label}
          value={e.value}
        />)}
      </Stack>
    </HgPaper>
  </Box>
}

interface LineInfoType {
  label: string
  value: string
}

function LineInfo(props: any) {
  const {label, value, sx} = props

  return <Box
    sx={{
      display: 'flex',
      flexDirection: 'row',
    }}
  >
    <Typography
      sx={{
        fontSize: 16,
        fontWeight: 500,
        minWidth: 200,
      }}
    >
      {label}
    </Typography>
    <Box flexGrow={1}/>
    {value == null 
      ? <Typography
          sx={{
            fontSize: 14,
            fontWeight: 500,
            color: grey
          }}
        >
          {kEmptyData}
        </Typography>
      : <Typography
          sx={{
            fontSize: 16,
            fontWeight: 700,
            textAlign: 'right'
          }}
        >
          {value}
        </Typography>
    }
  </Box>
}