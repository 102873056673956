import { Box, Divider, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { HgPaper } from "./cards";

export function Panel(props: any) {
  const {children, title, sx} = props

  return <Box
    sx={[
      {
        // borderColor: grey[200],
        // border: 0.5,
        borderRadius: 3,
        bgcolor: grey[200]
      },
      ...(Array.isArray(sx) ? sx : [sx])
    ]}
  >
    <Typography
      sx={{
        textAlign: 'left',
        pt: 1, mb: 1, ml: 2,
        fontSize: 16,
        fontWeight: 700,
      }}
    >
      {title}
    </Typography>
    <Divider/>
    {children}
  </Box>
}