import Login from "./views/login";
import PrivatePolicy from "./views/private_policy";

const routes = {
  children: [
      {
          path: 'login',
          element: <Login />,
      },
      {
          path: 'policy',
          element: <PrivatePolicy/>
      }
  ]
}

export default routes