import { Avatar } from "@mui/material"

export function HGAvatar(props: any) {
  const {fullName, avatarUrl, sx} = props

  return <Avatar
    src={avatarUrl ?? undefined}
    children={stringAvatar(fullName)}
    sx={[
      {
        bgcolor: stringToColor(fullName)
      },
      ...(Array.isArray(sx) ? sx : [sx])
    ]}
  />
}

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  const parts = name.split(' ')
  if(parts.length == 1) {
    return parts[0][0]
  }

  return `${parts[0][0]}${parts[parts.length - 1][0]}`
}