import { selector } from "recoil";
import { tokenAxios } from "../axios";
import { deptListEndpoint } from "../endpoints";

export const allDeptsState = selector({
  key: 'all_depts',
  get: async () => {
    const resp = await tokenAxios.request({
      ...deptListEndpoint,
      params: {
        offset: 0,
        limit: 100
      }
    })

    return resp.data.rows
  }
})