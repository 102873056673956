import { AbacProvider, AllowedTo } from "react-abac";

export enum Permission {
  VIEW_REPORT = "VIEW_REPORT",
  VIEW_HOME = 'VIEW_HOME',
  VIEW_FAVORITE = 'VIEW_FAVORITE',
  VIEW_STORE = 'VIEW_STORE',
  VIEW_MEMBER = 'VIEW_MEMBER',
  VIEW_DEPT = 'VIEW_DEPT',
  VIEW_SETTING = 'VIEW_SETTING',
  CREATE_PROPERTY = 'CREATE_PROPERTY',
  EDIT_PROPERTY = 'EDIT_PROPERTY',
  DELETE_PROPERTY = 'DELETE_PROPERTY',
  PUSH_PROPERTY = 'PUSH_PROPERTY',
  CREATE_MEMBER = 'CREATE_MEMBER',
  EDIT_MEMBER = 'EDIT_MEMBER',
  ACTIVATE_MEMBER = 'ACTIVATE_MEMBER',
  DELETE_MEMBER = 'DELETE_MEMBER',
  EDIT_MEMBER_DEPT = 'EDIT_MEMBER_DEPT',
  CREATE_DEPT = 'CREATE_DEPT',
  VIEW_PEDNING = 'VIEW_PENDING',
  VIEW_MYPROPERTY = 'VIEW_MYPROPERTY',
}

enum Role {
  ADMIN = "ADMIN",
  USER = "USER",
}

export const abacRules = {
  [Role.ADMIN]: {
    [Permission.VIEW_REPORT] : true,
    [Permission.VIEW_DEPT] : true,
    [Permission.VIEW_FAVORITE] : true,
    [Permission.VIEW_HOME] : true,
    [Permission.VIEW_MEMBER] : true,
    [Permission.VIEW_SETTING] : true,
    [Permission.VIEW_STORE] : true,
    [Permission.CREATE_PROPERTY] : true,
    [Permission.EDIT_PROPERTY] : true,
    [Permission.DELETE_PROPERTY] : true,
    [Permission.PUSH_PROPERTY] : (property: any, member: any) => {
      return property.createdBy == member.id
    },
    [Permission.CREATE_MEMBER] : true,
    [Permission.EDIT_MEMBER] : true,
    [Permission.ACTIVATE_MEMBER] : true,
    [Permission.DELETE_MEMBER] : true,
    [Permission.EDIT_MEMBER_DEPT] : true,
    [Permission.CREATE_DEPT] : true,
    [Permission.VIEW_PEDNING] : true,
    [Permission.VIEW_MYPROPERTY] : true,
  },
  [Role.USER]: {
    [Permission.VIEW_REPORT] : false,
    [Permission.VIEW_DEPT] : (data: any, member: any) => member.title.id != 7 && member.title.id != 8,
    [Permission.VIEW_FAVORITE] : true,
    [Permission.VIEW_HOME] : true,
    [Permission.VIEW_MEMBER] : (data: any, member: any) => member.title.id != 7 && member.title.id != 8,
    [Permission.VIEW_SETTING] : true,
    [Permission.VIEW_STORE] : true,
    [Permission.CREATE_PROPERTY] : (data: any, member: any) => {
      return [2, 3, 4, 6].includes(member.title.id) 
    },
    [Permission.EDIT_PROPERTY] : (property: any, member: any) => {
      return property.createdBy == member.id
    },
    [Permission.DELETE_PROPERTY] : (property: any, member: any) => {
      return property.createdBy == member.id
    },
    [Permission.PUSH_PROPERTY] : (property: any, member: any) => {
      return property.createdBy == member.id
    },
    [Permission.CREATE_MEMBER] : (other: any, mem: any) => {
      return [2, 3, 4].includes(mem.title.id)
    },
    [Permission.EDIT_MEMBER] : (other: any, mem: any) => {
      if(mem.title.id == 2) {
        return [5, 6].includes(other.title.id)
      }

      return other.department != null 
        && mem.department != null
        && other.department.id == mem.department.id
        && [3, 4].includes(mem.title.id)
    },
    [Permission.ACTIVATE_MEMBER] : (other: any, mem: any) => {
      if(mem.title.id == 2) {
        return ![1, 2].includes(other.title.id)
      }

      return other.department != null 
        && mem.department != null
        && other.department.id == mem.department.id
        && [3, 4].includes(mem.title.id)
    },
    [Permission.DELETE_MEMBER] : (other: any, mem: any) => {
      if(mem.title.id == 2) {
        return ![1, 2].includes(other.title.id)
      }

      return other.department != null 
        && mem.department != null
        && other.department.id == mem.department.id
        && [3, 4].includes(mem.title.id)
    },
    [Permission.EDIT_MEMBER_DEPT] : (other: any, mem: any) => {
      if(mem.title.id == 2) {
        return [5, 6].includes(other.title.id)
      }

      return false
    },
    [Permission.CREATE_DEPT] : false,
    [Permission.VIEW_PEDNING] : false,
    [Permission.VIEW_MYPROPERTY] : (data: any, mem: any) => {
      return [2, 3, 4, 6].includes(mem.title.id)
    },
  }
}