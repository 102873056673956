import { AccountBalance, ArrowCircleLeft, ArrowCircleRight, Chair, ChatBubble, Circle, Email, Facebook, Favorite, LocationOn, PhoneIphone, Share } from "@mui/icons-material";
import { Backdrop, Container, Divider, Grid, IconButton, Stack } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { RecoilRoot, useRecoilRefresher_UNSTABLE, useRecoilValue } from "recoil";
import DrawerHeader from "../../common/components/drawer.header";
import { ErrorBoundary } from "../../common/components/error_boundary";
import { HGAvatar } from "../../common/components/hg_avatar";
import { fullNameFromMember, propertyDetailLink, titleNameFromMember, urlWithId } from "../../common/util/common-utils";
import { kEmptyData, kProperyDefaultImageUrl } from "../../common/util/constants";
import { formatArea, formatDate, formatMeter, formatNumber, formatRecently, formatTextCurrency } from "../../common/util/format-utils";
import { AreaIcon, BathroomIcon, BedroomIcon, FavIcon, FloorIcon, HouseFrontIcon, LikeIcon, RoadIcon, ViewIcon } from "../../icons";
import { propertyDetailState } from "../state/property_detail.state";
import { titleFromProperty } from "../utils";
// import GoogleMapReact from 'google-map-react';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import { Content, Description, Label, SectionLabel, TimeLabel, TimeText, Title } from "../components/property_detail_styles";
import { ContactSidebar } from "../components/contact.sidebar";
import { EditPropertyPanel } from "../components/edit_property.panel";
import { TriggerTooltip } from "../../common/components/trigger_tooltip";
import { tokenAxios } from "../../common/axios";
import { likePropertyEndpoint, unlikePropertyEndpoint } from "../../common/endpoints";
import { SideBar } from "../../common/components/sidebar";
import { ImageContentBlur, ImageThumbnail, LeftNav, RightNav } from "../components/image_gallerys";
import { ImageFullScreenDialog } from "../components/image.fullscreen.dialog";
import { Suspense } from "../../common/components/suspense";
import ReactMapGL, { Marker } from '@goongmaps/goong-map-react';

export function PropertyDetailView(props: any) {
  return <RecoilRoot>
    <DrawerHeader />
      <ErrorBoundary>
        <Suspense>
          <DetailView/>
        </Suspense>
      </ErrorBoundary>
  </RecoilRoot>
}

function DetailView(props: any) {
  const params = useParams();
  const data = useRecoilValue(propertyDetailState(params.id ?? ''))
  const imageUrls: string[] = data.imageUrls == null ? [kProperyDefaultImageUrl]
    : data.imageUrls
  

  return <Container maxWidth='xl'>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: 'md',
        }}
      >
        <ImageGalleryRow imageUrls={imageUrls}/>

        <Title
        >
          {titleFromProperty(data).toUpperCase()}
        </Title>

        <TimeRow
          createdAt={new Date(data.createdAt)}
          updatedAt={data.updatedAt == null ? null : new Date(data.updatedAt)}
          numLike={data.numLike}
          numView={data.numView}
          sx={{
            mt: 2,
            mb: 4,
          }}
        />

        <PriceRow
          id={data.id}
          price={data.price}
          oldPrice={data.oldPrice}
          status={data.status}
          statusDescription={data.statusDescription}
          liked={data.members.length > 0}
        />

        <SectionLabel>
          Thông tin mô tả
        </SectionLabel>
        <Description
          style={{whiteSpace: 'pre-wrap'}}
        >
          {data.description}
        </Description>

        <SectionLabel>
          Đặc điểm bất động sản
        </SectionLabel>
        <BasicInfo data={data}/>

        <SectionLabel>
          Xem trên bản đồ
        </SectionLabel>
        <GoogleMapsRow
          lat={data.latitude}
          lng={data.longitude}
          address1={data.address1}
          address2={data.address2}
        />

        <SectionLabel>
          Liên hệ người đăng tin
        </SectionLabel>
        <ContactRow
          member={data.createdMember}
        />
      </Box>
      <SideBar
        sx={{
          width: 1,
          minWidth: 200,
          display: {sm: 'none', md: 'block'},
          ml: {md: 4, lg: 8, xl: 12},
        }}
      >
        <ContactSidebar property={data}/>
        <EditPropertyPanel 
          property={data}
          sx={{
            mt: 2,
          }}
        />
      </SideBar>
    </Box>
  </Container>
}

interface ImageGalleryPropsType {
  imageUrls: string[]
}

function ImageGalleryRow(props: ImageGalleryPropsType) {
  let {imageUrls} = props
  const ref = React.createRef<ImageGallery>()
  const [currentPage, setCurrentPage] = useState<number | undefined>(undefined)
  const [open, setOpen] = useState(false)
  
  const fullscreenImages = imageUrls.map((e: string) => ({
    original: e,
    thumbnail: e,
    thumbnailHeight: 60,
    thumbnailWidth: 100,
    loading: 'lazy',
  } as ReactImageGalleryItem))

  const images = imageUrls.map((e: string) => ({
    original: e,
    originalHeight: 450,
    thumbnail: e,
    thumbnailHeight: 60,
    thumbnailWidth: 100,
    loading: 'lazy',
  } as ReactImageGalleryItem))

  const renderLeftNav = (onClick: React.MouseEventHandler<HTMLElement>, disable: boolean) => {
    return <LeftNav
      onClick={onClick}
      disabled={disable}
    />
  }

  const renderRightNav = (onClick: React.MouseEventHandler<HTMLElement>, disable: boolean) => {
    return <RightNav
      onClick={onClick}
      disabled={disable}
    />
  }

  const renderItem = (item: ReactImageGalleryItem) => {
    return <ImageContentBlur item={item}/>
  }

  const renderThumbInner = (item: ReactImageGalleryItem) => {
    return <ImageThumbnail item={item}/>
  }

  const handleItemClick = (e: any) => {
    const index = ref.current?.getCurrentIndex()
    if(index != null) {
      setCurrentPage(index)
      setOpen(true)
    }
  }

  return <>
    <ImageGallery
      ref={ref}
      items={images}
      showPlayButton={false}
      showFullscreenButton={false}
      infinite={false}
      lazyLoad={true}
      renderLeftNav={renderLeftNav}
      renderRightNav={renderRightNav}
      renderItem={renderItem}
      renderThumbInner={renderThumbInner}
      onClick={handleItemClick}
    />
    <ImageFullScreenDialog
      images={fullscreenImages}
      currentPage={currentPage}
      open={open}
      setOpen={setOpen}
    />
  </>
}

function BasicInfo(props: any) {
  const {data} = props

  return <Grid 
    container
    direction="row"
    justifyContent="space-between"
    spacing={2}
    columns={2}
  >
    <Grid item xs={1}>
      <LineInfo
        Icon={<AreaIcon width={25} height={25}/>}
        label='Diện tích thực tế'
        content={formatArea(data.acreage)}
      />
    </Grid>
    <Grid item xs={1}>
      <LineInfo
        Icon={<AreaIcon width={25} height={25}/>}
        label='Diện tích trên sổ'
        content={formatArea(data.acreage1)}
      />
    </Grid>
    <Grid item xs={1}>
      <LineInfo
        Icon={<HouseFrontIcon width={25} height={25}/>}
        label='Mặt tiền'
        content={formatMeter(data.surfaceWide)}
      />
    </Grid>
    <Grid item xs={1}>
      <LineInfo
        Icon={<RoadIcon width={25} height={25}/>}
        label='Đường vào'
        content={formatMeter(data.roadWide)}
      />
    </Grid>
    <Grid item xs={1}>
      <LineInfo
        Icon={<FloorIcon width={25} height={25}/>}
        label='Số tầng'
        content={`${formatNumber(data.numFloor)} tầng`}
      />
    </Grid>
    <Grid item xs={1}>
      <LineInfo
        Icon={<BedroomIcon width={25} height={25}/>}
        label='Số phòng ngủ'
        content={`${formatNumber(data.numBed)} phòng`}
      />
    </Grid>
    <Grid item xs={1}>
      <LineInfo
        Icon={<BathroomIcon width={25} height={25}/>}
        label='Số phòng toilet'
        content={`${formatNumber(data.numToilet)} phòng`}
      />
    </Grid>
    <Grid item xs={2}>
      <Legal data={data}/>
    </Grid>
    <Grid item xs={2}>
      <Furniture data={data}/>
    </Grid>
  </Grid>
}

function Legal(props: any) {
  const {data} = props
  const [currentPage, setCurrentPage] = useState<number | undefined>(undefined)
  const [open, setOpen] = useState(false)

  let fullscreenImages = []
  if(data.legalImageUrls != null && data.legalImageUrls.length > 0) {
    fullscreenImages = data.legalImageUrls.map((e: string) => ({
      original: e,
      thumbnail: e,
      thumbnailHeight: 60,
      thumbnailWidth: 100,
      loading: 'lazy',
    } as ReactImageGalleryItem))
  }

  const handleLegaImageClick = (i: number) => (e: any) => {
    setCurrentPage(i)
    setOpen(true)
  }

  return <Box
    sx={{
      mt: 2
    }}
  >
    <Box
      sx={{
        width: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <AccountBalance width={25} height={25} />
      <Label
        sx={{
          ml: 2
        }}
      >
        Pháp lý
      </Label>
      <Divider
        sx={{
          flexGrow: 1,
          ml: 2,
        }}
      />
    </Box>
    <Box
      sx={{
        width: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        mt: 2,
        ml: 2
      }}
    >
      <Circle style={{fontSize: 8}} />
      <Label
        sx={{
          ml: 1
        }}
      >
        Hiện trạng:
      </Label>
      <Content
        sx={{
          ml: 1
        }}
      >
        {data.legalName}
      </Content>
    </Box>
    <Box
      sx={{
        width: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        mt: 2,
        ml: 2
      }}
    >
      <Circle style={{fontSize: 8}} />
      <Label
        sx={{
          ml: 1
        }}
      >
        Thông tin thêm:
      </Label>
      <Content
        sx={{
          ml: 1
        }}
      >
        {data.legalDescription ?? kEmptyData}
      </Content>
    </Box>
    {data.legalImageUrls != null && data.legalImageUrls.length > 0 && <Grid 
      container
      columnSpacing={2}
      rowSpacing={2}
      sx={{
        mt: 2,
        ml: 2, mr: 2
      }}
    >
      {data.legalImageUrls.map((e: string, i: number) => <Grid item key={i}>
        <Box
          sx={{
            width: 300,
            height: 200,
            position: 'relative',
            bgcolor: '#eeeeee',
            borderRadius: 2,
          }}
          onClick={handleLegaImageClick(i)}
        >
          <Box 
            component='img'
            src={e}
            sx={{
              position: 'absolute',
              width: 300,
              height: 200,
              objectFit: 'contain'
            }}
            loading="lazy"
          />
        </Box>
      </Grid>)}
    </Grid>}
    <ImageFullScreenDialog
      images={fullscreenImages}
      currentPage={currentPage}
      open={open}
      setOpen={setOpen}
    />
  </Box>
}

function Furniture(props: any) {
  const {data} = props
  return <Box
    sx={{
      mt: 2
    }}
  >
    <Box
      sx={{
        width: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Chair width={25} height={25} />
      <Label
        sx={{
          ml: 2
        }}
      >
        Nội thất
      </Label>
      <Divider
        sx={{
          flexGrow: 1,
          ml: 2,
        }}
      />
    </Box>
    <Box
      sx={{
        width: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        mt: 2,
        ml: 2
      }}
    >
      <Circle style={{fontSize: 8}} />
      <Label
        sx={{
          ml: 1
        }}
      >
        Hiện trạng:
      </Label>
      <Content
        sx={{
          ml: 1
        }}
      >
        {data.furnitureName}
      </Content>
    </Box>
    <Box
      sx={{
        width: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        mt: 2,
        ml: 2
      }}
    >
      <Circle style={{fontSize: 8}} />
      <Label
        sx={{
          ml: 1
        }}
      >
        Thông tin thêm:
      </Label>
      <Content
        sx={{
          ml: 1
        }}
      >
        {data.furnitureDescription ?? kEmptyData}
      </Content>
    </Box>
  </Box>
}

function LineInfo(props: any) {
  const {Icon, label, content} = props

  return <Box 
    sx={{
      display: 'flex',
      flexDirection: 'row',
      borderTop: 0.5,
      borderBottom: 0.5,
      borderColor: grey[400],
      pt: 1,
      pb: 1
    }}
  >
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: 200
      }}
    >
      {Icon}
      <Label
        sx={{
          ml: 2
        }}
      >
        {label}
      </Label>
    </Box>  
    <Content flexGrow={2} sx={{textAlign: 'start'}}>
      {content}
    </Content>
  </Box>
}

function TimeRow(props: any) {
  const {createdAt, updatedAt, numView, numLike, sx} = props

  return <Box
    sx={[
      {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      },
      ...(Array.isArray(sx) ? sx : [sx])
    ]}
  >
    <LikeIcon/>
    <TimeText
      sx={{
        ml: 1,
        mr: 2
      }}
    >
      {formatNumber(numLike)}
    </TimeText>
    <ViewIcon/>
    <TimeText
      sx={{
        ml: 1,
        mr: 2
      }}
    >
      {formatNumber(numView)}
    </TimeText>
    <Box flexGrow={1}/>
    <TimeLabel>
      Ngày đăng tin:
    </TimeLabel>
    <TimeText
      sx={{
        ml: 1,
        mr: 2
      }}
    >
      {formatDate(createdAt)}
    </TimeText>
    {updatedAt && <><TimeLabel>
      Cập nhật: 
    </TimeLabel>
    <TimeText
      sx={{
        ml: 1
      }}
    >
      {formatRecently(updatedAt)}
    </TimeText></>}
  </Box>
}

function PriceRow(props: any) {
  const {price, oldPrice, statusDescription, status, id, liked} = props

  return <Box
  sx={{
    display: 'flex',
    flexDirection: 'row',
    width: 1,
    borderTop: 0.5,
    borderBottom: 0.5,
    borderColor: grey[400],
    mt: 2,
    mb: 2,
    pt: 1,
    pb: 1
  }}
  >
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        mr: 2,
      }}
    >
      <Label>
        Tình trạng
      </Label>
      <Content>
        {statusDescription}
      </Content>
    </Box>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Label>
        Mức giá
      </Label>
      <Content>
        {formatTextCurrency(price)}
      </Content>
    </Box>
    <Box flexGrow={1}/>
    <ShareButton id={id}/>
    <FavButton id={id} liked={liked}/>
    
  </Box>
}

function FavButton(props: any) {
  const {id, liked} = props
  const refresh = useRecoilRefresher_UNSTABLE(propertyDetailState(`${id}`))

  const handleClick = async () => {
    try {
      if(liked) {
        const {url, ...config} = unlikePropertyEndpoint
        await tokenAxios.request({
          url: urlWithId(url, `${id}`),
          ...config
        })
      } else {
        const {url, ...config} = likePropertyEndpoint
        await tokenAxios.request({
          url: urlWithId(url, `${id}`),
          ...config
        })
      }
  
      refresh()
    } catch(e) {}
    
  }

  return <IconButton
    onClick={handleClick}
    sx={{
      ml: 2
    }}
  >
    {liked ? <Favorite style={{color: 'red'}}/> : <FavIcon/>}
  </IconButton>
}

function ShareButton(props: any) {
  const {id} = props
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    const link = `${propertyDetailLink(id)}`
    navigator.clipboard.writeText(link)
      .then(() => {
        setOpen(true)
      })
      .catch((_) => {})
  }

  return <TriggerTooltip
    title='Đã sao chép' 
    arrow={true}
    placement="top"
    open={open}
    setOpen={setOpen}
  >
    <IconButton onClick={handleClick}>
      <Share/>
    </IconButton>
  </TriggerTooltip>
  
}

function ContactRow(props: any) {
  const {member} = props
  
  return <Grid 
    container
    direction="row"
    justifyContent="space-between"
    spacing={2}
    columns={10}
  >
    <Grid item xs={10}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        <HGAvatar
          fullName={fullNameFromMember(member)}
          avatarUrl={member.avatarUrl ?? undefined}
          sx={{ width: 60, height: 60 }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            ml: 1
          }}
        >
          <Label>{titleNameFromMember(member)}</Label>
          <Content>{fullNameFromMember(member)}</Content>
        </Box>
      </Box>
    </Grid>
    <Grid item xs={5}>
        <LineInfo
          Icon={<PhoneIphone/>}
          label='Số điện thoại'
          content={member.phone}
        />
    </Grid>
    <Grid item xs={5}>
        <LineInfo
          Icon={<Email/>}
          label='Email'
          content={member.email}
        />
    </Grid>
    <Grid item xs={5}>
        <LineInfo
          Icon={<Facebook/>}
          label='Facebook'
          content={member.facebook ?? kEmptyData}
        />
    </Grid>
    <Grid item xs={5}>
        <LineInfo
          Icon={<ChatBubble/>}
          label='Zalo'
          content={member.zalo ?? kEmptyData}
        />
    </Grid>
  </Grid>
}

function GoogleMapsRow(props: any) {
  const {lat, lng, address1, address2} = props

  const [viewport, setViewport] = useState({
    width:'100%',
        height: 400,
        zoom: 18,
    latitude: lat,
    longitude: lng,
  });

  const renderMarkers = (map: any, maps: any) => {
    let marker = new maps.Marker({
    position: { lat, lng },
    map,
    title: '',
    });
    return marker;
   };

  return <>
    <Box
      sx={{
        width: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        ml: 2
      }}
    >
      <Circle style={{fontSize: 8}} />
      <Label
        sx={{
          ml: 1
        }}
      >
        Địa chỉ trên sổ:
      </Label>
      <Content
        sx={{
          ml: 1
        }}
      >
        {address1}
      </Content>
    </Box>
    <Box
      sx={{
        width: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        mt: 2,
        mb: 4,
        ml: 2
      }}
    >
      <Circle style={{fontSize: 8}} />
      <Label
        sx={{
          ml: 1
        }}
      >
        Địa chỉ khác:
      </Label>
      <Content
        sx={{
          ml: 1
        }}
      >
        {address2 ?? kEmptyData}
      </Content>
    </Box>
    <ReactMapGL
      goongApiAccessToken="LqT0mKY5lKGCuk7dhGt1VKfzTFneEvcNMQcseC80"
      {...viewport}
      onViewportChange={(nextViewport: any) => setViewport(nextViewport)}
    >
      <Marker 
        latitude={lat}
        longitude={lng}
      >
        <LocationOn style={{
          color: '#de2307', 
          width: 60, 
          height: 60, 
          marginLeft: -30,
          marginTop: -30
        }}/>
      </Marker>
    </ReactMapGL>
    {/* <Box
      sx={{
        width: 1,
        height: 400
      }}
    >
      
      <GoogleMapReact
        bootstrapURLKeys={{
          key: 'AIzaSyDqiuEj45KBsXASpXyvx90TwCsnjDP_XpQ',
          region: 'VN',
          language: 'vi'
        }}
        defaultZoom={18}
        defaultCenter={{lat, lng}}
        onGoogleApiLoaded={({map, maps}) => renderMarkers(map, maps)}
      />
    </Box> */}
  </>
}