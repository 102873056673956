import { Box, List, ListItemButton, Typography } from "@mui/material";
import { useRecoilState, useRecoilTransaction_UNSTABLE } from "recoil";
import { ListItemTextSideBar, TitleSidebar } from "../../common/components/list_item_text.sidebar";
import { rangeAreas, rangePrices } from "../constants";
import { currentPageState, rangeAreaFilterState } from "../state/property_list.state";
import { titleFromRangeArea, titleFromRangePrice } from "../utils";

export function AreaRangePanel(props: any) {
  const {sx} = props
  const updateRangeFilter = useRecoilTransaction_UNSTABLE(({set}) => (range: any) => {
    set(currentPageState, 1)
    set(rangeAreaFilterState, range)
  })
  const [rangeArea] = useRecoilState(rangeAreaFilterState)

  const handleItemClick = (data: any) => () => {
    updateRangeFilter(data)
  }

  return <Box
    sx={[
      {
        border: 0.5,
        borderColor: '#999',
        borderRadius: 2,
      },
      ...(Array.isArray(sx) ? sx : [sx])
    ]}
  >
    <TitleSidebar>
      Phạm vi diện tích
    </TitleSidebar>
    <List>
      {rangeAreas.map((e: any, i: number) => <ListItemButton 
        key={i}
        onClick={handleItemClick(e)}
      >
        <ListItemTextSideBar
          selected={rangeArea === e}
        >
          {`${titleFromRangeArea(e)}`}
        </ListItemTextSideBar>
      </ListItemButton>)}
    </List>
  </Box>
}
