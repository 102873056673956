import { Tooltip } from "@mui/material";
import React, { useEffect } from "react";

export function TriggerTooltip(props: any) {
  const {title, placement, arrow, children, open, setOpen} = props
  
  const handleTooltipClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setOpen(false)
    }, 1000)
    return () => {
      clearTimeout(timeout)
    }
  })

  return <Tooltip
    title={title}
    arrow={arrow}
    placement={placement}
    PopperProps={{
      disablePortal: true,
    }}
    onClose={handleTooltipClose}
    open={open}
    disableFocusListener
    disableHoverListener
    disableTouchListener
  >
    {children}
  </Tooltip>
}