import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { RecoilRoot} from "recoil";
import { CardTitle, HgCard, HgCardContent } from "../../common/components/cards";
import DrawerHeader from "../../common/components/drawer.header";
import { DescriptionContent as DescriptionContent } from "../components/description.content";
import { InfoContent } from "../components/info.content";
import { LocationCard as LocationContent } from "../components/location.card";
import { PropertyImageContent as PropertyImageContent } from "../components/property_image.content";
import * as yup from 'yup'
import { useFormik } from "formik";
import { furnitureTypes, legalTypes } from "../constants";
import { createPropertyTask, NewProperty, resumeNewPropertyTask } from "../property.service";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { InfoDialog } from "../../common/components/info.dialog";

export function PropertyCreateView(props: any) {
  const [openInfoDialog, setOpenInfoDialog] = useState(false)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [contentInfo, setContentInfo] = useState('')
  const [data, setData] = useState<any>(null)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const handleDialogClosed = () => {
    if(data != null) {
      navigate(`/property/${data.id}`)
    }
  }

  const handleConfirmDialogClosed = () => {
    if(newProperty != null) {
      navigate(`/property/${newProperty.id}`)
    }
  }

  let newProperty: NewProperty

  const formik = useFormik({
    initialValues: {
      address: '',
      address1: '',
      address2: '',
      wards: null,
      district: null,
      city: null,
      latlng: null,
      description: '',
      area: undefined,
      area1: undefined,
      frontWidth: undefined,
      roadWidth: undefined,
      price: undefined,
      legalType: legalTypes[2],
      furnitureType: furnitureTypes[1],
      legalDescription: '',
      furnitureDescription: '',
      numFloor: 1,
      numBed: 1,
      numToilet: 1,
      images: [],
      legalImages: []
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {      
      if(newProperty == null) {
        newProperty = createPropertyTask(values)
      }

      try {
        setLoading(true)

        await resumeNewPropertyTask(newProperty)
        setData(newProperty)
        setContentInfo('Đã đăng tin thành công!')
        setOpenInfoDialog(true)
      } catch (e) {
        setContentInfo('Đã đăng tin thành công. Tuy nhiên phần tải ảnh lên server bị lỗi, vui lòng vào chi tiết tin để chỉnh sửa lại')
        setOpenInfoDialog(true)
      }
    }
  })

  return <RecoilRoot>
    <Container maxWidth='md'>
      <form onSubmit={formik.handleSubmit}>
        <Stack 
          direction='column'
          spacing={2}
          sx={{
            mb: 20,
            position: 'relative'
          }}
        >
          <DrawerHeader />
          <HgCard>
            <HgCardContent>
              <CardTitle>
                Thông tin vị trí
              </CardTitle>
              <LocationContent formik={formik}/>
            </HgCardContent>
          </HgCard> 
          <HgCard>
            <HgCardContent>
              <CardTitle>
                Thông tin bất động sản
              </CardTitle>
              <InfoContent formik={formik}/>
            </HgCardContent>
          </HgCard> 
          <HgCard>
            <HgCardContent>
              <CardTitle>
                Mô tả
              </CardTitle>
              <DescriptionContent formik={formik}/>
            </HgCardContent>
          </HgCard> 
          <HgCard>
            <HgCardContent>
              <CardTitle>
                Ảnh thực địa
              </CardTitle>
              <PropertyImageContent formik={formik}/>
            </HgCardContent>
          </HgCard>
          <SaveButton loading={loading}/>
        </Stack>
      </form>
    </Container>
    <InfoDialog
      open={openInfoDialog}
      setOpen={setOpenInfoDialog}
      content={contentInfo}
      onClose={handleDialogClosed}
    />
  </RecoilRoot>
}

const validationSchema = yup.object({
  address: yup.string().required('Địa chỉ thực tế là bắt buộc'),
  address1: yup.string().required('Địa chỉ trên sổ là bắt buộc'),
  address2: yup.string().notRequired(),
  wards: yup
    .object()
    .nullable()
    .required('Thông tin Xã/Phường là bắt buộc'),
  district: yup
    .object()
    .nullable()
    .required('Thông tin Quận/Huyện là bắt buộc'),
  city: yup
    .object()
    .nullable()
    .required('Thông tin Tỉnh/Thành phố là bắt buộc'),
  latlng: yup.object().nullable().notRequired(),
  description: yup
    .string()
    .min(30, 'Mô tả tối thiểu 30 ký tự')
    .max(5000, 'Mô tả tối đa 5000 ký tự')
    .required('Mô tả về bất động sản là bắt buộc'),
  area: yup.number().required('Diện tích thực tế là bắt buộc'),
  area1: yup.number().required('Diện tích trên sổ là bắt buộc'),
  frontWidth: yup.number().notRequired(),
  roadWidth: yup.number().notRequired(),
  price: yup.number().required('Mức giá là bắt buộc'),
  legalType: yup.object().required(),
  furnitureType: yup.object().required(),
  legalDescription: yup.string().notRequired(),
  furnitureDescription: yup.string().notRequired(),
  numFloor: yup.number().required(),
  numBed: yup.number().required(),
  numToilet: yup.number().required(),
  images: yup.array().notRequired(),
  legalImaegs: yup.array().notRequired()
})

interface SaveButtonProp {
  loading: boolean
}

function SaveButton({loading}: SaveButtonProp) {
  return <HgCard>
    <HgCardContent>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          alignContent: 'center'
        }}
      >
        <Button
          disabled={loading}
          variant="contained"
          type='submit'
          sx={{
            width: 200,
            height: 50,
          }}
        >
          {loading ? <CircularProgress size={30}/> : 'Đăng tin'}
        </Button>
      </Box>
    </HgCardContent>
  </HgCard>
}