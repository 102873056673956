import styled from "@emotion/styled"
import { Typography } from "@mui/material"
import { grey } from "@mui/material/colors"
import { TypographyProps } from "@mui/system"

export const Title = styled(Typography)((theme) => ({
  fontSize: 20,
  fontWeight: 900,
  marginTop: 20,
  marginBottom: 10
}))

export const SectionLabel = styled(Typography)((theme) => ({
  fontSize: 18,
  fontWeight: 700,
  marginTop: 25,
  marginBottom: 10
}))

export const Description = styled(Typography)<TypographyProps>(() => ({
  fontSize: 18,
  fontWeight: 500
}))

export const Label = styled(Typography)<TypographyProps>(() => ({
  fontSize: 16,
  fontWeight: 400,
  color: grey[700]
}))

export const TimeLabel = styled(Typography)<TypographyProps>(() => ({
  fontSize: 14,
  fontWeight: 400,
  color: grey[800]
}))

export const TimeText = styled(Typography)<TypographyProps>(() => ({
  fontSize: 14,
  fontWeight: 400,
  color: grey[900]
}))

export const Content = styled(Typography)<TypographyProps>(() => ({
  fontSize: 18,
  fontWeight: 600,
  color: grey[900]
}))