import { selector } from "recoil";
import { tokenAxios } from "../../common/axios";
import { notificationListEndpoint } from "../../common/endpoints";
import { toQueryParams } from "../../common/util/common-utils";

export const notificationListState = selector({
  key: 'notification_list',
  get: async () => {
    const resp = await tokenAxios.request({
      ...notificationListEndpoint,
      params: toQueryParams({
        offset: 0,
        limit: 100,
      })
    })

    return resp.data
  },
  cachePolicy_UNSTABLE: {
    eviction: 'lru', 
    maxSize: 0
  },
})

export const notificationsState = selector({
  key: 'notifications',
  get: ({get}) => {
    return get(notificationListState).rows
  }
})