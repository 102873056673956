import { atom, selectorFamily } from "recoil";
import { tokenAxios } from "../../common/axios";
import { propertyDetailEndpoint } from "../../common/endpoints";
import { urlWithId } from "../../common/util/common-utils";

export const propertyIdState = atom<number | null>({
  key: 'id',
  default: null,
})

export const propertyDetailState = selectorFamily<any, string>({
  key: 'property_detail',
  get: id => async  ({get}) => {  
    const {url, ...config} = propertyDetailEndpoint
    const resp = await tokenAxios.request({
      url: urlWithId(url, id),
      ...config
    })

    return resp.data
  },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent'
  } 
})