import AppLayout from "./app_layout";
import HomeView from './views/home.view'
import {PropertyListView} from "../property/views/property_list.view";
import { PropertyDetailView } from "../property/views/property_detail.view";
import { PropertyCreateView } from "../property/views/property_create.view";
import { MemberListView } from "../member/views/member_list.view";
import { MemberDetailView } from "../member/views/member_detail.view";
import { NewMemberView } from "../member/views/new_member.view";
import { DeptListView } from "../dept/views/dept_list.view";
import { DeptDetailView } from "../dept/views/dept_detail.view";
import { UserSettingView } from "../user/views/user_setting.view";
import { EditProfileView } from "../user/views/edit_profile.view";
import { ChangePasswordView } from "../user/views/change_password.view";

const routes = {
  path: '/',
  element: <AppLayout />,
  children: [
    {
      path: '',
      element: <HomeView/>
    },
    {
      path: 'properties',
      element: <PropertyListView/>,
    },
    {
      path: 'property/:id',
      element: <PropertyDetailView/>
    },
    {
      path: 'new-property',
      element: <PropertyCreateView/>
    },
    {
      path: 'employee/members',
      element: <MemberListView/>
    },
    {
      path: 'employee/member/:id',
      element: <MemberDetailView/>
    },
    {
      path: 'new-member',
      element: <NewMemberView/>
    },
    {
      path: 'employee/depts',
      element: <DeptListView/>
    },
    {
      path: 'employee/dept/:id',
      element: <DeptDetailView/>
    },
    {
      path: 'new-dept',
      element: <></>
    },
    {
      path: 'setting',
      element: <UserSettingView/>
    },
    {
      path: 'edit-profile',
      element: <EditProfileView/>
    },
    {
      path: 'change-password',
      element: <ChangePasswordView/>
    },
  ]
}

export default routes