import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface AlertState {
  content?: string | null
  onClose?: any | null
  open: boolean
}

const initialState = {
  content: null,
  open: false,
} as AlertState

export const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    setAlert: (state, action: PayloadAction<AlertState>) => {
      state.content = action.payload.content
      state.onClose = action.payload.onClose
      state.open = true
    }
  }
})

export const { setAlert } = alertSlice.actions
export default alertSlice.reducer

