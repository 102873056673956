import { atom, selector, selectorFamily } from "recoil";
import { tokenAxios } from "../../common/axios";
import { memberListEndpoint } from "../../common/endpoints";
import { ListStateType, PaginationType } from "../../common/types";
import { deptNameFromMember, fullNameFromMember, hometownFromMember, offsetFromPage, titleNameFromMember, toQueryParams } from "../../common/util/common-utils";
import { formatDate } from "../../common/util/format-utils";
import { MemberSorts } from "../../property/constants";

export const currentPageState = atom({
  key: 'member_list/current_page',
  default: 1,
})

export const keywordSearchState = atom<string>({
  key: 'member_list/keyword',
  default: '',
})

export const sortByState = atom({
  key: 'member_list/sort_by',
  default: MemberSorts[0]
})

export const memberListState = selector<ListStateType>({
  key: 'member_list/data',
  get: async ({get}) => {
    const page = get(currentPageState)
    const keyword = get(keywordSearchState)
    const sortBy = get(sortByState)

    const resp = await tokenAxios.request({
      ...memberListEndpoint,
      params: toQueryParams({
        offset: offsetFromPage(page),
        limit: 20,
        ...(keyword && {keyword}),
        sort_by: sortBy.query,
      })
    })

    return resp.data
  },
  cachePolicy_UNSTABLE: {
    eviction: 'lru', 
    maxSize: 0
  },
})

export interface MemberRowType {
  id: number
  order: number
  avatar: string
  fullName: string
  title: string
  dept: string
  hometown: string
  joinedDate?: string
}

export const membersState = selector<MemberRowType[]>({
  key: 'member_list/rows',
  get: ({get}) => {
    const paging = get(paginationState)
    const offset = (paging.page - 1)*20 + 1
    return get(memberListState).rows.map((e: any, index: number) => ({
      id: e.id,
      order: offset + index,
      avatar: e.avatarUrl,
      fullName: fullNameFromMember(e),
      title: titleNameFromMember(e),
      dept: deptNameFromMember(e),
      hometown: hometownFromMember(e),
      joinedDate: e.joinedAt && formatDate(new Date(e.joinedAt))
    } as MemberRowType))
  },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent'
  }
})

export const paginationState = selector<PaginationType>({
  key: 'member_list/pagination',
  get: ({get}) => {
    const total = Math.ceil(get(memberListState).count/20)
    const page = get(currentPageState)

    return {
      page, total
    }
  },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent'
  }
})