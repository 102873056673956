import { Circle } from "@mui/icons-material";
import { Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import React from "react";
import { selector, useRecoilState, useRecoilTransaction_UNSTABLE, useRecoilValue } from "recoil";
import { tokenAxios } from "../../common/axios";
import { CardTitle } from "../../common/components/cards";
import { ListItemTextSideBar, TitleSidebar } from "../../common/components/list_item_text.sidebar";
import { allWlocsEndpoint } from "../../common/endpoints";
import { LocationType } from "../../common/types";
import { currentPageState, locationFilterState } from "../state/property_list.state";

export function StorePanel() {
  return <Box
    sx={{
      border: 0.5,
      borderColor: '#999',
      borderRadius: 2,
    }}
  >
    <TitleSidebar>
      Khu vực
    </TitleSidebar>
    <React.Suspense>
      <Store/>
    </React.Suspense>
  </Box>
}

function Store() {
  const locs = useRecoilValue(wlocListState)
  const updateLocationFilter = useRecoilTransaction_UNSTABLE(({set}) => (location: any) => {
    set(currentPageState, 1)
    set(locationFilterState, location)
  })
  const [location] = useRecoilState(locationFilterState)

  const handleItemClick = (data: any) => () => {
    updateLocationFilter(data.city == null ? null : {
      city: {
        id: data.city.id,
        name: data.city.name
      },
      district: {
        id: data.district.id,
        name: data.district.name
      }
    })
  }

  return <List>
    {locs.map((e: any, i: number) => <ListItemButton 
      key={i}
      onClick={handleItemClick(e)}
    >
      <ListItemTextSideBar
        selected={location?.city.id == e.cityId && location?.district.id == e.districtId}
      >
        {e.city == null ? 'Tất cả trong công ty' : `${e.district.name} - ${e.city.name}`}
      </ListItemTextSideBar>
    </ListItemButton>)}
  </List>
}

const wlocListState = selector({
  key: 'all-wlocs',
  get: async () => {
    const resp = await tokenAxios.request(allWlocsEndpoint)

    return [
      {
        city: null,
        district: null
      },
      ...resp.data
    ]
  }
})