import React, {Dispatch, useEffect} from "react";
import {basicAxios, tokenAxios} from "../axios";
import {loginEndpoint, profileEndpoint} from "../endpoints";

interface AuthContextType {
  user: any
  login: (data: any, callback: VoidFunction) => void,
  logout: (callback: VoidFunction) => void
}

const AuthContext = React.createContext<AuthContextType | undefined>(undefined)

function authReducer(state: any, action: any) {
 switch (action.type) {
   case 'none':
     return {loading: false, data: null, error: null}
   case 'preload':
     return {loading: true}
   case 'data':
     localStorage.setItem('user', JSON.stringify(action.data))
     return {loading: false, data: action.data}
   case 'failed':
     return {loading: false, error: action.message}
 }
}

function useAuth() {
  const context = React.useContext(AuthContext)

  if (!context) {
    throw new Error(`useProfile must be used within a ProfileProvider`)
  }
  return context
}

function AuthProvider(props: any) {
  const initializeAuth = () => {
    const userStr = localStorage.getItem('user')
    if(userStr != null) {
      const userObj = JSON.parse(userStr)
      return {loading: false, data: userObj}
    }

    return undefined
  }

  const [user, dispatch] = React.useReducer(authReducer, undefined, initializeAuth)

  const login = async (data: any, callback: VoidFunction) => {
    try {
      const resp = await basicAxios.request({
        ...loginEndpoint,
        data
      })

      dispatch({type: 'data', data: resp.data['user']})
      callback()
    } catch(e: any) {
      dispatch({type: 'failed', message: e.toString()})
    }
  }

  const logout = async (callback: VoidFunction) => {

  }

  const value = {user, login, logout}//React.useMemo(() => [user, login, logout], [user])

  return <AuthContext.Provider value={value} {...props}/>
}

export {AuthProvider, useAuth}