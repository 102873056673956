import { atom, selector, selectorFamily } from "recoil";
import { tokenAxios } from "../axios";
import { cityListEndpoint, districtListEndpoint, wardsListEndpoint } from "../endpoints";
import { urlWithId } from "../util/common-utils";

export const citiesState = selector({
  key: 'location/cities',
  get: async () => {
    const resp = await tokenAxios.request(cityListEndpoint)

    return resp.data.map((e: any) => ({
      id: e.id,
      name: e.name
    }))
  }
})

export const allDistrictState = atom<Map<number, any[]>>({
  key: 'location/all-district',
  default: new Map()
})

export const allWardsState = atom<Map<number, any[]>>({
  key: 'location/all-wards',
  default: new Map()
})

export const districtsState = selectorFamily<any, number>({
  key: 'location/districts',
  get: cityId => async () => {
    const {url, ...config} = districtListEndpoint
    const resp = await tokenAxios.request({
      url: urlWithId(url, `${cityId}`),
      ...config
    })
    

    return resp.data
  }
})

export const wardsesState = selectorFamily<any, number>({
  key: 'location/wardses',
  get: districtId => async () => {
    const {url, ...config} = wardsListEndpoint
    const resp = await tokenAxios.request({
      url: urlWithId(url, `${districtId}`),
      ...config
    })

    return resp.data
  }
})