import { selector, selectorFamily } from "recoil";
import { tokenAxios } from "../../common/axios";
import { memberDetailEndpoint } from "../../common/endpoints";
import { WorkingLocation } from "../../common/types";
import { deptNameFromMember, fullNameFromMember, hometownFromMember, titleNameFromMember, urlWithId } from "../../common/util/common-utils";
import { formatDate } from "../../common/util/format-utils";

interface MemberDetailType {
  id: number
  avatarUrl?: string
  fullName: string
  titleName: string
  deptName?: string
  hometown: string
  birthday?: string
  joinedDate?: string
  facebook?: string
  zalo?: string
  phone: string
  email: string
  gender: string
  locations: any[]
  title: any
  department: any
  userId?: number
}

export const memberDetailState = selectorFamily<MemberDetailType, number>({
  key: 'member_detail/data',
  get: id => async () => {
    const {url, ...config} = memberDetailEndpoint
    const resp = await tokenAxios.request({
      url: urlWithId(url, `${id}`),
      ...config
    })
    const data = resp.data
    const fullName = fullNameFromMember(data)
    const titleName = titleNameFromMember(data)
    const deptName = deptNameFromMember(data)
    const hometown = hometownFromMember(data)
    const birthday = data.birthday && formatDate(new Date(data.birthday))
    const joinedDate = data.joinedAt && formatDate(new Date(data.joinedAt))
    
    return {
      id,
      fullName, titleName, deptName, hometown,
      birthday, joinedDate,
      facebook: data.facebook,
      zalo: data.zalo,
      phone: data.phone,
      email: data.email,
      avatarUrl: data.avatarUrl,
      gender: data.gender,
      locations: data.locations,
      title: data.title,
      department: data.department,
      userId: data.userId
    }
  },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent'
  }
})

export const wlocsState = selectorFamily<WorkingLocation[], number>({
  key: 'member_detail/wlocs',
  get: id => ({get}) => {
    const data = get(memberDetailState(id))
    return data.locations
  },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent'
  }
})

interface Item {
  id: 'phone' | 'email' | 'facebook' | 'zalo' | 'joinedDate' | 'hometown' | 'birthday' | 'gender',
  label: string,
  value?: string
}

export const memberInfoItems = selectorFamily<Item[], number>({
  key: 'member_detail/items',
  get: id => ({get}) => {
    const data = get(memberDetailState(id))
    return [
      {
        id: 'phone',
        label: 'Số điện thoại',
        value: data.phone
      },
      {
        id: 'email',
        label: 'Email',
        value: data.email,
      },
      {
        id: 'facebook',
        label: 'Facebook',
        value: data.facebook,
      },
      {
        id: 'zalo',
        label: 'Zalo',
        value: data.zalo,
      },
      {
        id: 'joinedDate',
        label: 'Ngày tham gia',
        value: data.joinedDate,
      },
      {
        id: 'hometown',
        label: 'Quê quán',
        value: data.hometown,
      },
      {
        id: 'birthday',
        label: 'Ngày sinh',
        value: data.birthday,
      },
      {
        id: 'gender',
        label: 'Giới tính',
        value: data.gender,
      }
    ]
  }
})