import { Box, Divider, Grid } from "@mui/material";
import { useRecoilState, useRecoilValueLoadable } from "recoil";
import { CardLabel, CardSubtitle, CardTitle, HgCard, HgCardContent } from "../../common/components/cards";
import { DropDownLabelButton } from "../../common/components/dropdown_label.button";
import { allDeptsState } from "../../common/state/depts.state";
import { allTitlesState } from "../../common/state/titles.state";
import { WorkingLocation } from "../../common/types";
import { titleFromWloc } from "../../common/util/common-utils";
import { wlocsState } from "../states/new_member.state";
import { DateLabelButton } from "./date_label.button";
import { NewWlocButton } from "./new_wloc.button";
import { WlocItem } from "./wloc.item";

export function WorkInfoCard(props: any) {
  const {sx, formik, disableDept} = props
  const allTitles = useRecoilValueLoadable(allTitlesState)
  const titleList = allTitles.state == 'hasValue' ? allTitles.contents : []
  const allDepts = useRecoilValueLoadable(allDeptsState)
  const deptList = allDepts.state == 'hasValue' ? allDepts.contents : []

  const handleTitleChange = (item: any) => {
    formik.setFieldValue('title', item)
  }

  const handleDeptChange = (item: any) => {
    formik.setFieldValue('dept', item)
  }

  return <Grid
    container
    rowSpacing={2}
    columnSpacing={4}
    columns={2}
    sx={{
      mt: 2,
    }}
  >
    <Grid item sm = {1}>
      <DropDownLabelButton
        id='title'
        item={formik.values.title}
        handleChange={handleTitleChange}
        items={titleList}
        label='Chức danh'
        required={true}
        placeholder='Chọn chức danh'
        error={formik.touched.title && Boolean(formik.errors.title)}
        helperText={formik.touched.title && formik.errors.title}
      />
    </Grid>
    <Grid item sm = {1}>
      <DropDownLabelButton
        id='dept'
        item={formik.values.dept}
        handleChange={handleDeptChange}
        items={deptList}
        label='Khối/Phòng'
        placeholder='Chọn khối/phòng'
        disable={disableDept}
      />
    </Grid>
    <Grid item sm={1}>
      <DateLabelButton
        id='joinedDate'
        value={formik.values.joinedDate}
        handleChange={(value: any) => formik.setFieldValue('joinedDate', value)}
        label='Ngày tham gia'
      />
    </Grid>
    <Grid item xs={2}>
      <WorkingLocationCard/>
    </Grid>
  </Grid>
}

function WorkingLocationCard() {
  const [wlocs, setWlocs] = useRecoilState(wlocsState)

  const handleItemDeleted = (item: WorkingLocation) => {
    const index = wlocs.indexOf(item)
    
    if(index > -1) {
      const newItems = [...wlocs]
      newItems.splice(index, 1)
      
      setWlocs(newItems)
    }
  }

  return <>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        mt: 2,
        mb: 2,
        width: 1,
      }}
    >
      <CardLabel>
        Kho hàng
      </CardLabel>
      <Divider
        sx={{
          flexGrow: 1,
          ml: 2
        }}
      />
    </Box>
    <Grid 
      container 
      spacing={2}
      sx={{
        mt: 2,
        mb: 2,
      }}
    >
        {[
          ...wlocs.map((e: WorkingLocation, index: number) => <Grid item key={index}>
            <WlocItem 
              label={titleFromWloc(e)}
              handleDelete={() => handleItemDeleted(e)}
            />
          </Grid>
          ),
          <Grid item key={-1}>
            <NewWlocButton/>
          </Grid>
        ]}
    </Grid>
  </>
}



