import { Circle, DoneAll } from "@mui/icons-material";
import { Button, ButtonBase, Divider, List, Popover, Popper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Virtuoso } from 'react-virtuoso'
import { useRecoilRefresher_UNSTABLE, useRecoilValue, useRecoilValueLoadable } from "recoil";
import { tokenAxios } from "../../common/axios";
import { Suspense } from "../../common/components/suspense";
import { markAsAllReadEndpoint } from "../../common/endpoints";
import { formatRecently } from "../../common/util/format-utils";
import { notificationListState, notificationsState } from "../states/notification.state";

export function NotificationListPopper(props: any) {
  const {anchorEl, setAnchorEl} = props
  const refreshList = useRecoilRefresher_UNSTABLE(notificationListState)
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const handleMarkAllClick = async () => {
    try {
      await tokenAxios.request(markAsAllReadEndpoint)
      refreshList()
    } catch(e) {}
  }

  return <Popover
    id={id} 
    open={open} 
    anchorEl={anchorEl}
    onClose={handleClose}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    sx={{
      zIndex: 10000,
    }}
    PaperProps={{
      style: { 
        width: 500,
        height: '60vh'
      },
    }}
  >
    <Box
      sx={{
        width: 1,
        m: 1,
        pr: 2,
        display: 'flex',
        flexDirection: 'row'
      }}
    >
      <Box flexGrow={1}/>
      <Button
        onClick={handleMarkAllClick}
      >
        <DoneAll/>
        <Typography sx={{ml: 1}}>
          Đánh dấu đã đọc tất cả
        </Typography>
      </Button>
    </Box>
    <Divider/>
    <Suspense>
      <NotificationList
        onClose={handleClose}
      />
    </Suspense>
  </Popover>
}

function NotificationList(props: any) {
  const {onClose} = props
  const rows = useRecoilValue(notificationsState)
  const refreshList = useRecoilRefresher_UNSTABLE(notificationListState)
  const dataLoadable = useRecoilValueLoadable(notificationListState)
  const navigate = useNavigate()

  useEffect(() => {
    if(dataLoadable.state != 'loading') {
      refreshList()
    }
  }, [])

  const handleItemClick = (row: any) => () => {
    onClose()
    if(row.title === 'Thành viên mới') {
      navigate(`/employee/member/${row.payload.id}`)
    } else if(row.title === 'Tin mới' 
      || row.title === 'Cập nhật địa chỉ'
      || row.title === 'Cập nhật trạng thái BĐS') {
      navigate(`/property/${row.payload.id}`)
    }
  }

  const renderRow = (index: number, row: any) => {
    return <Box
    sx={{
      borderBottom: 0.5,
      borderColor: '#999',
      m: 1,
      p: 1,
      '&:hover': {
        backgroundColor: '#f9f9f9',
        opacity: [0.9, 0.8, 0.7],
        cursor: 'pointer'
      },
    }}
    onClick={handleItemClick(row)}
  >
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      }}
    >
      <Typography
        style={{
          fontSize: 18,
          fontWeight: 700,
          textAlign: 'left',
        }}
      >
        {row.title}
      </Typography>
      <Box flexGrow={1}/>
      <Circle
        style={{
          fontSize: 10,
          marginRight: 3,
          color: row.read ? '#888' : '#ff9300'
        }}
      />
      <Typography
        style={{
          fontSize: 14,
          fontWeight: 400,
          textAlign: 'right',
        }}
      >
        {formatRecently(new Date(row.createdAt))}
      </Typography>
    </Box>
    { <div dangerouslySetInnerHTML={{ __html: row.content }} /> }
  </Box>
  }

  return <Virtuoso
    style={{
      height: 'calc(100% - 50px)',
    }}
    data={rows}
    overscan={300}
    itemContent={renderRow}
    components={{Footer}}
  />
}

const Footer = () => {
  return (
    <div
      style={{
        padding: '2rem',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      Đang tải thêm...
    </div>
  )
}