import {AxiosRequestConfig} from "axios";

export const paramEndpoint = _general({
  url: '/config/params',
  method: "get",
})

export const loginEndpoint = {
  url: '/auth/login/web',
  method: "post",
  headers: {
    'api-version' : '2'
  }
} as AxiosRequestConfig

export const logoutEndpoint = _general({
  url: '/auth/logout/web',
  method: "delete",
})

export const requestLoginEndpoint = _general({
  url: '/auth/request-login',
  method: 'post'
})

export const changePasswordEndpoint = _general({
  url: '/auth/change-password',
  method: "put"
})

export const forgotPassword1 = _general({
  url: '/auth/forgot-password-1',
  method: "put"
})

export const profileEndpoint = _general({
  url: '/auth/profile',
  method: 'get'
})

export const propertyListEndpoint = {
  url: '/properties',
  method: "get",
  headers: {
    'api-version' : '3'
  }
} as AxiosRequestConfig

export const newPropertyEndpoint = {
  url: '/properties',
  method: "post",
  headers: {
    'api-version' : '3'
  }
} as AxiosRequestConfig

export const propertyImageEndpoint = _general({
  url: '/properties/:id/image',
  method: 'put'
})

export const propertyLocationEndpoint = _general({
  url: '/properties/:id/location',
  method: 'put'
})

export const propertyInfoEndpoint = _general({
  url: 'properties/:id/info',
  method: 'put'
})

export const propertyStatusEndpoint = _general({
  url: 'properties/:id/status',
  method: 'put'
})

export const propertyDescriptionEndpoint = _general({
  url: 'properties/:id/description',
  method: 'put'
})

export const pushPropertyEndpoint = _general({
  url: 'properties/:id/push',
  method: 'put'
})

export const deletePropertyEndpoint = _general({
  url: 'properties/:id',
  method: 'delete'
})

export const propertyDetailEndpoint = {
  url: '/properties/:id',
  method: 'get',
  headers: {
    'api-version' : '2'
  }
}

export const likePropertyEndpoint = _general({
  url: 'properties/:id/likes',
  method: 'post'
})

export const unlikePropertyEndpoint = _general({
  url: 'properties/:id/likes',
  method: 'delete'
})

export const highlightEndpoint = _general({
  url: '/highlight',
  method: "get"
})

export const cityListEndpoint = _general({
  url: '/locations/cities',
  method: "get"
})

export const districtListEndpoint = _general({
  url: '/locations/cities/:id',
  method: "get"
})

export const wardsListEndpoint = _general({
  url: '/locations/districts/:id',
  method: "get"
})

export const memberListEndpoint = _general({
  url: '/employee/members',
  method: 'get'
})

export const newMemberEndpoint = _general({
  url: '/employee/members',
  method: 'post'
})

export const memberDetailEndpoint = _general({
  url: '/employee/members/:id',
  method: 'get'
})

export const activateMemberEndpoint = _general({
  url: '/employee/members/:id/activate',
  method: 'put'
})

export const deleteMemberEndpoint = _general({
  url: '/employee/members/:id',
  method: 'delete'
})

export const editProfileEndpoint = _general({
  url: '/employee/members/0/profile',
  method: 'put'
})

export const deptListEndpoint = _general({
  url: '/employee/departments',
  method: 'get'
})

export const deptDetailEndpoint = _general({
  url: '/employee/departments/:id',
  method: 'get'
})

export const titleListEndpoint = _general({
  url: '/titles',
  method: 'get'
})

export const newPresignedUrlEndpoint = {
  url: '/files',
  method: "post",
  headers: {
    'api-version' : '2'
  }
} as AxiosRequestConfig

export const notificationListEndpoint = _general({
  url: '/notifications',
  method: 'get'
})

export const markAsAllReadEndpoint = _general({
  url: '/notifications',
  method: 'put'
})

export const markAsReadEndpoint = _general({
  url: '/notifications/:id',
  method: 'put'
})

export const allWlocsEndpoint = _general({
  url: '/profile/wlocs',
  method: 'get'
})

export const newDeviceTokenEndpoint = _general({
  url: '/device-tokens',
  method: 'post'
})

function _general(endpoint: AxiosRequestConfig) {
  const {headers, ...remain} = endpoint
  return {
    ...remain,
    headers: {
      ...headers,
      'api-version' : '1'
    }
  } as AxiosRequestConfig
}