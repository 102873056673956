import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material"

export function SortByButton(props: any) {
  const {item, setItem, items} = props
  
  const handleChange = (event: SelectChangeEvent) => {
    setItem(event.target.value)
  };

  return <FormControl sx={{ minWidth: 200,}} size="small">
  <Select
    labelId="demo-select-small"
    id="demo-select-small"
    value={item ?? ''}
    onChange={handleChange}
  >
    {items.map((e: any, index: number) => {
      return <MenuItem key={index} value={e}>
        {e?.description}
      </MenuItem>
    })}
  </Select>
</FormControl>
}