import {useRecoilState, useRecoilValue} from "recoil";
import {currentPageState, paginationState} from "../state/property_list.state";
import {Box, Button, Pagination, Typography} from "@mui/material";

export function PropertyListPagination(props: any) {
  const pagin = useRecoilValue(paginationState)
  const [currentPage, setCurrentPage] = useRecoilState(currentPageState)

  const handleOnPageChanged = (event: any, page: number) => {
    setCurrentPage(page)
  }

  return pagin.total === 0 ? <InfinitePaging
    currentPage={currentPage}
    setCurrentPage={setCurrentPage}
  /> : <Pagination
    count={pagin.total}
    page={currentPage}
    variant="outlined"
    shape="rounded"
    onChange={handleOnPageChanged}
  />
}

function InfinitePaging(props: any) {
  const { currentPage, setCurrentPage } = props

  const handlePrev = () => {
    if(currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const handleNext = () => {
    setCurrentPage(currentPage + 1)
  }
  
  return <Box
    sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    }}
  >
    <Button
      onClick={handlePrev}
      disabled={currentPage < 2}
    >
      ← Trang trước
    </Button>
    <Typography
      sx={{
        ml: 1, mr: 1
      }}
    >
      |
    </Typography>
    <Button
      onClick={handleNext}
    >
      Trang tiếp theo →
    </Button>
  </Box>
}