import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

export function InfoDialog(props: any) {
  const {open, setOpen, content, onClose} = props

  const handleClose = () => {
    setOpen(false);
    if(onClose != null) {
      onClose()
    }
  };

  return <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    sx={{
      minWidth: 200
    }}
  >
    <DialogTitle id="alert-dialog-title">
      Thông báo
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {content}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} autoFocus>
        Đóng
      </Button>
    </DialogActions>
  </Dialog>
}