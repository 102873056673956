import { ArrowDropDown, Notifications } from "@mui/icons-material";
import { Avatar, Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { AxiosError } from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValueLoadable } from "recoil";
import { tokenAxios } from "../../common/axios";
import { logoutEndpoint } from "../../common/endpoints";
import { authState } from "../../common/state/auth.state";
import { fullNameFromMember, titleNameFromMember } from "../../common/util/common-utils";
import { kUnknownError } from "../../common/util/constants";
import { setAlert } from "../../redux/alert.slice";
import { useAppDispatch } from "../../redux/hooks";
import { NotificationListPopper } from "../../user/components/notification.list.popper";

export function UserProfile() {
  const auth = useRecoilValueLoadable(authState)
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  if(auth.state !== 'hasValue') {
    return <></>
  }

  const user = auth.contents
  
  const handleCloseUserMenu = (index: number) => async () => {
    setAnchorElUser(null);
    if(index == 0) {
      navigate('edit-profile')
    } else if(index == 1) {
      navigate('change-password')
    } else if(index == 2) {
      try {
        await tokenAxios.request(logoutEndpoint)
        navigate('login')
      } catch(e) {
        const msg = e instanceof AxiosError ? e.message : kUnknownError
        dispatch(setAlert({open: true, content: msg}))
      }
    }
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  return <>
    <Box sx={{
      flexGrow: 0,
      flexDirection: 'row',
      alignItems: 'center',
      display: 'flex',
    }}>
      <Notification />
      <Button variant="text" onClick={handleOpenUserMenu} sx={{ p: 0, mr: 2 }}>
        <Avatar src={user.member.avatarUrl}/>
        <Box sx={{
          ml: 1,
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}>
          <Typography
            noWrap
            align='left'
            textTransform='none'
            sx={{
              fontVariant: 'subtitle1',
              fontSize: 12,
              color: grey[700]
            }}
          >
            {titleNameFromMember(user.member)}
          </Typography>
          <Typography
            noWrap
            align='left'
            textTransform='none'
            sx={{
              fontVariant: 'subtitle2',
              fontSize: 14,
              color: '#000'
            }}
          >
            {fullNameFromMember(user.member)}
          </Typography>
        </Box>
        <ArrowDropDown/>
      </Button>
    </Box>
    <Menu
      sx={{ mt: '45px' }}
      id="menu-appbar"
      anchorEl={anchorElUser}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorElUser)}
      onClose={handleCloseUserMenu(-1)}
    >
      {settings.map((setting, index: number) => (
        <MenuItem key={setting} onClick={handleCloseUserMenu(index)}>
          <Typography textAlign="center">{setting}</Typography>
        </MenuItem>
      ))}
    </Menu>
  </>
}

function Notification() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  return <>
    <Button
      startIcon={<Notifications/>}
      sx={{
        mr: 2,
      }}
      onClick={handleClick}
    >
      Thông báo
    </Button>
    <NotificationListPopper
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
    />
  </>
}

const settings = ['Sửa thông tin cá nhân', 'Thay đổi mật khẩu', 'Đăng xuất'];