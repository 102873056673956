import { Box, CircularProgress } from "@mui/material";

export function ProgressBox() {
  return <Box
    sx={{
      width: 1,
      height: '60vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      justifyItems: 'center',
    }}
  >
    <CircularProgress/>
  </Box>
}