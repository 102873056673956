import { Box, Button, Dialog, DialogContent, FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material"
import { AxiosError } from "axios"
import { useSnackbar } from "notistack"
import { useEffect, useState } from "react"
import { useRecoilRefresher_UNSTABLE } from "recoil"
import { tokenAxios } from "../../common/axios"
import { DialogTitleClose } from "../../common/components/title_close.dialog"
import { propertyStatusEndpoint } from "../../common/endpoints"
import { ValueType } from "../../common/types"
import { urlWithId } from "../../common/util/common-utils"
import { kUnknownError } from "../../common/util/constants"
import { setAlert } from "../../redux/alert.slice"
import { useAppDispatch } from "../../redux/hooks"
import { propertyStatuses } from "../constants"
import { propertyDetailState } from "../state/property_detail.state"

export function PropertyStatusDialog(props: any) {
  const { open, setOpen, property} = props
  const refreshDetail = useRecoilRefresher_UNSTABLE(propertyDetailState(`${property.id}`))
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const [value, setValue] = useState(property.status)

  useEffect(() => {
    setValue(property.status) 
  }, [open])

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = async () => {
    const {url, ...config} = propertyStatusEndpoint
    try {
      const status = propertyStatuses.find(e => e.value === value)
      if(status == null) {
        return
      }

      await tokenAxios.request({
        url: urlWithId(url, `${property.id}`),
        ...config,
        data: {
          status: status.value,
          statusDescription: status.description,
        }
      })

      setOpen(false)
      enqueueSnackbar('Đã thay đổi trạng thái của BĐS')
      setTimeout(() => {
        refreshDetail()
      }, 300)
    } catch(e) {
      const msg = e instanceof AxiosError ? e.message : kUnknownError
      dispatch(setAlert({open: true, content: msg}))
    }
  }

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(parseInt(event.target.value))
    
  }

  return <Dialog
    maxWidth='md'
    open={open}
  >
    <DialogContent>
      <DialogTitleClose
        title='Thay đổi trạng thái BĐS'
        onClose={handleClose}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          minWidth: 400,
        }}
      >
        <FormControl
          sx={{
            width: 1
          }}
        >
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={value}
            onChange={handleValueChange}
          >
            {propertyStatuses.map((e: any, i: number) => (
              <FormControlLabel
                key={i}
                value={e.value}
                label={e.description}
                control={<Radio/>}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <Button
          variant="contained"
          type='submit'
          sx={{
            width: 200,
            mt: 2,
          }}
          onClick={handleSubmit}
        >
          Xác nhận
        </Button>
      </Box>
    </DialogContent>
  </Dialog>
}