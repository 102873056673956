import { Dialog, DialogContent } from "@mui/material"
import { AxiosError } from "axios"
import { useFormik } from "formik"
import { useSnackbar } from "notistack"
import { useRecoilRefresher_UNSTABLE } from "recoil"
import * as yup from 'yup'
import { simpleAxios, tokenAxios } from "../../common/axios"
import { DialogActionConfirm } from "../../common/components/confirm_action.dialog"
import { DialogTitleClose } from "../../common/components/title_close.dialog"
import { newPresignedUrlEndpoint, propertyImageEndpoint } from "../../common/endpoints"
import { MediaAsset } from "../../common/types"
import { fileReader, imageUrlFromUrl, urlWithId } from "../../common/util/common-utils"
import { kUnknownError } from "../../common/util/constants"
import { setAlert } from "../../redux/alert.slice"
import { useAppDispatch } from "../../redux/hooks"
import { propertyDetailState } from "../state/property_detail.state"
import { PropertyImageContent } from "./property_image.content"

export function PropertyImageDialog(props: any) {
  const { open, setOpen, property} = props
  const refreshDetail = useRecoilRefresher_UNSTABLE(propertyDetailState(`${property.id}`))
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const formik = useFormik({
    initialValues: {
      images: property.imageUrls
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      let images = values.images
      let assets: MediaAsset[] = []
      for(let i = 0; i < images.length; i++) {
        if(images[i] instanceof File) {
          assets.push({
            name: `${property.id}-${i}-${(new Date()).getTime()}`,
            file: images[i],
            category: 'property',
            contentType: 'image/jpeg'
          } as MediaAsset)
        }
      }

      try {
        const uploadedImageUrls: string[] = []
        if(assets.length > 0) {
          const resp = await tokenAxios.request({
            ...newPresignedUrlEndpoint,
            data: assets.map(e => ({
              fileName: e.name,
              contentType: e.contentType,
              category: e.category
            }))
          })
          const presignedUrls = resp.data

          for(const asset of assets) {
            const rawUrl = presignedUrls[asset.name]
            const url = new URL(rawUrl)   
            try {
              const binary = await fileReader(asset.file)
              await simpleAxios.put(rawUrl, binary, {
                headers: {
                  AWSAccessKeyId : url.searchParams.get('AWSAccessKeyId') ?? '',
                  Type: url.searchParams.get('Type') ?? '',
                  Expires: url.searchParams.get('Expires') ?? 0,
                  Signature: url.searchParams.get('Signature') ?? '',
                  'x-amz-acl': url.searchParams.get('x-amz-acl') ?? '',
                  'Content-Type': asset.contentType
                },
              })
            } catch(_) {}
            
            let imageUrl = imageUrlFromUrl(url)
            uploadedImageUrls.push(imageUrl)
          }
        }

        let imageUrls: string[] = []
        for(const image of images) {
          if(image instanceof File) {
            const url = uploadedImageUrls.shift()
            if(url != undefined) {
              imageUrls.push(url)
            }
          } else {
            imageUrls.push(image)
          }
        }

        const {url, ...config} = propertyImageEndpoint
        await tokenAxios.request({
          url: urlWithId(url, `${property.id}`),
          ...config,
          data: {
            imageUrls
          }
        })

        setOpen(false)
        enqueueSnackbar('Đã cập nhật ảnh thực địa')
        setTimeout(() => {
          refreshDetail()
        }, 300)
      } catch(e) {
        const msg = e instanceof AxiosError ? e.message : kUnknownError
        dispatch(setAlert({open: true, content: msg}))
      }
    }
  })

  const handleClose = () => {
    formik.resetForm()
    setOpen(false)
  }

  return <Dialog
    maxWidth='md'
    fullWidth
    open={open}
  >
    <DialogContent sx={{width: 1}}>
      <DialogTitleClose
        title='Thay đổi ảnh thực địa'
        onClose={handleClose}
      />
      <form onSubmit={formik.handleSubmit}>
        <PropertyImageContent formik={formik} fromDialog={true}/>
        <DialogActionConfirm/>
      </form>
    </DialogContent>
  </Dialog>
}

const validationSchema = yup.object({
  images: yup.array().notRequired(),
})