import { CloseOutlined } from "@mui/icons-material";
import { Box, Button, IconButton } from "@mui/material";
import { arrayMoveImmutable } from "array-move";
import SortableList, { SortableItem } from "react-easy-sort";
import './sortable_grid.css'

interface SortableGridType {
  items: any[]
  setItems: any
  sx: any
}

export function SortableGrid(props: SortableGridType) {
  const {items, setItems, sx} = props

  const onSortEnd = (oldIndex: number, newIndex: number) => {
    setItems(arrayMoveImmutable(items, oldIndex, newIndex))
  }

  const handleRemoveClicked = (item: any) => (event: any) => {
    const index = items.indexOf(item)
    
    if(index > -1) {
      const newItems = [...items]
      newItems.splice(index, 1)
      
      setItems(newItems)
    }
  }
  
  return <SortableList
    onSortEnd={onSortEnd} 
    className="list" 
    draggedItemClassName="dragged"
  >
    {items.map((item: any, index: number) => (
      <SortableItem key={index}>
        <Box
          sx={[
            {
              width: 200,
              height: 140,
              position: 'relative',
              bgcolor: '#aeaeae',
              borderRadius: 2,
            },
            ...(Array.isArray(sx) ? sx : [sx])
          ]}
        >
          <Box
            component='img'
            src={item instanceof File ? URL.createObjectURL(item) : item}
            draggable={false}
            sx={{
              width: 200,
              height: 140,
              position: 'absolute',
              objectFit: 'contain',
            }}
          />
          <IconButton
            sx={{
              width: 30,
              height: 30,
              bgcolor: '#fff',
              borderRadius: 20,
              position: 'absolute',
              right: 10,
              top: 10,
            }}
            onClick={handleRemoveClicked(item)}
          >
            <CloseOutlined/>
          </IconButton>
        </Box>
      </SortableItem>
    ))}
  </SortableList>
}