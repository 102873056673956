import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { AllowedTo } from "react-abac";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";
import { Permission } from "../../authz";
import DrawerHeader from "../../common/components/drawer.header";
import { myDeptIdState } from "../../common/state/auth.state";
import { HomeChart, HomeDept, HomeFav, HomeMember, HomeMyDept, HomeMyProperties, HomeStore } from "../../icons";

export default function HomeView() {
  const deptIdData = useRecoilValueLoadable(myDeptIdState)
  const deptId = deptIdData.state == 'hasValue' ? deptIdData.contents : null

  return <Container maxWidth='lg'>
    <DrawerHeader/>
    <Grid 
      container
      columnSpacing={3}
      rowSpacing={3}
    >
      <AllowedTo
        perform={Permission.VIEW_REPORT}
      >
        <Grid item>
        <ButtonItem 
          label="Thống kê"
          href="/reports"
          icon={<HomeChart/>}
        />
      </Grid>
      </AllowedTo>
      <Grid item>
        <ButtonItem 
          label="Tin yêu thích"
          href="/properties?fav-only=true"
          icon={<HomeFav/>}
        />
      </Grid>
      <AllowedTo
        perform={Permission.VIEW_MYPROPERTY}
      >
        <Grid item>
        <ButtonItem 
          label="TIN TÔI TẠO"
          href="/properties?my-properties=true"
          icon={<HomeMyProperties/>}
        />
      </Grid>
      </AllowedTo>
      <Grid item>
        <ButtonItem 
          label="Kho hàng"
          href="/properties"
          icon={<HomeStore/>}
        />
      </Grid>
      <AllowedTo
        perform={Permission.VIEW_MEMBER}
      >
        <Grid item>
          <ButtonItem 
            label="Thành viên"
            href="/employee/members"
            icon={<HomeMember/>}
          />
        </Grid>
      </AllowedTo>
      <AllowedTo
        perform={Permission.VIEW_DEPT}
      >
        <Grid item>
          <ButtonItem 
            label="Khối/Phòng"
            href="/employee/depts"
            icon={<HomeDept/>}
          />
        </Grid>
      </AllowedTo>
      {deptId && <AllowedTo
        perform={Permission.VIEW_DEPT}
      >
        <Grid item>
        <ButtonItem 
          label="Phòng ban của tôi"
          href={`/employee/dept/${deptId}`}
          icon={<HomeMyDept/>}
        />
      </Grid>
      </AllowedTo>}
    </Grid>
  </Container>
}

interface ButtonItemProps {
  label: string
  href: string
  icon: any
}

function ButtonItem({label, href, icon}: ButtonItemProps) {
  return <Button
    variant="text"
    href={href}
    sx={{
      p: 2,
      display: 'flex',
      flexDirection: 'column',
      width: 300,
      height: 200,
      border: 0,
      boxShadow: '0px 0px 4px 2px rgba(0,0,0,0.1)',
      borderRadius: 2,
      bgcolor: '#fff'
    }}
  >
    <Box
      sx={{
        width: 90,
        height: 90
      }}
    >
      {icon}
    </Box>
    <Typography
      sx={{
        fontSize: 20,
        fontWeight: 600,
        mt: 2,
      }}
    >
      {label.toUpperCase()}
    </Typography>
  </Button>
}