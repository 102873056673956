import { Card, CardContent, CardContentProps, CardProps, Paper, PaperProps, styled, Typography, TypographyProps } from "@mui/material";

export const CardTitle = styled(Typography)<TypographyProps>(() => ({
  fontSize: 20,
  fontWeight: 900,
  marginTop: 20,
  marginBottom: 10
}))

export const CardSubtitle = styled(Typography)<TypographyProps>(() => ({

}))

export const CardHint = styled(Typography)<TypographyProps>(() => ({
  fontSize: 12,
  fontWeight: 400,
  color: '#aeaeae'  
}))

export const HgCard = styled(Card)<CardProps>(() => ({
  boxShadow: '0px 0px 4px 2px rgba(0,0,0,0.1)'
}))

export const HgPaper = styled(Paper)<PaperProps>(() => ({
  boxShadow: '0px 0px 4px 2px rgba(0,0,0,0.1)'
}))

export const CardLabel = styled(Typography)<TypographyProps>(() => ({
  fontSize: 14,
  fontWeight: 700,
  display: 'inline'
}))

export function HgCardContent(props: any) {
  return <CardContent
    sx={{
      pl: {sm: 2, md: 4},
      pr: {sm: 2, md: 4}
    }}
  >
    {props.children}
  </CardContent>
}