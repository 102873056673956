import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDqiuEj45KBsXASpXyvx90TwCsnjDP_XpQ",
  authDomain: "hoanggia-8c5f8.firebaseapp.com",
  projectId: "hoanggia-8c5f8",
  storageBucket: "hoanggia-8c5f8.appspot.com",
  messagingSenderId: "169427900613",
  appId: "1:169427900613:web:f045788689c29e1837f8ac",
  measurementId: "G-6Q00GQ0Y8Y"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const getClientToken = (setTokenFound: any) => {
  return getToken(messaging, {vapidKey: 'BKTWNtZACM0qW98lRaRZ55Ez60732Ry5aWmcBoac1ibiboJeNSlX8zKsqZP1hgjGf7__Gkht6FGjPrDfOiFYNQk'}).then((currentToken: any) => {
    if (currentToken) {
      setTokenFound(currentToken);
    } else {
      console.log('No registration token available. Request permission to generate one.');
      setTokenFound(null);
    }
  }).catch((err: any) => {
    console.log('An error occurred while retrieving token. ', err);
  });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});