import { simpleAxios, tokenAxios } from "../common/axios"
import { newPropertyEndpoint, propertyImageEndpoint } from "../common/endpoints"
import { MediaAsset } from "../common/types"
import { fileReader, imageUrlFromUrl, urlWithId } from "../common/util/common-utils"

export interface NewProperty {
  id?: number | undefined
  presignedUrls?: any | undefined,
  imageUrls: string[]
  legalImageUrls: string[]
  assets: MediaAsset[],
  bodyData: any
}

export function createPropertyTask(formData: any) {
  let id: number | undefined = undefined

  let {images, legalImages} = formData
  
  let assets: MediaAsset[] = []
  for(let i = 0; i < images.length; i++) {
    assets.push({
      name: `${i}-${(new Date()).getTime()}`,
      file: images[i],
      category: 'property',
      contentType: 'image/jpeg'
    } as MediaAsset)
  }
  for(let i = 0; i < legalImages.length; i++) {
    assets.push({
      name: `c-${i}-${(new Date()).getTime()}`,
      file: legalImages[i],
      category: 'houseCert',
      contentType: 'image/jpeg'
    } as MediaAsset)
  }

  let data = {
    description: formData.description,
    address: formData.address,
    address1: formData.address1,
    address2: formData.address2,
    wardsId: formData.wards.id,
    wards: formData.wards.name,
    districtId: formData.district.id,
    district: formData.district.name,
    cityId: formData.city.id,
    city: formData.city.name,
    latitude: formData.latlng?.lat,
    longitude: formData.latlng?.lng,
    price: parseInt(formData.price),
    acreage: parseFloat(formData.area),
    acreage1: parseFloat(formData.area1),
    numFloor: parseInt(formData.numFloor),
    numBed: parseInt(formData.numBed),
    numToilet: parseInt(formData.numToilet),
    roadWide: formData.roadWidth && parseFloat(formData.roadWidth),
    surfaceWide: formData.frontWidth && parseFloat(formData.frontWidth),
    legalType: formData.legalType.value,
    legalName: formData.legalType.brief,
    furnitureType: formData.furnitureType.value,
    furnitureName: formData.furnitureType.brief,
    legalDescription: formData.legalDescription,
    furnitureDescription: formData.furnitureDescription,
    images: assets.map(e => ({
      contentType: e.contentType,
      fileName: e.name,
      category: e.category
    }))
  }

  return {
    assets,
    bodyData: data,
    imageUrls: [],
    legalImageUrls: []
  } as NewProperty
}

export const resumeNewPropertyTask = async (property: NewProperty) => {
  const {id, bodyData, assets} = property
  
  if(id === undefined) {
    const resp = await tokenAxios.request({
      ...newPropertyEndpoint,
      data: bodyData
    })

    property.id = resp.data.id
    property.presignedUrls = resp.data.presignedUrls
  }

  const presignedUrls = property.presignedUrls
  if(presignedUrls != null) {
    const imageUrls: string[] = []
    const legalImageUrls: string[] = []
    for(const asset of assets) {
      const rawUrl = presignedUrls[asset.name]
      const url = new URL(rawUrl)   
      try {
        const binary = await fileReader(asset.file)
        await simpleAxios.put(rawUrl, binary, {
          headers: {
            AWSAccessKeyId : url.searchParams.get('AWSAccessKeyId') ?? '',
            Type: url.searchParams.get('Type') ?? '',
            Expires: url.searchParams.get('Expires') ?? 0,
            Signature: url.searchParams.get('Signature') ?? '',
            'x-amz-acl': url.searchParams.get('x-amz-acl') ?? '',
            'Content-Type': asset.contentType
          },
        })
      } catch(_) {}
      
      let imageUrl = imageUrlFromUrl(url)
      if(asset.category === 'houseCert') {
        legalImageUrls.push(imageUrl)
      } else {
        imageUrls.push(imageUrl)
      }
    }

    property.imageUrls = imageUrls
    property.legalImageUrls = legalImageUrls
  }

  if(property.imageUrls.length > 0 || property.legalImageUrls.length > 0) {
    const {url, ...config} = propertyImageEndpoint
    try {
      await tokenAxios.request({
        url: urlWithId(url, `${property.id}`),
        ...config,
        data: {
          imageUrls: property.imageUrls,
          legalImageUrls: property.legalImageUrls,
          silent: true,
        }
      })
    } catch(_) {}
  }
}