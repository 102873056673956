import { atom, selector } from "recoil"
import { WorkingLocation } from "../../common/types"
import { genders } from "../constants"

export interface MemberFormType {
  fullName: string
  email: string
  phone: string
  address?: string
  wards?: any | null
  district?: any | null
  city?: any | null
  gender: any
  birthday?: Date | null
  joinedDate?: Date | null
  title?: any | null
  dept?: any | null
}

export const wlocsState = atom<WorkingLocation[]>({
  key: 'new-member/wlocs',
  default: []
})

export const memberInfoState = atom<MemberFormType>({
  key: 'new-member/info',
  default: {
    fullName: '',
    email: '',
    phone: '',
    address: '',
    wards: null,
    district: null,
    city: null,
    gender: genders[2],
    birthday: null,
    joinedDate: null,
    title: null,
    dept: null,
  }
})