import {kEmptyData, webAppOrigin} from "./constants";
import * as qs from 'qs'
import { WorkingLocation } from "../types";

export function offsetFromPage(page: number) {
  return (page - 1)*20
}

export function fullNameFromMember(member: any) {
  const names = []
  if(member.firstName != null) {
    names.push(member.firstName)
  }
  if(member.middleName != null) {
    names.push(member.middleName)
  }
  if(member.lastName != null) {
    names.push(member.lastName)
  }

  return names.join(' ')
}

export function hometownFromMember(member: any) {
  const names = []
  if(member.address != null && member.address.length > 0) {
    names.push(member.address)
  }
  if(member.wards != null) {
    names.push(member.wards)
  }
  if(member.district != null) {
    names.push(member.district)
  }
  if(member.city != null) {
    names.push(member.city)
  }

  return names.join(', ')
}

export function titleNameFromMember(member: any) {
  return member?.title?.name ?? kEmptyData
}

export function deptNameFromMember(member: any) {
  return member?.department?.name ?? kEmptyData
}

export function titleFromWloc(wloc: WorkingLocation) {
  if(wloc.district == null) {
    return `Toàn bộ ${wloc.city.name}`
  }

  return `${wloc.district!.name} - ${wloc.city.name}`
}

export function toQueryParams(obj: any) {
  var flattenedObject = {};
  traverseAndFlatten(obj, flattenedObject, undefined);
  return flattenedObject;
}

function traverseAndFlatten(currentNode: any, target: any, flattenedKey: any) {
  for (var key in currentNode) {
    if (currentNode.hasOwnProperty(key)) {
      var newKey;
      if (flattenedKey === undefined) {
          newKey = key;
      } else {
          newKey = flattenedKey + '[' + key + ']';
      }

      var value = currentNode[key];
      if (typeof value === "object") {
          traverseAndFlatten(value, target, newKey);
      } else {
          target[newKey] = value;
      }
    }
  }
}

export function urlWithId(url: string | undefined, id: string) {
  return url?.replace(':id', `${id}`)
}

export function openInNewTabWinBrowser(url: string) {
  const win = window.open(url, '_blank');
  win?.focus();
}

export function propertyDetailLink(id: number) {
  return `${webAppOrigin}/property/${id}`
}

function fallbackCopyTextToClipboard(text: string) {
  var textArea = document.createElement("textarea");
  textArea.value = text;
  
  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}

export async function copyTextToClipboard(text: string) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(function() {
    console.log('Async: Copying to clipboard was successful!');
  }, function(err) {
    console.error('Async: Could not copy text: ', err);
  });
}

export function imageUrlFromUrl(url: URL) {
  return `${url.origin}${url.pathname}`
}

export function fileReader(file: File){
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      resolve(reader.result)
    };

    reader.readAsArrayBuffer(file)
  })
}