import { atom, selector } from "recoil"
import { tokenAxios } from "../../common/axios"
import { deptDetailEndpoint, memberListEndpoint } from "../../common/endpoints"
import { PaginationType } from "../../common/types"
import { deptNameFromMember, fullNameFromMember, hometownFromMember, offsetFromPage, titleNameFromMember, toQueryParams, urlWithId } from "../../common/util/common-utils"
import { formatDate } from "../../common/util/format-utils"
import { MemberSorts } from "../../property/constants"

export const currentPageState = atom({
  key: 'dept_detail/current_page',
  default: 1,
})

export const keywordSearchState = atom<string>({
  key: 'dept_detail/keyword',
  default: '',
})

export const currentDeptIdState = atom<number>({
  key: 'dept_detail/id',
  default: 0
})

export const sortByState = atom({
  key: 'dept_detail/sort_by',
  default: MemberSorts[0]
})

export const deptDetailState = selector({
  key: 'dept_detail/info',
  get: async ({get}) => {
    const {url, ...config} = deptDetailEndpoint
    const id = get(currentDeptIdState)
    const resp = await tokenAxios.request({
      url: urlWithId(url, `${id}`),
      ...config
    })

    return resp.data
  }
})

export const memberListState = selector({
  key: 'dept_detail/member_list',
  get: async ({get}) => {
    const deptId = get(currentDeptIdState)
    const page = get(currentPageState)
    const keyword = get(keywordSearchState)
    const sortBy = get(sortByState)
    const resp = await tokenAxios.request({
      ...memberListEndpoint,
      params: toQueryParams({
        offset: offsetFromPage(page),
        limit: 20,
        ...(keyword && {keyword}),
        sort_by: sortBy.query,
        dept_ids: [deptId]
      })
    })

    return resp.data
  },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent'
  }
})

export interface MemberRowType {
  id: number
  order: number
  avatar: string
  fullName: string
  title: string
  hometown: string
  joinedDate?: string
}

export const membersState = selector<MemberRowType[]>({
  key: 'dept_detail/rows',
  get: ({get}) => {
    const paging = get(paginationState)
    const offset = (paging.page - 1)*20 + 1
    return get(memberListState).rows.map((e: any, index: number) => ({
      id: e.id,
      order: offset + index,
      avatar: e.avatarUrl,
      fullName: fullNameFromMember(e),
      title: titleNameFromMember(e),
      hometown: hometownFromMember(e),
      joinedDate: e.joinedAt && formatDate(new Date(e.joinedAt))
    } as MemberRowType))
  },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent'
  }
})

export const paginationState = selector<PaginationType>({
  key: 'dept_detail/pagination',
  get: ({get}) => {
    const total = Math.ceil(get(memberListState).count/20)
    const page = get(currentPageState)

    return {
      page, total
    }
  },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent'
  }
})