import { Box } from "@mui/material"
import ReactStickyBox from "react-sticky-box"
import useAppBarHeight from "../hooks/use_appbar_height"
import DrawerHeader from "./drawer.header"

export function SideBar(props: any) {
  const {children, sx} = props
  const height = useAppBarHeight()
  
  return <Box
    sx={[
      {
        width: 1,
        maxWidth: 300,    
      },
      ...(Array.isArray(sx) ? sx : [sx])
    ]}
  >
    <ReactStickyBox offsetTop={height + 20} offsetBottom={20}>
      {children}
    </ReactStickyBox>
  </Box>
}