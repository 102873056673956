import { Box, Button } from "@mui/material";

export function DialogActionConfirm() {
  return <Box
  sx={{
    width: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    mt: 2,
  }}
>
  <Button
      variant="contained"
      type='submit'
      sx={{
        width: 200
      }}
    >
      Xác nhận
    </Button>
</Box>
}