import { Box, Button, CardContent } from "@mui/material";
import { CardTitle, HgCard, HgCardContent } from "../../common/components/cards";
import { AddImageButton } from "./add_image.button";
import { SortableGrid } from "./sortable_grid";

export function PropertyImageContent(props: any) {
  const { formik, fromDialog } = props

  const handleDrop = (files: File[]) => {
    formik.setFieldValue('images', [
      ...formik.values.images,
      ...files
    ])
  }

  const setImages = (files: File[]) => {
    formik.setFieldValue('images', files)
  }

  return <>
    <AddImageButton handleDrop={handleDrop}/>
    <Box sx={{height: 20}}/>
    <SortableGrid items={formik.values.images} setItems={setImages} sx={fromDialog && {zIndex: 2000}}/>
  </>
}

