import { TextareaAutosize } from "@mui/material";
import { CardHint } from "../../common/components/cards";

export function DescriptionContent(props: any) {
  const { formik } = props

  return <>
    <TextareaAutosize
        id='description'
        defaultValue={formik.values.description}
        onChange={formik.handleChange}
        aria-label="empty textarea"
        minRows={15}
        maxRows={30}
        style={{ 
          width: '100%',
          minHeight: '100px',
          maxHeight: '400px',
          resize: 'vertical',
          fontFamily: 'Nunito Sans', 
          padding: '10px'
        }}
        placeholder='Nhập mô tả về bất động sản...'
      />
      <CardHint>
        Tối thiểu 30 ký tự, tối đa 3.000 ký tự
      </CardHint>
  </>
}