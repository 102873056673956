import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import { AxiosInterceptorNavigate } from './modules/common/components/axis_intercept';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <BrowserRouter>
        <AxiosInterceptorNavigate />
        <App />
    </BrowserRouter>
);

reportWebVitals();
