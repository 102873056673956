import { Box, Chip, Divider, Grid, TextareaAutosize } from "@mui/material";
import { CardLabel, CardSubtitle, CardTitle, HgCard, HgCardContent } from "../../common/components/cards";
import { LabelNumberField } from "../../common/components/label_number_field";
import { furnitureTypes, legalTypes } from "../constants";
import { AddImageButton } from "./add_image.button";
import { NumberStepperRow } from "./number_stepper.row";
import { SortableGrid } from "./sortable_grid";

export function InfoContent(props: any) {
  const {formik, fromDialog} = props

  const setNumFloor = (num: number) => {
    formik.setFieldValue('numFloor', num)
  }

  const setNumBed = (num: number) => {
    formik.setFieldValue('numBed', num)
  }

  const setNumToilet = (num: number) => {
    formik.setFieldValue('numToilet', num)
  }

      return <Grid
        container
        rowSpacing={2}
        columnSpacing={4}
        columns={2}
        sx={{
          mt: 2,
        }}
      >
        <Grid item sm={2}>
        <LabelNumberField
          id='price'
          text={formik.values.price}
          handleChange={formik.handleChange}
          error={formik.touched.price && Boolean(formik.errors.price)}
          helperText={formik.touched.price && formik.errors.price}
          label='Mức giá'
          required={true}
          placeholder='Nhập mức giá...'
          unit='đồng'
        />
        </Grid>
        <Grid item sm={1}>
        <LabelNumberField
          id='area'
          text={formik.values.area}
          handleChange={formik.handleChange}
          error={formik.touched.area && Boolean(formik.errors.area)}
          helperText={formik.touched.area && formik.errors.area}
          label='Diện tích thực tế'
          required={true}
          placeholder='Nhập diện tích đo được trên thực tế...'
          unit='m²'
        />
        </Grid>
        <Grid item sm={1}>
        <LabelNumberField
          id='area1'
          text={formik.values.area1}
          handleChange={formik.handleChange}
          error={formik.touched.area1 && Boolean(formik.errors.area1)}
          helperText={formik.touched.area1 && formik.errors.area1}
          label='Diện tích trên sổ'
          required={true}
          placeholder='Nhập diện tích ghi trên sổ...'
          unit='m²'
        />
        </Grid>
        <Grid item sm={1}>
        <LabelNumberField
          id='frontWidth'
          text={formik.values.frontWidth}
          handleChange={formik.handleChange}
          error={formik.touched.frontWidth && Boolean(formik.errors.frontWidth)}
          helperText={formik.touched.frontWidth && formik.errors.frontWidth}
          label='Mặt tiền'
          placeholder='Nhập độ rộng mặt tiền...'
          unit='m'
        />
        </Grid>
        <Grid item sm={1}>
        <LabelNumberField
          id='roadWidth'
          text={formik.values.roadWidth}
          handleChange={formik.handleChange}
          error={formik.touched.roadWidth && Boolean(formik.errors.roadWidth)}
          helperText={formik.touched.roadWidth && formik.errors.roadWidth}
          label='Đường vào'
          placeholder='Nhập độ rộng đường vào...'
          unit='m'
        />
        </Grid>
        <Grid item sm={2}>
          <Legal formik={formik} sx={fromDialog && {zIndex: 2000}}/>
        </Grid>
        <Grid item sm={2}>
          <Furniture formik={formik}/>
        </Grid>
        <Grid item sm={2}>
          <Box>
            <Divider
              sx={{
                mt: 1,
                mb: 1
              }}
            />
            <NumberStepperRow
              label='Số tầng'
              text={formik.values.numFloor}
              setText={setNumFloor}
            />
            <Divider
              sx={{
                mt: 1,
                mb: 1
              }}
            />
            <NumberStepperRow
              label='Số phòng ngủ'
              text={formik.values.numBed}
              setText={setNumBed}
            />
            <Divider
              sx={{
                mt: 1,
                mb: 1
              }}
            />
            <NumberStepperRow
              label='Số phòng toilet'
              text={formik.values.numToilet}
              setText={setNumToilet}
            />
          </Box>
        </Grid>
      </Grid>
}

function Legal(props: any) {
  const {formik, sx} = props

  const handleDrop = (files: File[]) => {
    formik.setFieldValue('legalImages', [
      ...formik.values.legalImages,
      ...files
    ])
  }

  const setLegalImages = (files: File[]) => {
    formik.setFieldValue('legalImages', files)
  }

  const handleChipClicked = (type: any) => () => {
    formik.setFieldValue('legalType', type)
  }

  return <Box
    display='flex'
    flexDirection='column'
  >
    <CardLabel>
      Thông tin pháp lý
    </CardLabel>
    <Grid container
      rowSpacing={2}
      columnSpacing={{sm: 2, md: 4}}
      sx={{
        mt: 0.5,
        mb: 2
      }}
    >
      {legalTypes.map((e: any, i: number) => (
        <Grid key={i} item>
          {formik.values.legalType.value === e.value ? (
            <Chip
              key={i}
              label={e.description}
            />
          ) : (
            <Chip
              key={i}
              label={e.description}
              variant="outlined"
              onClick={handleChipClicked(e)}
            />
          )}
        </Grid>
      ))}
    </Grid>
    <TextareaAutosize
      id='legalDescription'
      defaultValue={formik.values.legalDescription ?? ''}
      onChange={formik.handleChange}
      aria-label="empty textarea"
      minRows={1}
      maxRows={5}
      style={{ 
        width: '100%',
        minHeight: '50px',
        maxHeight: '100px',
        resize: 'vertical',
        fontFamily: 'Nunito Sans', 
        padding: '10px',
      }}
      placeholder='Nhập mô tả bổ sung về pháp lý...'
    />
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        mt: 2,
        mb: 2
      }}
    >
      <CardSubtitle>
        Ảnh chứng nhận
      </CardSubtitle>
      <Divider 
        sx={{
          flexGrow: 1,
          ml: 2
        }}
      />
    </Box>
    <AddImageButton handleDrop={handleDrop}/>
    <Box sx={{height: 20}}/>
    <SortableGrid items={formik.values.legalImages} setItems={setLegalImages} sx={sx}/> 
  </Box>
}

function Furniture(props: any) {
  const { formik } = props

  const handleChipClicked = (type: any) => () => {
    formik.setFieldValue('furnitureType', type)
  }

  return <Box
    display='flex'
    flexDirection='column'
  >
    <CardLabel>
      Tình trạng nội thất
    </CardLabel>
    <Grid container
      rowSpacing={2}
      columnSpacing={{sm: 2, md: 4}}
      sx={{
        mt: 0.5,
        mb: 2
      }}
    >
      {furnitureTypes.map((e: any, i: number) => (
        <Grid key={i} item>
          {formik.values.furnitureType.value === e.value ? (
            <Chip
              key={i}
              label={e.description}
            />
          ) : (
            <Chip
              key={i}
              label={e.description}
              variant="outlined"
              onClick={handleChipClicked(e)}
            />
          )}
        </Grid>
      ))}
    </Grid>
    <TextareaAutosize
      id='furnitureDescription'
      defaultValue={formik.values.furnitureDescription ?? ''}
      onChange={formik.handleChange}
      aria-label="empty textarea"
      minRows={1}
      maxRows={5}
      style={{ 
        width: '100%',
        minHeight: '50px',
        maxHeight: '100px',
        resize: 'vertical',
        fontFamily: 'Nunito Sans', 
        padding: '10px',
      }}
      placeholder='Nhập mô tả bổ sung về tình trạng nội thất...'
    />
  </Box>
}