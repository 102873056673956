import { Box, Button, Grid } from "@mui/material";
import { Container } from "@mui/system";
import { CardTitle, HgCard, HgCardContent } from "../../common/components/cards";
import { LabelTextField } from "../../common/components/text_field_label";
import * as yup from 'yup'
import { useFormik } from "formik";
import DrawerHeader from "../../common/components/drawer.header";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../redux/hooks";
import { AxiosError } from "axios";
import { kUnknownError } from "../../common/util/constants";
import { setAlert } from "../../redux/alert.slice";
import { tokenAxios } from "../../common/axios";
import { changePasswordEndpoint } from "../../common/endpoints";

export function ChangePasswordView() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        await tokenAxios.request({
          ...changePasswordEndpoint,
          data: {
            oldPassword: values.oldPassword,
            newPassword: values.newPassword
          }
        })
        
        dispatch(setAlert({open: true, content: 'Thay đổi mật khẩu thành công. Phiên làm việc hiện tại đã hết hiệu lực, vui lòng đăng nhập lại'}))
        navigate('/login')
      } catch(e) {
        const msg = e instanceof AxiosError ? e.message : kUnknownError
        dispatch(setAlert({open: true, content: msg}))
      }
    }
  })

  return <form onSubmit={formik.handleSubmit}>
    <DrawerHeader/>
    <Container maxWidth='md'>
      <HgCard>
        <HgCardContent>
        <CardTitle>
          Thay đổi mật khẩu
        </CardTitle>
        <Grid
          container
          rowSpacing={2}
          columnSpacing={4}
          columns={1}
          sx={{
            mt: 2,
          }}
        >
          <Grid item xs={1}>
            <LabelTextField
              id='oldPassword'
              type="password"
              text={formik.values.oldPassword}
              handleChange={formik.handleChange}
              error={formik.touched.oldPassword && Boolean(formik.errors.oldPassword)}
              helperText={formik.touched.oldPassword && formik.errors.oldPassword}
              label='Mật khẩu cũ'
              required={true}
              placeholder='Nhập mật khẩu cũ...'
            />
          </Grid>
          <Grid item xs={1}>
            <LabelTextField
              id='newPassword'
              type="password"
              text={formik.values.newPassword}
              handleChange={formik.handleChange}
              error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
              helperText={formik.touched.newPassword && formik.errors.newPassword}
              label='Mật khẩu mới'
              required={true}
              placeholder='Nhập mật khẩu mới...'
            />
          </Grid>
          <Grid item xs={1}>
            <LabelTextField
              id='confirmPassword'
              type="password"
              text={formik.values.confirmPassword}
              handleChange={formik.handleChange}
              error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
              helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
              label='Xác nhận mật khẩu mới'
              required={true}
              placeholder='Nhập lại mật khẩu mới...'
            />
          </Grid>
        </Grid>
        <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              mt: 4,
              mb: 3
            }}
          >
            <Button
              color="primary" 
              variant="contained" 
              type="submit"
              sx={{
                width: 200
              }}
            >
              Xác nhận
            </Button>
          </Box>
        </HgCardContent>
      </HgCard>
    </Container>
  </form>
}

const validationSchema = yup.object({
  oldPassword: yup.string().required('Mật khẩu cũ là bắt buộc phải nhập'),
  newPassword: yup.string()
    .min(6, 'Mật khẩu phải từ 6 ký tự trở lên')
    .required('Mật khẩu mới là bắt buộc'),
  confirmPassword: yup.string()
    .oneOf([yup.ref('newPassword'), null], 'Mật khẩu nhập lại không khớp')
    .required('Nhập lại mật khẩu')
})