import { Search } from "@mui/icons-material";
import { Button, FormControl, Input, InputAdornment, InputLabel, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { AllowedTo } from "react-abac";
import { useRecoilState, useRecoilTransaction_UNSTABLE, useRecoilValue } from "recoil";
import { Permission } from "../../authz";
import { SearchTextField } from "../../common/components/search.textfield";
import { SortByButton } from "../../common/components/sort_by.button";
import { SortBys } from "../constants";
import { currentPageState, keywordSearchState, sortByState } from "../state/property_list.state";

export function SearchPanel(props: any) {
  const {sx} = props

  const [text, setText] = useState<string>('')
  const [keyword] = useRecoilState(keywordSearchState)
  const setKeyword = useRecoilTransaction_UNSTABLE(({set, reset}) => (kw: string) =>  {
    reset(currentPageState)
    set(keywordSearchState, kw)
  })
  
  useEffect(() => {
    setText(keyword ?? '')
  }, [keyword])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  const handleEnterPressed = (value: string) => setKeyword(value)

  const handClick = (event: any) => {
    setKeyword(text)
  }
  
  return <Box
    display='flex'
    flexDirection='row'
    sx={[
      ...(Array.isArray(sx) ? sx : [sx])
    ]}
  >
      <SearchTextField
        placeholder='Loại BĐS, địa chỉ...'
        value={text}
        onChange={handleChange}
        onSubmit={handleEnterPressed}
      />
      <Button 
        variant="contained"
        onClick={handClick}
        sx={{
          display: {sm: 'none', md: 'block'},
          minWidth: 100
        }}
      >
        Tìm kiếm
      </Button>
    <Box flexGrow={1}/>
    <AllowedTo
      perform={Permission.CREATE_PROPERTY}
    >
      <Button 
        variant="contained"
        sx={{
          mr: 1,
          display: {sm: 'none', md: 'block'},
          minWidth: 100
        }}
        href='new-property'
      >
        + Đăng tin
      </Button>
    </AllowedTo>
    <PropertySortByButton/>
  </Box>
}

function PropertySortByButton(props: any) {
  const [sortBy, setSortBy] = useRecoilState(sortByState)

  return <SortByButton
    item={sortBy}
    setItem={setSortBy}
    items={SortBys}
  />
}