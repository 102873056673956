import { selector } from "recoil";
import { tokenAxios } from "../axios";
import { titleListEndpoint } from "../endpoints";

export const allTitlesState = selector({
  key: 'titles',
  get: async () => {
    const resp = await tokenAxios.request(titleListEndpoint)

    return resp.data.rows
  }
})