import { Card, CardContent, Grid, TextField } from "@mui/material";
import { useEffect } from "react";
import { useRecoilState, useRecoilValueLoadable } from "recoil";
import { CardTitle, HgCard, HgCardContent } from "../../common/components/cards";
import { DropDownLabelButton } from "../../common/components/dropdown_label.button";
import { SortByButton } from "../../common/components/sort_by.button";
import { LabelTextField } from "../../common/components/text_field_label";
import { citiesState, districtsState, wardsesState } from "../../common/state/location.state";
import { LocationType } from "../../common/types";
import { address1State, address2State, addressState, cityState, districtState, wardsState } from "../state/property_create.state";

export function LocationCard(props: any) {
  const {sx, formik} = props
  const cityList = useRecoilValueLoadable(citiesState)
  const cities: LocationType[] = cityList.state === 'hasValue' ? cityList.contents : []
  const districtsAV = useRecoilValueLoadable(districtsState(formik.values.city?.id ?? -1))
  const districts: LocationType[] = districtsAV.state === 'hasValue' ? districtsAV.contents : []
  const wardsesAV = useRecoilValueLoadable(wardsesState(formik.values.district?.id ?? -1))
  const wardses: LocationType[] = wardsesAV.state == 'hasValue' ? wardsesAV.contents : []

  const handleCityChanged = (e: any) => {
    formik.setFieldValue('district', null)
    formik.setFieldValue('wards', null)
    formik.setFieldValue('city', e)
  }

  const handleDistrictChanged = (e: any) => {
    formik.setFieldValue('wards', null)
    formik.setFieldValue('district', e)
  }

  const handleWardsChanged = (e: any) => {
    formik.setFieldValue('wards', e)
  }

  return <Grid
    container
    rowSpacing={2}
    columnSpacing={4}
    columns={2}
    sx={{
      mt: 2
    }}
  >
    <Grid item sm={1}>
    <DropDownLabelButton
      id='city'
      item={formik.values.city}
      items={cities}
      handleChange={handleCityChanged}
      error={formik.touched.city && Boolean(formik.errors.city)}
      helperText={formik.touched.city && formik.errors.city}
      label='Tỉnh/Thành phố'
      required={true}
      placeholder='Chọn tỉnh/thành phố'
    />
    </Grid>
    <Grid item sm={1}>
    <DropDownLabelButton
      id='district'
      item={formik.values.district}
      items={districts}
      handleChange={handleDistrictChanged}
      error={formik.touched.district && Boolean(formik.errors.district)}
      helperText={formik.touched.district && formik.errors.district}
      label='Quận/Huyện'
      required={true}
      placeholder='Chọn quận/huyện'
    />
    </Grid>
    <Grid item sm={1}>
    <DropDownLabelButton
      id='wards'
      item={formik.values.wards}
      items={wardses}
      handleChange={handleWardsChanged}
      error={formik.touched.wards && Boolean(formik.errors.wards)}
      helperText={formik.touched.wards && formik.errors.wards}
      label='Phường/Xã'
      required={true}
      placeholder='Chọn phường/xã'
    />
    </Grid>
    <Grid item sm={2}>
    <LabelTextField
      id='address'
      text={formik.values.address}
      handleChange={formik.handleChange}
      error={formik.touched.address && Boolean(formik.errors.address)}
      helperText={formik.touched.address && formik.errors.address}
      label='Địa chỉ thực tế'
      required={true}
      placeholder='Nhập địa chỉ chi tiết như số nhà, ngõ, ngách, hẻm...'
    />
    </Grid>
    <Grid item xs={2}>
    <LabelTextField
      id='address1'
      text={formik.values.address1}
      handleChange={formik.handleChange}
      error={formik.touched.address1 && Boolean(formik.errors.address1)}
      helperText={formik.touched.address1 && formik.errors.address1}
      label='Địa chỉ trên sổ'
      required={true}
      placeholder='Nhập địa chỉ chi tiết như số nhà, ngõ, ngách, hẻm...'
    />
    </Grid>
    <Grid item xs={2}>
    <LabelTextField
      id='address2'
      text={formik.values.address2}
      handleChange={formik.handleChange}
      error={formik.touched.address2 && Boolean(formik.errors.address2)}
      helperText={formik.touched.address2 && formik.errors.address2}
      label='Địa chỉ khác'
      placeholder='Nhập địa chỉ chi tiết như số nhà, ngõ, ngách, hẻm...'
    />
    </Grid>
  </Grid>
}