import { styled, Typography, TypographyProps } from "@mui/material";

export function ListItemTextSideBar(props: any) {
  const {selected} = props

  return <Typography
    sx={{
      fontSize: 14,
      fontWeight: selected ? 700 : 600,
      textDecoration: selected ? 'underline' : 'none'
    }}
  >
    {props.children}
  </Typography>
}

export const TitleSidebar = styled((props: TypographyProps) => <Typography 
   sx={{ml: 2, mr: 2, mt: 2, mb: 1}} {...props}
>{props.children}</Typography>)(() => ({
  fontSize: 16,
  fontWeight: 700,
}))