import { Button } from "@mui/material";
import Dropzone from "react-dropzone";
import { NoImageIcon } from "../../icons";

export function AddImageButton(props: any) {
  const {handleDrop} = props

  return <Dropzone onDrop={handleDrop}>
    {({getRootProps, getInputProps}) => (
        <Button
          {...getRootProps()}
          sx={{
            width: 0.99,
            height: 200,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 2,
            backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23333' stroke-width='1' stroke-dasharray='5' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e")`
          }}
        >
          <input {...getInputProps()} />
          <NoImageIcon width={100} height={100}/>
          <p>Kéo thả ảnh vào đây để thêm ảnh hoặc click vào đây để thêm.</p>
        </Button>
    )}
  </Dropzone>
}