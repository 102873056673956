import { AppBar, Box, Toolbar } from "@mui/material";
import { LogoYellowIcon } from "../../icons";
import { useShortcutId } from "./shortcut";
import { HgTab } from "./tabs/tab";
import { UserProfile } from "./user_profile";

export function ResponsiveAppBar() {
  const selectedId = useShortcutId()

  return <AppBar
      elevation={0}
      sx={{
        bgcolor: '#fff',
        borderBottom: 0.5,
        borderColor: '#aeaeae'
      }}
      style={{
        width: selectedId == 0 ? '100%' : `calc(100% - 60px)`,
      }}
    >
      <Toolbar
        disableGutters
        sx={{
          alignItems: 'stretch'
        }}
      >
        {selectedId == 0 && <LogoYellowIcon width={60} height={60}/>}
        <HgTab/>
        <Box sx={{flexGrow: 1}}></Box>
        <UserProfile/>
      </Toolbar>
    </AppBar>
}