import { styled, Typography, TypographyProps } from "@mui/material";

export const ViewTitle = styled(Typography)<TypographyProps>(() => ({
  fontSize: 22,
  fontWeight: 800
}))

export const ViewSubtitle = styled(Typography)<TypographyProps>(() => ({
  fontSize: 16,
  fontWeight: 500
}))