import {
  Box, Container, Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { AbacProvider } from "react-abac";

import {Outlet, useNavigate} from "react-router-dom";
import { useRecoilValueLoadable } from "recoil";
import { getClientToken, onMessageListener } from "../../firebase";
import { abacRules } from "../authz";
import { tokenAxios } from "../common/axios";
import { ErrorBoundary } from "../common/components/error_boundary";
import { ProgressBox } from "../common/components/progress_box";
import { logoutEndpoint, newDeviceTokenEndpoint } from "../common/endpoints";
import { authState } from "../common/state/auth.state";
import { ResponsiveAppBar } from "./components/appbar";
import { Shortcut } from "./components/shortcut";
import { getMessaging, onMessage } from "firebase/messaging"
import useUUID from "../common/hooks/use_uuid";
import { useIdleTimer, workerTimers } from 'react-idle-timer'
import { UAParser } from "ua-parser-js";
import HgAppBar from "../auth/components/appbar";
import Footer from "../auth/components/footer";

function AppLayout() {
  const auth = useRecoilValueLoadable(authState)
  const {enqueueSnackbar} = useSnackbar()
  let uuid = useUUID()
  
  const navigate = useNavigate()

  const onIdle = async () => {
    try {
      await tokenAxios.request(logoutEndpoint)
      navigate('login')
    } catch(e) {
      
    }
  }

  const idleTimer = useIdleTimer({ 
    timers: workerTimers,
    crossTab: true,
    timeout: 1000 * 60 * 30,
    onIdle: onIdle
  })

  useEffect(() => {
    window.Notification.requestPermission().then((permission) => { 
      console.log(permission);
           
      if(permission == 'granted') {
        const ua = UAParser()
        getClientToken(async (token: string) => {
          if(token != null) {
            try {
              await tokenAxios.request({
                ...newDeviceTokenEndpoint,
                data: {
                  token,
                  model: `${ua.browser.name}.${ua.browser.version}-${ua.os.name}.${ua.os.version}`,
                  uuid: uuid,
                  platform: 'web'
                }
              })
            } catch(e) {}
          }
        });
      }
    })
  }, [])

  const messaging = getMessaging();
  onMessage(messaging, (payload: any) => {
    enqueueSnackbar(payload.notification.body)
  });

  if(auth.state == 'loading') {
    return <ProgressBox/>
  }

  if(auth.state == 'hasError') {
    return <ErrorBoundary><></></ErrorBoundary>
  }

  return <AbacProvider
    user={auth.contents.member}
    roles={[auth.contents.role != 'ADMIN' ? 'USER' : 'ADMIN']}
    rules={abacRules}
    permissions={[]}
  >
    <ResponsiveAppBar/>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row'
      }}
    >
      <Shortcut/>
      <Box 
        component="main" 
        sx={{ 
          flexGrow: 1,
          m: {xs: 2, md: 4, lg: 6, xl: 8}
        }}
      >
        <Outlet/>
      </Box>
    </Box>
  </AbacProvider>
}

export default AppLayout