import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

export function ConfirmDialog(props: any) {
  const {open, setOpen, content, title, onClose, positiveTitle, negativeTitle} = props

  const handleClose = (positive: boolean) => () => {
    setOpen(false);
    if(onClose != null) {
      onClose(positive)
    }
  };

  return <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    sx={{
      minWidth: 200
    }}
  >
    <DialogTitle id="alert-dialog-title">
      {title ?? 'Thông báo'}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {content}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button key={0} onClick={handleClose(true)}>
        {positiveTitle ?? 'CÓ'}
      </Button>
      <Button key={1} onClick={handleClose(false)} autoFocus>
        {negativeTitle ?? 'KHÔNG'}
      </Button>
    </DialogActions>
  </Dialog>
}