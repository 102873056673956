import { Clear } from "@mui/icons-material"
import { Box, Dialog, DialogContent, IconButton } from "@mui/material"
import { useState } from "react"
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery'
import { ImageContent, ImageThumbnail, LeftNav, RightNav } from "./image_gallerys"

interface ImageFullScreenDialogProps {
  images: ReactImageGalleryItem[]
  currentPage?: number | undefined
  open: boolean
  setOpen: any
}

export function ImageFullScreenDialog({images, open, setOpen, currentPage}: ImageFullScreenDialogProps) {

  const renderLeftNav = (onClick: React.MouseEventHandler<HTMLElement>, disable: boolean) => {
    return <LeftNav
      onClick={onClick}
      disabled={disable}
    />
  }

  const renderRightNav = (onClick: React.MouseEventHandler<HTMLElement>, disable: boolean) => {
    return <RightNav
      onClick={onClick}
      disabled={disable}
    />
  }

  const renderItem = (item: ReactImageGalleryItem) => {
    return <ImageContent item={item}/>
  }

  const renderThumbInner = (item: ReactImageGalleryItem) => {
    return <ImageThumbnail item={item}/>
  }

  const handleClose = () => {
    setOpen(false)
  }

  return <Dialog
    open={open}
    fullScreen
    PaperProps={{
      style: {
        backgroundColor: '#000',
        boxShadow: 'none',
        margin: 0,  
      },
    }}
  >
    <DialogContent>
      <Box
        sx={{
          width: 1,
          display: 'flex',
          flexDirection: 'row',
          
          mb: 2,
        }}
      >
        <IconButton
          onClick={handleClose}
        >
          <Clear style={{ color: '#fff'}}/>
        </IconButton>
      </Box>
      <ImageGallery
        items={images}
        showPlayButton={false}
        showFullscreenButton={false}
        infinite={false}
        lazyLoad={true}
        renderLeftNav={renderLeftNav}
        renderRightNav={renderRightNav}
        renderItem={renderItem}
        renderThumbInner={renderThumbInner}
        startIndex={currentPage}
      />
      </DialogContent>
  </Dialog>
}