import {v4 as uuidv4} from 'uuid';

export default function useUUID() {
  let uuid = localStorage.getItem('uuid')
  if(uuid == null) {
    uuid = uuidv4()
    if(uuid != null) {
      localStorage.setItem('uuid', uuid)
    }
  }

  return uuid
}