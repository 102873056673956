import { Box, Typography } from "@mui/material";
import { useRecoilValue } from "recoil";
import { EmptyDataBox } from "../../common/components/empty_data.box";
import { EmptyProperty } from "../../icons";
import { hasAnyFilterState } from "../state/property_list.state";

export function EmptyPropertyBox() {
  const hasAnyFilter = useRecoilValue(hasAnyFilterState)

  return <Box
    sx={[
      {
        width: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyItems: 'stretch',
      },
    ]}
  >
    <EmptyProperty/>
    <Typography
      align="center"
      sx={{
        mt: 2,
        mb: 2
      }}
    >
      Rất tiếc, hiện tại chưa có bất động sản nào ở khu vực này.
    </Typography>
    {hasAnyFilter && <Typography
      align="center"
    >
        Hãy thay đổi bộ lọc để có thêm nhiều kết quả hơn!
    </Typography>}
  </Box>
}