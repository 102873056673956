import { Box, List, ListItemButton, Typography } from "@mui/material";
import { useRecoilState, useRecoilTransaction_UNSTABLE } from "recoil";
import { ListItemTextSideBar, TitleSidebar } from "../../common/components/list_item_text.sidebar";
import { rangePrices } from "../constants";
import { currentPageState, rangePriceFilterState } from "../state/property_list.state";
import { titleFromRangePrice } from "../utils";

export function PriceRangePanel(props: any) {
  const {sx} = props
  const updateRangeFilter = useRecoilTransaction_UNSTABLE(({set}) => (range: any) => {
    set(currentPageState, 1)
    set(rangePriceFilterState, range)
  })
  const [rangePrice] = useRecoilState(rangePriceFilterState)

  const handleItemClick = (data: any) => () => {
    updateRangeFilter(data)
  }

  return <Box
    sx={[
      {
        border: 0.5,
        borderColor: '#999',
        borderRadius: 2,
      },
      ...(Array.isArray(sx) ? sx : [sx])
    ]}
  >
    <TitleSidebar>
      Khoảng giá
    </TitleSidebar>
    <List>
      {rangePrices.map((e: any, i: number) => <ListItemButton 
        key={i}
        onClick={handleItemClick(e)}
      >
        <ListItemTextSideBar
          selected={rangePrice === e}
        >
          {`${titleFromRangePrice(e)}`}
        </ListItemTextSideBar>
      </ListItemButton>)}
    </List>
  </Box>
}
