import { Box, styled, Tab, TabProps, Tabs, Typography } from "@mui/material";
import React from "react";
import { AllowedTo, useAbac } from "react-abac";
import { Location, useLocation, useSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { Permission } from "../../../authz";
import { myDeptIdState } from "../../../common/state/auth.state";

export function HgTab() {
  const location = useLocation()
  if(location.pathname.startsWith('/properties')) {
    return <PropertyTabs location={location}/>
  } else if(location.pathname.startsWith('/employee/')) {
    return <EmployeeTabs location={location}/>
  }

  return <></>
}

interface HgTabProps {
  location: Location
}

function EmployeeTabs({location}: HgTabProps) {
  let initTabIndex = 0
  const deptId = useRecoilValue(myDeptIdState)
  
  if(location.pathname === '/employee/depts') {
    initTabIndex = 1
  } else if(location.pathname.startsWith('/employee/dept/')) {
    initTabIndex = location.pathname === `/employee/dept/${deptId}` ? 2 : 0
  }

  return <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    }}
  >
    <Box sx={{flexGrow: 1}}/>
    <Tabs value={initTabIndex}>
      <StyledTab label='Danh sách thành viên' href='/employee/members' {...a11yProps(0)}/>
      <StyledTab label='Danh sách phòng ban' href='/employee/depts' {...a11yProps(1)}/>
      {deptId && <StyledTab label='Phòng ban của tôi' href={`/employee/dept/${deptId}`} {...a11yProps(2)}/>}
    </Tabs>
  </Box>
}

function PropertyTabs({location}: HgTabProps) {
  const [searchParams] = useSearchParams()
  let initTabIndex = 0
  if(searchParams.get('fav-only')) {
    initTabIndex = 1
  } else if(searchParams.get('my-properties')) {
    initTabIndex = 2
  } else if(searchParams.get('pending-properties')) {
    initTabIndex = 3
  }

  const abac = useAbac()

  return <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    }}
  >
    <Box sx={{flexGrow: 1}}/>
    <Tabs value={initTabIndex}>
      <StyledTab label='Danh sách tin' href='/properties' {...a11yProps(0)}/>
      <StyledTab label='Tin yêu thích' href='/properties?fav-only=true' {...a11yProps(1)}/>
      {abac.userHasPermissions(Permission.VIEW_MYPROPERTY) && <StyledTab label='Tin tôi tạo' href='/properties?my-properties=true' {...a11yProps(2)}/>}
      {abac.userHasPermissions(Permission.VIEW_PEDNING) && <StyledTab label='Tin chờ duyệt' href='/properties?pending-properties=true' {...a11yProps(3)}/>}
    </Tabs>
  </Box>
}

const Head = styled(Typography)({
  fontSize: 22,
  fontWeight: 800,
  color: '#000'
})

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const StyledTab = styled((props: any) => <Tab disableRipple {...props} />)({
  fontWeight: 600,
  fontSize: 16
})