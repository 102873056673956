import {atom, selector, selectorFamily} from "recoil";
import {tokenAxios} from "../../common/axios";
import {propertyListEndpoint} from "../../common/endpoints";
import { ListStateType, PaginationType } from "../../common/types";
import {offsetFromPage, toQueryParams} from "../../common/util/common-utils";
import {
  furnitureTypeFilters,
  legalTypeFilters,
  numBedTypes,
  numFloorTypes, numToiletTypes,
  rangeAreas,
  rangeHouseFronts,
  rangePrices,
  SortBys
} from "../constants";
import { isDefaultRangeValue, isDefaultValueType, queryFromRangeValue, queryFromValue } from "../utils";

interface FilterType {

}

interface RangeValueFilterType {
  from: number
  to: number
}

interface PropertyFilterStateType {
  priceRange: RangeValueFilterType
  acreageRange: RangeValueFilterType
  frontWidthRange: RangeValueFilterType
  legalType: number
  furnitureType: number
  numFloor: number
  numBed: number
  numToilet: number
}

export const currentPageState = atom({
  key: 'property_list/current_page',
  default: 1,
})

export const legalFilterState = atom({
  key: 'property_list/filters/legal',
  default: legalTypeFilters[0]
})

export const furnitureFilterState = atom({
  key: 'property_list/filters/furniture',
  default: furnitureTypeFilters[0]
})

export const rangePriceFilterState = atom({
  key: 'property_list/filters/range_price',
  default: rangePrices[0],
})

export const rangeAreaFilterState = atom({
  key: 'property_list/filters/range_area',
  default: rangeAreas[0],
})

export const rangeFrontFilterState = atom({
  key: 'property_list/filters/range_front',
  default: rangeHouseFronts[0],
})

export const numFloorFilterState = atom({
  key: 'property_list/filters/num_floor',
  default: numFloorTypes[0]
})

export const numBedFilterState = atom({
  key: 'property_list/filters/num_bed',
  default: numBedTypes[0]
})

export const numToiletFilterState = atom({
  key: 'property_list/filters/num_toilet',
  default: numToiletTypes[0]
})

interface LocationType {
  id: number
  name: string
}

interface LocationFilterType {
  city: LocationType
  district: LocationType
}

export const locationFilterState = atom<LocationFilterType | null>({
  key: 'property_list/filters/location',
  default: null
})

export const myPropertiesFilterState = atom({
  key: 'property_list/filters/my_properties',
  default: false,
})

export const pendingPropertiesFilterState = atom({
  key: 'property_list/filters/pending_properties',
  default: false,
})

export const favPropertiesFilterState = atom({
  key: 'property_list/filters/fav_properties',
  default: false
})

export const statusFilterState = atom<number | null>({
  key: 'property_list/filters/status',
  default: null,
})

export const keywordSearchState = atom<string | null>({
  key: 'property_list/filters/keyword',
  default: null,
})

export const sortByState = atom({
  key: 'property_list/sort_by',
  default: SortBys[0],
})

export const hasAnyFilterState = selector<boolean>({
  key: 'property_list/has_any_filter',
  get: ({get}) => {
    const rangePrice = get(rangePriceFilterState)
    const rangeArea = get(rangeAreaFilterState)
    const rangeFront = get(rangeFrontFilterState)
    const legalType = get(legalFilterState)
    const furnitureType = get(furnitureFilterState)
    const numFloor = get(numFloorFilterState)
    const numBed = get(numBedFilterState)
    const numToilet = get(numToiletFilterState)

    return !isDefaultRangeValue(rangePrice)
      || !isDefaultRangeValue(rangeArea)
      || !isDefaultRangeValue(rangeFront)
      || !isDefaultValueType(legalType)
      || !isDefaultValueType(furnitureType)
      || !isDefaultValueType(numFloor)
      || !isDefaultValueType(numBed)
      || !isDefaultValueType(numToilet)
  }
})

export const propertyListState = selector<ListStateType>({
  key: 'property_list/resp',
  get: async ({get}) => {
    const page = get(currentPageState)
    const keyword = get(keywordSearchState)
    const rangePrice = get(rangePriceFilterState)
    const rangeArea = get(rangeAreaFilterState)
    const rangeFront = get(rangeFrontFilterState)
    const legalType = get(legalFilterState)
    const furnitureType = get(furnitureFilterState)
    const numFloor = get(numFloorFilterState)
    const numBed = get(numBedFilterState)
    const numToilet = get(numToiletFilterState)
    const sortBy = get(sortByState)
    const myPropertiesOnly = get(myPropertiesFilterState)
    const pendingOnly = get(pendingPropertiesFilterState)
    const favOnly = get(favPropertiesFilterState)
    const status = get(statusFilterState)
    const location = get(locationFilterState)

    const priceQuery = queryFromRangeValue(rangePrice)
    const areaQuery = queryFromRangeValue(rangeArea)
    const frontQuery = queryFromRangeValue(rangeFront)
    const numFloorQuery = queryFromValue(numFloor)
    const numBedQuery = queryFromValue(numBed)
    const numToiletQuery = queryFromValue(numToilet)
    
    const resp = await tokenAxios.request({
      ...propertyListEndpoint,
      params: toQueryParams({
        offset: offsetFromPage(page),
        limit: 20,
        ...(keyword && {keyword}),
        ...(priceQuery && {price: priceQuery}),
        ...(areaQuery && {area: areaQuery}),
        ...(frontQuery && {front_width: frontQuery}),
        ...(legalType.value > 0 && {legal_type: legalType.value}),
        ...(furnitureType.value > 0 && {furniture_type: furnitureType.value}),
        ...(numFloorQuery && {num_floor: numFloorQuery}),
        ...(numBedQuery && {num_bed: numBedQuery}),
        ...(numToiletQuery && {num_toilet: numToiletQuery}),
        ...(myPropertiesOnly && {created_by_me: true}),
        ...(pendingOnly && {pending_only: true}),
        ...(favOnly && {favorite_only: true}),
        ...(status && {property_state: status}),
        ...(location && {locations: [{city_id: location.city.id, district_id: location.district.id}]}),
        sort_by: sortBy.query,
      })
    })

    return resp.data
  },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent', 
  },
})

export const propertiesState = selector({
  key: 'property_list/data',
  get: ({get}) => {
    return get(propertyListState).rows
  }
})

export const propertyCountState = selector({
  key: 'property_list/count',
  get: ({get}) => {

    try {
      return get(propertiesState).length
    } catch(e) {
      return 0
    }
  }
})

export const propertyItemState = selectorFamily<any, number>({
  key: 'property_list/item',
  get: index => ({get}) => {
    const list = get(propertiesState)
    return list[index]
  }
})

export const paginationState = selector<PaginationType>({
  key: 'property_list/pagination',
  get: ({get}) => {
    let total = 0
    let count = get(propertyListState).count
    if(count > 0) {
      total = Math.ceil(get(propertyListState).count/20)
    }
    const page = get(currentPageState)

    return {
      page, total
    }
  }
})

export const titleState = selector({
  key: 'property_list/title',
  get: ({get}) => {
    const location = get(locationFilterState)
    const favOnly = get(favPropertiesFilterState)

    if(favOnly) {
      return 'Danh sách tin yêu thích'
    }

    let title = 'Danh sách tin'
    if(location != null) {
      if(location.city.name != null) {
        if(location.district.name != null) {
          title += ` ở ${location.district.name}, ${location.city.name}`
        } else {
          title += ` ở ${location.city.name}`
        }
      }
    } else {
      title += ' ở tất cả các khu vực'
    }

    return title
  }
})
