import { atom, selector } from "recoil"
import { tokenAxios } from "../../common/axios"
import { deptListEndpoint } from "../../common/endpoints"
import { ListStateType, PaginationType } from "../../common/types"
import { fullNameFromMember, offsetFromPage, toQueryParams } from "../../common/util/common-utils"
import { kEmptyData } from "../../common/util/constants"
import { DeptSorts } from "../../property/constants"

export const currentPageState = atom({
  key: 'dept_list/current_page',
  default: 1,
})

export const keywordSearchState = atom<string>({
  key: 'dpet_list/keyword',
  default: '',
})

export const sortByState = atom({
  key: 'dept_list/sort_by',
  default: DeptSorts[0]
})

export const deptListState = selector<ListStateType>({
  key: 'dept_list/data',
  get: async ({get}) => {
    const page = get(currentPageState)
    const keyword = get(keywordSearchState)
    const sortBy = get(sortByState)

    const resp = await tokenAxios.request({
      ...deptListEndpoint,
      params: toQueryParams({
        offset: offsetFromPage(page),
        limit: 20,
        ...(keyword && {keyword}),
        sort_by: sortBy.query,
      })
    })
    

    return resp.data
  },
  cachePolicy_UNSTABLE: {
    eviction: 'lru', 
    maxSize: 0
  },
})

export interface DeptRowType {
  id: number
  order: number
  name: string
  manager: any
  totalMember: number
}

export const deptRowsState = selector<DeptRowType[]>({
  key: 'dept_list/rows',
  get: ({get}) => {
    const paging = get(paginationState)
    const offset = (paging.page - 1)*20 + 1
    return get(deptListState).rows.map((e: any, index: number) => ({
      id: e.id,
      order: offset + index,
      name: e.name,
      manager: e.manager.length > 0 ? {
        name: fullNameFromMember(e.manager[0]),
        avatarUrl: e.manager[0].avatarUrl
      } : null,
      totalMember: e.totalMember
    } as DeptRowType))
  },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent'
  }
})

export const paginationState = selector<PaginationType>({
  key: 'dept_list/pagination',
  get: ({get}) => {
    const total = Math.ceil(get(deptListState).count/20)
    const page = get(currentPageState)

    return {
      page, total
    }
  },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent'
  }
})