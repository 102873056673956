import React, {useEffect, useState} from 'react';
import './App.css';
import '@goongmaps/goong-js/dist/goong-js.css';
import {useNavigate, useRoutes} from "react-router-dom";
import Auth from "./modules/auth";
import {createTheme, CssBaseline, GlobalStyles, Slide, ThemeProvider} from "@mui/material";
import "@fontsource/nunito-sans/200.css";
import "@fontsource/nunito-sans/200-italic.css";
import "@fontsource/nunito-sans/300.css";
import "@fontsource/nunito-sans/300-italic.css";
import "@fontsource/nunito-sans/400.css";
import "@fontsource/nunito-sans/400-italic.css";
import "@fontsource/nunito-sans/600.css";
import "@fontsource/nunito-sans/600-italic.css";
import "@fontsource/nunito-sans/700.css";
import "@fontsource/nunito-sans/700-italic.css";
import {orange} from "@mui/material/colors";
import Home from "./modules/home";
import {AuthProvider, useAuth} from "./modules/common/context/auth.context";
import {ConfigProvider} from "./modules/common/context/config.context";
import {RecoilRoot, useRecoilBridgeAcrossReactRoots_UNSTABLE, useRecoilSnapshot, useRecoilState} from "recoil";
import { SnackbarProvider, useSnackbar } from 'notistack';
import { InfoDialog } from './modules/common/components/info.dialog';
import { Provider } from 'react-redux'
import { store } from './modules/redux/store';
import { useAppDispatch, useAppSelector } from './modules/redux/hooks';
import { setAlert } from './modules/redux/alert.slice';
import { SnackMessage } from './modules/common/components/snackbar';

// function DebugObserver(): React.ReactNode {
//   const snapshot = useRecoilSnapshot();
//   useEffect(() => {
//     console.debug('The following atoms were modified:');
//     for (const node of snapshot.getNodes_UNSTABLE({isModified: true})) {
//       console.debug(node.key, snapshot.getLoadable(node));
//     }
//   }, [snapshot]);

//   return null;
// }

const themeLight = createTheme({
  palette: {
    primary: {
      main: orange[400],
      contrastText: '#fff',
    },
    background: {
      default: "#f9f9f9"
    }
  },
  typography: {
    fontFamily: 'Nunito Sans',
    button: {
      textTransform: 'none',
      fontWeight: 600
    }
  },
  
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        
      },
    },
    
  },
  breakpoints: {
    values: {
      xs: 300,
      sm: 500,
      md: 800,
      lg: 1100,
      xl: 1436,
    },
  },
});

const themeDark = createTheme({
  palette: {
    background: {
      default: "#222222"
    },
    text: {
      primary: "#ffffff"
    },

  },
});

function App() {
  const routing = useRoutes([Auth, Home])
  const [light, setLight] = React.useState(true);
  
  return <Provider store={store}>
    <RecoilRoot>
      <ThemeProvider theme={light ? themeLight : themeDark}>
        <AuthProvider>
          <SnackbarProvider 
            maxSnack={3} 
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            TransitionComponent={Slide}
            content={(key, message) => <SnackMessage id={key} message={message} />}
          >
            <CssBaseline />
            <GlobalStyles styles={{
              Button: {
                fontWeight: "bold",
                color: "#000"
              }
            }}/>
            <>{routing}</>
            
          </SnackbarProvider>
        </AuthProvider>
        <HgAlert/>
      </ThemeProvider>
    </RecoilRoot>
  </Provider>
}

function HgAlert() {
  const alert = useAppSelector((s) => s.alert)
  const dispatch = useAppDispatch()

  if(alert.content == null) {
    return <></>
  }
  
  const setOpen = (close: boolean) => {
    dispatch(setAlert({open: false}))
  }

  return <InfoDialog
    open={alert.open}
    setOpen={setOpen}
    content={alert.content}
    onClose={alert.onClose}
  />
  
}

export default App;
