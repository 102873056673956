import { Search } from "@mui/icons-material"
import { Box, Button, InputAdornment, TextField } from "@mui/material"
import { useEffect, useState } from "react"
import { AllowedTo } from "react-abac"
import { useRecoilState } from "recoil"
import { Permission } from "../../authz"
import { SearchTextField } from "../../common/components/search.textfield"
import { SortByButton } from "../../common/components/sort_by.button"
import { MemberSorts } from "../../property/constants"
import { keywordSearchState, sortByState } from "../states/member_list.state"

export function MemberSearchPanel(props: any) {
  const {sx} = props

  const [text, setText] = useState<string>('')
  const [keyword, setKeyword] = useRecoilState(keywordSearchState)
  
  useEffect(() => {
    setText(keyword ?? '')
  }, [keyword])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  const handleEnterPressed = (value: string) => setKeyword(value)

  const handClick = (event: any) => {
    setKeyword(text)
  }
  
  return <Box
    display='flex'
    flexDirection='row'
    sx={[
      ...(Array.isArray(sx) ? sx : [sx])
    ]}
  >
    <SearchTextField
      placeholder='Nhập họ tên, quê quán...'
      value={text}
      onChange={handleChange}
      onSubmit={handleEnterPressed}
    />
    <Button 
      variant="contained"
      onClick={handClick}
    >
      Tìm kiếm
    </Button>
    <Box flexGrow={1}/>
    <AllowedTo
      perform={Permission.CREATE_MEMBER}
    >
      <Button 
        variant="contained"
        sx={{
          mr: 1
        }}
        href='/new-member'
      >
        + Thêm thành viên
      </Button>
    </AllowedTo>
    <MemberSortByButton/>
  </Box>
}

function MemberSortByButton(props: any) {
  const [sortBy, setSortBy] = useRecoilState(sortByState)

  return <SortByButton
    item={sortBy}
    setItem={setSortBy}
    items={MemberSorts}
  />
}