import { ChevronRight } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";

export function ButtonItem(props: any) {
  const {label, onClick, disabled} = props

  return <Button
    onClick={onClick}
    size='small'
    disabled={disabled}
  >
    <Typography
      sx={{
        textTransform: 'none'
      }}
    >
      {label}
    </Typography>
    <Box flexGrow={1}/>
    <ChevronRight/>
</Button>
}