import { Box, FormControl, FormHelperText, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { red } from "@mui/material/colors";
import { CardLabel } from "./cards";

interface DropDownLabelButtonProps {
  id: string
  item: any
  setItem?: any
  handleChange?: any
  items: any[]
  label: string
  required?: boolean
  error?: any
  helperText?: any
  placeholder?: string
  disable?: boolean
}

export function DropDownLabelButton(props: DropDownLabelButtonProps) {
  const {id, item, setItem, handleChange, items, label, required, placeholder, error, helperText, disable} = props

  const handleValueChange = (event: SelectChangeEvent) => {
    setItem(event.target.value)
  };

  const handleItemSelected = (event: SelectChangeEvent) => {
    const id = event.target.value
    const item = items.find((e) => id === e.id)
    handleChange(item)
  }

  return <Box
    display='flex'
    flexDirection='column'
  >
    <CardLabel>
      {label}
      {(required ?? false) && <Typography 
        component='span'
        display='inline'
        sx={{
          ml: 0.5,
        }}
        style={{
          color: 'rgba(255, 0, 0, 1)'
        }}
      >*</Typography>}
    </CardLabel>
    
    <FormControl sx={{ width: 1, mt: 0.5 }} size="small">
      <Select
        disabled={disable}
        labelId="demo-select-small"
        id={id ?? 'se'}
        name={id}
        value={item != null && items.length > 0 ? item.id : ''}
        onChange={handleChange != null ? handleItemSelected : handleValueChange}
        error={error}
        displayEmpty
        renderValue={
          item != null ? undefined : () => <Typography style={{color: '#aaa'}}>{placeholder}</Typography>
        }
        MenuProps={{
          style: {
            maxHeight: 400
          }
        }}
      >
        {items.map((e: any, index: number) => {
          return <MenuItem key={index} value={e.id}>
            {e?.name}
          </MenuItem>
        })}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  </Box>
}