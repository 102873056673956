import {MutableSnapshot, RecoilRoot, useRecoilBridgeAcrossReactRoots_UNSTABLE, useRecoilRefresher_UNSTABLE, useRecoilState, useRecoilValue, useRecoilValueLoadable, useResetRecoilState} from "recoil";
import {favPropertiesFilterState, myPropertiesFilterState, paginationState, pendingPropertiesFilterState, propertyCountState, propertyListState, rangeAreaFilterState, rangePriceFilterState, statusFilterState, titleState} from "../state/property_list.state";
import React, {useEffect} from "react";
import {Box, Container, Divider, Grid, List, Pagination, Stack, Toolbar, Typography} from "@mui/material";
import {PropertyRow} from "../components/property.row";
import {PropertyListPagination} from "../components/property_list.pagination";
import DrawerHeader from "../../common/components/drawer.header";
import {PropertyFilterPanel} from "../components/property_filter.panel";
import { SearchPanel } from "../components/search.panel";
import { ErrorBoundary } from "../../common/components/error_boundary";
import { EmptyPropertyBox } from "../components/empty_property.box";
import { ViewTitle } from "../../common/components/views";
import { SideBar } from "../../common/components/sidebar";
import { useSearchParams } from "react-router-dom";
import { StorePanel } from "../components/store_panel";
import { PriceRangePanel } from "../components/price_range.panel";
import { AreaRangePanel } from "../components/area_panel";

export function PropertyListView(props: any) {
  const [searchParams, _] = useSearchParams()
  const initializeState = ({set}: MutableSnapshot) => {
    if(searchParams.get('fav-only')) {
      set(favPropertiesFilterState, true)
    }

    if(searchParams.get('my-properties')) {
      set(myPropertiesFilterState, true)
    }

    if(searchParams.get('pending-properties')) {
      set(pendingPropertiesFilterState, true)
    }

    if(searchParams.get('status')) {
      const status = parseInt(searchParams.get('status') ?? '')
      if(status > 0) {
        set(statusFilterState, status)
      }
    }
  }

  return <RecoilRoot
    initializeState={initializeState}
  >
    <Title/>
    <PropertyFilterPanel/>
    <SearchPanel
      sx={{
        mt: 2,
        mb: 2,
      }}
    />
    <Box
      sx={{
        borderBottom: 0.5,
        borderColor: '#aeaeae',
        mt: 2,
        mb: 4,
      }}
    />
    <ErrorBoundary>
      <React.Suspense>
        <Container maxWidth='xl'>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              height: 1
            }}
          >
            <Box
              maxWidth='md'
              sx={{
                m: 0,
                width: 1,
              }}
            >
              
                <PropertyList/>
            </Box>
            <SideBar
              sx={{
                ml: {md: 2, lg: 6, xl: 8},
                minWidth: 200,
                display: {xs: 'none', lg: 'block'},
              }}
            >
              <StorePanel/>
              <PriceRangePanel
                sx={{
                  mt: 2
                }}
              />
              <AreaRangePanel
                sx={{
                  mt: 2
                }}
              />
            </SideBar>
          </Box>
        </Container>
      </React.Suspense>
    </ErrorBoundary>
  </RecoilRoot>
}

function Title() {
  const title = useRecoilValue(titleState)

  return <ViewTitle
    sx={{
      mt: 4,
      mb: 2,
    }}
  >
    {title}
  </ViewTitle>
}

function PropertyList(props: any) {
  const count = useRecoilValue(propertyCountState)
  const paging = useRecoilValue(paginationState)
  const dataLoadable = useRecoilValueLoadable(propertyListState)
  const refresh = useRecoilRefresher_UNSTABLE(propertyListState)

  useEffect(() => {
    window.scroll(0, 0)
  }, [paging])

  useEffect(() => {
    if(dataLoadable.state != 'loading') {
      refresh()
    }
  }, [])

  if(count === 0) {
    return <EmptyPropertyBox/>
  }

  let items: JSX.Element[] = []
  for(let i = 0; i < count; i++) {
    items.push(<PropertyRow key={i} index={i} />)
  }

  return <>
    <Stack
      direction="column"
      divider={<Divider orientation="horizontal" flexItem />}
      spacing={2}
      // maxWidth={800}
    >
      {items}
    </Stack>
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        mb: 4,
        mt: 4
      }}
    >
      <PropertyListPagination/>
    </Box>
  </>
}