import { Dialog, DialogContent } from '@mui/material'
import { useFormik } from 'formik'
import { AllowedTo } from 'react-abac'
import * as yup from 'yup'
import { Permission } from '../../authz'
import { DialogActionConfirm } from '../../common/components/confirm_action.dialog'
import { DialogTitleClose } from '../../common/components/title_close.dialog'
import { WorkInfoCard } from './work_info.card'

export function UpdateWorkDialog(props: any) {
  const { open, setOpen, member} = props

  const formik = useFormik({
    initialValues: {
      title: member.title,
      dept: member.department,
      joinedAt: member.joinedAt == null ? null : new Date(member.joinedAt)
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // const {url, ...config} = propertyDescriptionEndpoint
      // try {
      //   await tokenAxios.request({
      //     url: urlWithId(url, `${property.id}`),
      //     ...config,
      //     data: {
      //       description: values.description
      //     }
      //   })
      //   setOpen(false)
      //   enqueueSnackbar('Đã cập nhật mô tả BĐS')
      //   setTimeout(() => {
      //     refreshDetail()
      //   }, 300)
      // } catch(e) {
      //   const msg = e instanceof AxiosError ? e.message : kUnknownError
      //   dispatch(setAlert({open: true, content: msg}))
      // }
    }
  })

  const handleClose = () => {
    formik.resetForm()
    setOpen(false)
  }

  return <Dialog
    maxWidth='md'
    fullWidth
    open={open}
  >
    <DialogContent>
      <DialogTitleClose
        title='Sửa thông tin công việc'
        onClose={handleClose}
      />
      <form onSubmit={formik.handleSubmit}>
        <AllowedTo
          perform={Permission.EDIT_MEMBER_DEPT}
          data={member}
          yes={() => <WorkInfoCard formik={formik}/>}
          no={() => <WorkInfoCard formik={formik} disableDept={true}/>}
        />
        <DialogActionConfirm/>
      </form>
      </DialogContent>
  </Dialog>
}

const validationSchema = yup.object({
  joinedAt: yup
    .date()
    .nullable()
    .notRequired(),
  title: yup
    .object()
    .nullable()
    .required('Chức danh là bắt buộc'),
  dept: yup
    .object()
    .nullable()
    .notRequired(),
})