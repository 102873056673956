import { Close } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { HgDialogTitle } from "./dialog.style";

export function DialogTitleClose(props: any) {
  const {title, onClose} = props

  return <Box sx={{flexDirection: 'row', display: 'flex', mb: 2}}>
  <HgDialogTitle>{title}</HgDialogTitle>
  <Box flexGrow={1}/>
  <IconButton onClick={onClose} size='small'>
    <Close/>
  </IconButton>
</Box>
}