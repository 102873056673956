import {
    Box,
    Button,
    CircularProgress,
    Container,
    Slide,
    styled,
    TextField,
    Typography,
} from "@mui/material";
import Footer from "../components/footer";
import {GradientButton} from "../../common/components";
import {basicAxios, tokenAxios} from "../../common/axios";
import {loginEndpoint, requestLoginEndpoint} from "../../common/endpoints";
import HgAppBar from "../components/appbar";
import {useLocation, useNavigate} from "react-router-dom";
import { useRecoilRefresher_UNSTABLE } from "recoil";
import { authState } from "../../common/state/auth.state";
import { useSnackbar } from "notistack";
import { AxiosError } from "axios";
import { kUnknownError } from "../../common/util/constants";
import useUUID from "../../common/hooks/use_uuid";
import { Phonelink } from "@mui/icons-material";
import { UAParser } from 'ua-parser-js'
import { useEffect, useState } from "react";
import { HGAvatar } from "../../common/components/hg_avatar";
import { fullNameFromMember, urlWithId } from "../../common/util/common-utils";
// import Geocode from "react-geocode";

// Geocode.setApiKey('AIzaSyDqiuEj45KBsXASpXyvx90TwCsnjDP_XpQ')
// Geocode.setLanguage("vi")
// Geocode.setRegion("vn")
// Geocode.setLocationType("ROOFTOP")

export default function Login() {
  const [requestLogin, setRequestLogin] = useState<any | null>(null)

  return <Container component="main" maxWidth='xs'>
    <HgAppBar/>
    <Box 
      sx={{
        mt: 15,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: 1,
        width: 1,
      }}
    >
      {requestLogin == null ? <RequestLogin setRequestLogin={setRequestLogin}/>
      : <WaitLogin requestLogin={requestLogin} setRequestLogin={setRequestLogin}/>}
    <Footer/>
    </Box>
  </Container>
}

function RequestLogin(props: any) {
  const {setRequestLogin} = props
  const {enqueueSnackbar} = useSnackbar()
  const uuid = useUUID()

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    try {
      const parser = new UAParser()
      
      const resp = await basicAxios.request({
        ...requestLoginEndpoint,
        data: {
          'username' : formData.get('username'),
          'deviceInfo' : {
            'uuid' : uuid
          },
          'uuid' : uuid,
          'os' : parser.getOS(),
          'browser' : parser.getBrowser()
        }
      })
      
      setRequestLogin({
        ...resp.data,
        'username' : formData.get('username'),
        'uuid' : uuid,
      })
    } catch(e) {
      const msg = e instanceof AxiosError ? e.response?.data?.message : kUnknownError
      enqueueSnackbar(msg, {
        anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
        },
        TransitionComponent: Slide,
        preventDuplicate: true,
      })
    }
  }

  return (
    <Box 
      component="form" 
      onSubmit={handleSubmit} 
      noValidate 
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 1,
      }}
    >
      <Box
        sx={{
          mb: 5
        }}
      >
        <Phonelink
          style={{
            fontSize: 150,
            color: '#ff9300'
          }}
        />
      </Box>
      <TextField
        margin="normal"
        required
        fullWidth
        id="username"
        label="Email hoặc số điện thoại"
        name="username"
        autoComplete="email"
        autoFocus
        size={"small"}
      />
      <GradientButton
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2}}
      >
        Gửi yêu cầu đăng nhập
      </GradientButton>
    </Box>
  )
}

function WaitLogin(props: any) {
  const { setRequestLogin } = props
  const { id, member, uuid, username } = props.requestLogin
  const location = useLocation()
  const navigate = useNavigate()
  const refreshProfile = useRecoilRefresher_UNSTABLE(authState)
  const [error, setError] = useState<any | null>(null)

  useEffect(() => {
    basicAxios.request({
      ...loginEndpoint,
      timeout: 20000,
      timeoutErrorMessage: 'Yêu cầu đã bị hết hạn',
      data: {
        'username' : username,
        'uuid' : uuid,
        'requestId' : id
      }
    }).then((resp) => {
      refreshProfile()
      //@ts-ignore
      const from = location.state?.from?.pathname || '/'
      navigate(from, { replace: true })
    }).catch((e: AxiosError) => {
      setError(e.response?.data ?? e.message)
    })
  }, [])

  return <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: 1,
    }}
  >
    <HGAvatar
      fullName={fullNameFromMember(member)}
      avatarUrl={member.avatarUrl}
      sx={{
        width: 100,
        height: 100
      }}
    />
    <Typography
      sx={{
        fontSize: 20,
        fontWeight: 700,
        mt: 2,
        mb: 2
      }}
    >
      {fullNameFromMember(member)}
    </Typography>
    {error == null ? (
    <>
      <CircularProgress
      
      />
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: 500,
          mt: 2,
          mb: 1
        }}
      >
        Đang đợi yêu cầu được chấp nhận...
      </Typography>
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: 500,
          mb: 4,
          textAlign: 'center',
        }}
      >
        Mở ứng dụng trên điện thoại của bạn. 
        Vào mục <BoldText>Thiết lập</BoldText> → <BoldText>Yêu cầu đăng nhập</BoldText>. 
        Sau đó kiểm tra kỹ yêu cầu rồi chọn chấp nhận hoặc từ chối.
      </Typography>
    </>
    ) : (
      <>
        <Typography
        sx={{
          fontSize: 14,
          fontWeight: 500,
          color: '#e82406',
          mt: 2,
          mb: 2
        }}
      >
        {error}
      </Typography>
      <Button
        onClick={() => setRequestLogin(null)}
        sx={{
          mb: 4
        }}
      >
        QUAY LẠI
      </Button>
      </>
    )}
  </Box>
}

const BoldText = styled(Typography)({
  fontWeight: 700,
  fontSize: 14,
  display: 'inline-block'
})