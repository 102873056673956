import { Button, ClickAwayListener, Popover, Stack, Tooltip, Typography } from "@mui/material"
import { Box } from "@mui/system"
import React, { useEffect } from "react"
import { HGAvatar } from "../../common/components/hg_avatar"
import { TriggerTooltip } from "../../common/components/trigger_tooltip"
import { fullNameFromMember, openInNewTabWinBrowser, propertyDetailLink, titleNameFromMember } from "../../common/util/common-utils"
import { titleFromProperty } from "../utils"
import { Content, Label } from "./property_detail_styles"

export function ContactSidebar(props: any) {
  const {property} = props
  const member = property.createdMember

  const handleEmailClicked = () => {
    const subject = `Thông tin về ${titleFromProperty(property)}`
    const body = `${propertyDetailLink(property.id)}`
    window.location.href = `mailto:${member.email}?subject=${subject}&body=${body}`
  }

  return <Box
    sx={{
      p: 2,
      border: 0.5,
      borderColor: '#999',
      borderRadius: 2,
    }}
  >
    <Stack
      spacing={2}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <HGAvatar
          fullName={fullNameFromMember(member)}
          avatarUrl={member.avatarUrl ?? undefined}
          sx={{ width: 80, height: 80, mb: 2 }}
        />
        <Label>Đăng bởi</Label>
        <Content>{fullNameFromMember(member)}</Content>
      </Box>
      <PhoneButton phone={member.phone}/>
      <Button variant="outlined" onClick={handleEmailClicked}>
        Gửi email
      </Button>
      {member.facebook && <Button 
        variant="outlined" 
        onClick={() => openInNewTabWinBrowser(member.facebook)}
      >
        Facebook
      </Button>}
      {member.zalo && <Button 
        variant="outlined"
        onClick={() => openInNewTabWinBrowser(member.zalo)}
      >
        Zalo
      </Button>}
    </Stack>
  </Box>
}

function PhoneButton(props: any) {
  const {phone} = props
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    navigator.clipboard.writeText(phone)
      .then(() => {
        setOpen(true);
      })
      .catch((_) => {})
  };

  return <TriggerTooltip 
    title='Đã sao chép' 
    arrow={true}
    placement="top"
    open={open}
    setOpen={setOpen}
  >
    <Button variant="contained" onClick={handleClick}>
      {phone}
    </Button>
  </TriggerTooltip>
}