import { Box, Button, Container, Grid, styled } from "@mui/material";
import { CardTitle, HgCard, HgCardContent } from "../../common/components/cards";
import DrawerHeader from "../../common/components/drawer.header";
import { LabelTextField } from "../../common/components/text_field_label";
import * as yup from 'yup'
import { useFormik } from "formik";
import { useRecoilRefresher_UNSTABLE, useRecoilValue, useRecoilValueLoadable } from "recoil";
import { citiesState, districtsState, wardsesState } from "../../common/state/location.state";
import { LocationType, MediaAsset } from "../../common/types";
import { authState } from "../../common/state/auth.state";
import { fileReader, fullNameFromMember, imageUrlFromUrl } from "../../common/util/common-utils";
import { DropDownLabelButton } from "../../common/components/dropdown_label.button";
import { genders } from "../../member/constants";
import { DateLabelButton } from "../../member/components/date_label.button";
import React, { useState } from "react";
import { HGAvatar } from "../../common/components/hg_avatar";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../redux/hooks";
import { AxiosError } from "axios";
import { kUnknownError } from "../../common/util/constants";
import { setAlert } from "../../redux/alert.slice";
import { simpleAxios, tokenAxios } from "../../common/axios";
import { editProfileEndpoint, newPresignedUrlEndpoint } from "../../common/endpoints";
import Dropzone from "react-dropzone";

export function EditProfileView() {
  const auth = useRecoilValue(authState)

  return <React.Suspense>
    <EditProfile member={auth.member}/>
  </React.Suspense>
}

interface EditProfileType {
  member: any
}

function EditProfile({member} : EditProfileType) {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const refreshProfile = useRecoilRefresher_UNSTABLE(authState)

  const formik = useFormik({
    initialValues: {
      fullName: fullNameFromMember(member),
      address: member.address ?? '',
      wards: {
        id: member.wardsId,
        name: member.wards
      },
      district: {
        id: member.districtId,
        name: member.district
      },
      city: {
        id: member.cityId,
        name: member.city
      },
      gender: genders.find(e => e.name.toLowerCase() == member.gender.toLowerCase()) ?? genders[2],
      birthday: new Date(member.birthday),
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        let assets: MediaAsset[] = []
        if(avatar instanceof File) {
          assets.push({
            name: `${member.id}-${(new Date()).getTime()}`,
            file: avatar,
            category: 'member',
            contentType: 'image/jpeg'
          } as MediaAsset)
        }

        let uploadedImageUrls: string | null = null
        if(assets.length > 0) {
          const resp = await tokenAxios.request({
            ...newPresignedUrlEndpoint,
            data: assets.map(e => ({
              fileName: e.name,
              contentType: e.contentType,
              category: e.category
            }))
          })
          const presignedUrls = resp.data

          for(const asset of assets) {
            const rawUrl = presignedUrls[asset.name]
            const url = new URL(rawUrl)   
            try {
              const binary = await fileReader(asset.file)
              await simpleAxios.put(rawUrl, binary, {
                headers: {
                  AWSAccessKeyId : url.searchParams.get('AWSAccessKeyId') ?? '',
                  Type: url.searchParams.get('Type') ?? '',
                  Expires: url.searchParams.get('Expires') ?? 0,
                  Signature: url.searchParams.get('Signature') ?? '',
                  'x-amz-acl': url.searchParams.get('x-amz-acl') ?? '',
                  'Content-Type': asset.contentType
                },
              })
            } catch(_) {}
            
            let imageUrl = imageUrlFromUrl(url)
            uploadedImageUrls = imageUrl
          }
        }

        await tokenAxios.request({
          ...editProfileEndpoint,
          data: {
            fullName: values.fullName,
            address: values.address,
            wardsId: values.wards!.id,
            wards: values.wards!.name,
            districtId: values.district!.id,
            district: values.district!.name,
            cityId: values.city!.id,
            city: values.city!.name,
            birthday: values.birthday?.toUTCString(),
            avatarUrl: uploadedImageUrls == null ? undefined : uploadedImageUrls
          }
        })

        refreshProfile()
        dispatch(setAlert({open: true, content: 'Thay đổi thông tin cá nhân thành công!'}))
        navigate('/setting')
      } catch(e) {
        const msg = e instanceof AxiosError ? e.message : kUnknownError
        dispatch(setAlert({open: true, content: msg}))
      }
    }
  })

  const cityList = useRecoilValueLoadable(citiesState)
  const cities: LocationType[] = cityList.state === 'hasValue' ? cityList.contents : []
  const districtsAV = useRecoilValueLoadable(districtsState(formik.values.city?.id ?? -1))
  const districts: LocationType[] = districtsAV.state === 'hasValue' ? districtsAV.contents : []
  const wardsesAV = useRecoilValueLoadable(wardsesState(formik.values.district?.id ?? -1))
  const wardses: LocationType[] = wardsesAV.state == 'hasValue' ? wardsesAV.contents : []

  const [avatar, setAvatar] = useState(member.avatarUrl)

  const handleCityChanged = (e: any) => {
    formik.setFieldValue('district', null)
    formik.setFieldValue('wards', null)
    formik.setFieldValue('city', e)
  }

  const handleDistrictChanged = (e: any) => {
    formik.setFieldValue('wards', null)
    formik.setFieldValue('district', e)
  }

  const handleWardsChanged = (e: any) => {
    formik.setFieldValue('wards', e)
  }

  const handleGenderChange = (e: any) => formik.setFieldValue('gender', e)

  const handleDrop = (files: File[]) => {
    setAvatar(files[0])
  }

  return <>
    <form onSubmit={formik.handleSubmit}>
      <DrawerHeader/>
      <Container maxWidth='md'>
        <HgCard>
          <HgCardContent>
          <CardTitle>
            Thay đổi thông tin cá nhân
          </CardTitle>
          <Grid
            container
            rowSpacing={2}
            columnSpacing={4}
            columns={2}
            sx={{
              mt: 2,
            }}
          >
            <Grid item xs={2}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  mt: 2,
                  mb: 2,
                }}
              >
                  <Dropzone 
                    onDrop={handleDrop}
                    multiple={false}
                  >
                    {({getRootProps, getInputProps}) => (
                      <Button
                        {...getRootProps()}
                      >
                      <input {...getInputProps()} />
                      <HGAvatar
                        fullName={fullNameFromMember(member)}
                        avatarUrl={avatar instanceof File ? URL.createObjectURL(avatar) : avatar}
                        sx={{
                          width: 120,
                          height: 120
                        }}
                      />
                    </Button>
                    )}
                  </Dropzone>
                
              </Box>
            </Grid>
            <Grid item xs={2}>
              <LabelTextField
                id='fullName'
                text={formik.values.fullName}
                handleChange={formik.handleChange}
                error={formik.touched.fullName && Boolean(formik.errors.fullName)}
                helperText={formik.touched.fullName && formik.errors.fullName}
                label='Họ và tên'
                required={true}
                placeholder='Nhập đầy đủ họ và tên...'
              />
            </Grid>
            <Grid item xs={2}>
              <LabelTextField
                id='address'
                text={formik.values.address}
                handleChange={formik.handleChange}
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={formik.touched.address && formik.errors.address}
                label='Địa chỉ'
                placeholder='Nhập địa chỉ chi tiết, ví dụ ngõ ngách hẻm...'
              />
            </Grid>
            <Grid item sm = {1}>
              <DropDownLabelButton
                id='city'
                item={formik.values.city}
                handleChange={handleCityChanged}
                items={cities}
                label='Tỉnh/Thành phố'
                required={true}
                placeholder='Chọn tỉnh/thành phố'
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
              />
            </Grid>
            <Grid item sm = {1}>
              <DropDownLabelButton
                id='district'
                item={formik.values.district}
                handleChange={handleDistrictChanged}
                items={districts}
                label='Quận/Huyện'
                required={true}
                placeholder='Chọn quận/huyện'
                error={formik.touched.district && Boolean(formik.errors.district)}
                helperText={formik.touched.district && formik.errors.district}
              />
            </Grid>
            <Grid item sm = {1}>
              <DropDownLabelButton
                id='wards'
                item={formik.values.wards}
                handleChange={handleWardsChanged}
                items={wardses}
                label='Phường/Xã'
                required={true}
                placeholder='Chọn phường/xã'
                error={formik.touched.wards && Boolean(formik.errors.wards)}
                helperText={formik.touched.wards && formik.errors.wards}
              />
            </Grid>
            <Grid item sm = {1}>
            </Grid>
            <Grid item sm = {1}>
              <DropDownLabelButton
                id='gender'
                item={formik.values.gender}
                handleChange={handleGenderChange}
                items={genders}
                label='Giới tính'
                required={true}
                placeholder='Nhập giới tính'
              />
            </Grid>
            <Grid item sm={1}>
              <DateLabelButton
                id='birthday'
                value={formik.values.birthday}
                handleChange={(value: any) => formik.setFieldValue('birthday', value)}
                label='Ngày sinh'
              />
            </Grid>
          </Grid>
          <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                mt: 4,
                mb: 3
              }}
            >
              <Button
                color="primary" 
                variant="contained" 
                type="submit"
                sx={{
                  width: 200
                }}
              >
                Xác nhận
              </Button>
            </Box>
          </HgCardContent>
        </HgCard>
      </Container>
    </form>
  </>
}

const Input = styled('input')({
  display: 'none',
});

const validationSchema = yup.object({
  fullName: yup
  .string()
  .min(3, 'Họ và tên phải ít nhất từ 3 ký tự trở lên')
  .required('Họ và tên là bắt buộc'),
  address: yup
    .string()
    .notRequired(),  
  wards: yup
    .object()
    .nullable()
    .required('Thông tin Xã/Phường là bắt buộc'),
  district: yup
    .object()
    .nullable()
    .required('Thông tin Quận/Huyện là bắt buộc'),
  city: yup
    .object()
    .nullable()
    .required('Thông tin Tỉnh/Thành phố là bắt buộc'),
  gender: yup
    .object()
    .required('Giới tính là bắt buộc'),
  birthday: yup
    .date()
    .nullable()
    .notRequired()
})