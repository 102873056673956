import { selector } from "recoil";
import { tokenAxios } from "../axios";
import { profileEndpoint } from "../endpoints";

export const authState = selector({
  key: 'auth',
  get: async () => {
    try {
      const resp = await tokenAxios.request(profileEndpoint)
      
      return resp.data
    } catch(e) {
      return null
    }
  }
})

export const myDeptIdState = selector({
  key: 'user/dept_id',
  get: ({get}) => get(authState).member.department.id
})