import {green, grey, red, yellow} from "@mui/material/colors";
import {maxPrice} from "./constants";
import {formatArea, formatCurrencyByBillion, formatMeter, formatSTextCurrency, formatTextCurrency} from "../common/util/format-utils";

export function titleFromProperty(data: any) {
  return [
    `Căn hộ ở ${data.address}`,
    data.wards,
    data.district,
    data.city,
  ].join(', ')
}

export function colorFromStatus(status: number) {
  if(status === 3) {
    return red[800]
  } else if(status === 2) {
    return green[800]
  } else if(status == 1) {
    return yellow[900]
  }

  return grey[500]
}

export function isDefaultRangeValue(aRange: any) {
  return aRange.from === 0 && aRange.to === 0
}

export function isDefaultValueType(valueType: any) {
  return valueType.value === 0
}

export function sliderValuesFromRange(aRange: any) {
  return [aRange.from, aRange.to]
}

export function rangeFromSliderValues(values: number[]) {
  return {
    from: values[0],
    to: values[1]
  }
}

export function titleFromRangePrice(aRange: any) {
  return _titleFromRange(aRange, formatCurrencyByBillion)
}

export function titleFromRangeArea(aRange: any) {
  return _titleFromRange(aRange, formatArea)
}

export function titleFromRangeFront(aRange: any) {
  return _titleFromRange(aRange, formatMeter)
}

function _titleFromRange(aRange: any, format: any) {
  const {from, to} = aRange

  if(from === to && to === 0) {
    return 'Tất cả'
  }

  if(from === 0) {
    return `< ${format(to)}`
  }

  if(from === to) {
    return `> ${format(to)}`
  }

  return `${format(from)} - ${format(to)}`
}

export function queryFromRangeValue(aRange: any) {
  const {from, to} = aRange
  if(from === to && to === 0) {
    return null
  }

  if(from === 0) {
    return {
      to
    }
  }

  if(from === to) {
    return {
      from: to
    }
  }

  return {
    from, to
  }
}

export function queryFromValue(aValue: any) {
  if(aValue.value == 0) {
    return null
  }

  return {
    from: aValue.value
  }
}