import { ChevronRight } from "@mui/icons-material";
import { Box, Button, Container, Divider, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";
import { useRecoilValue } from "recoil";
import { ButtonItem } from "../../common/components/button_indicator";
import { CardTitle, HgCard, HgCardContent } from "../../common/components/cards";
import DrawerHeader from "../../common/components/drawer.header";
import { HGAvatar } from "../../common/components/hg_avatar";
import { Suspense } from "../../common/components/suspense";
import { authState } from "../../common/state/auth.state";
import { fullNameFromMember, hometownFromMember, titleNameFromMember } from "../../common/util/common-utils";
import { kEmptyData } from "../../common/util/constants";
import { formatDate } from "../../common/util/format-utils";

export function UserSettingView() {
  const auth = useRecoilValue(authState)

  return <Suspense>
    <UserSetting member={auth.member}/>
  </Suspense>
}

function UserSetting(props: any) {
  const {member} = props
  const items = itemsFromMember(member)

  return <>
    <DrawerHeader/>
    <Container maxWidth='md'>
      <Box
        sx={{
          width: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <HGAvatar
            fullName={fullNameFromMember(member)}
            avatarUrl={member.avatarUrl}
            sx={{
              width: 120,
              height: 120
            }}
          />
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: 500,
              mt: 2,
              mb: 0.5
            }}
          >
            {titleNameFromMember(member)}
          </Typography>
          <Typography
            sx={{
              fontSize: 20,
              fontWeight: 800,
              mb: 6
            }}
          >
            {fullNameFromMember(member)}
          </Typography>
      </Box>
      <HgCard
        sx={{
          mb: 2,
        }}
      >
        <HgCardContent>
          <Stack
            spacing={2}
            divider={<Divider orientation="horizontal" flexItem />}
          >
            <Button
              size='small'
              href="/edit-profile"
            >
              <Typography
                sx={{
                  textTransform: 'none'
                }}
              >
                Sửa thông tin cá nhân
              </Typography>
              <Box flexGrow={1}/>
              <ChevronRight/>
            </Button>
            <Button
              size='small'
              href="/change-password"
            >
              <Typography
                sx={{
                  textTransform: 'none'
                }}
              >
                Thay đổi mật khẩu
              </Typography>
              <Box flexGrow={1}/>
              <ChevronRight/>
            </Button>
          </Stack>
        </HgCardContent>
      </HgCard>
      <HgCard>
        <HgCardContent>
          <CardTitle>
            Thông tin cá nhân
          </CardTitle>
          <Stack
            spacing={2}
            divider={<Divider orientation="horizontal" flexItem />}
            sx={{
              mt: 4
            }}
          >
            {items.map((e: any, index: number) => <LineInfo
              key={index}
              label={e.label}
              value={e.value}
            />)}
          </Stack>
        </HgCardContent>
      </HgCard>
    </Container>
  </>
}

function LineInfo(props: any) {
  const {label, value, sx} = props

  return <Box
    sx={{
      display: 'flex',
      flexDirection: 'row',
    }}
  >
    <Typography
      sx={{
        fontSize: 16,
        fontWeight: 500,
        minWidth: 200,
      }}
    >
      {label}
    </Typography>
    <Box flexGrow={1}/>
    {value == null 
      ? <Typography
          sx={{
            fontSize: 14,
            fontWeight: 500,
            color: grey
          }}
        >
          {kEmptyData}
        </Typography>
      : <Typography
          sx={{
            fontSize: 16,
            fontWeight: 700,
            textAlign: 'right'
          }}
        >
          {value}
        </Typography>
    }
  </Box>
}

interface Item {
  id: 'phone' | 'email' | 'facebook' | 'zalo' | 'joinedDate' | 'hometown' | 'birthday' | 'gender',
  label: string,
  value?: string
}

function itemsFromMember(data: any) {
  return [
    {
      id: 'phone',
      label: 'Số điện thoại',
      value: data.phone
    },
    {
      id: 'email',
      label: 'Email',
      value: data.email,
    },
    {
      id: 'facebook',
      label: 'Facebook',
      value: data.facebook,
    },
    {
      id: 'zalo',
      label: 'Zalo',
      value: data.zalo,
    },
    {
      id: 'joinedDate',
      label: 'Ngày tham gia',
      value: data.joinedDate && formatDate(new Date(data.joinedDate)),
    },
    {
      id: 'hometown',
      label: 'Quê quán',
      value: hometownFromMember(data),
    },
    {
      id: 'birthday',
      label: 'Ngày sinh',
      value: data.birthday && formatDate(new Date(data.birthday)),
    },
    {
      id: 'gender',
      label: 'Giới tính',
      value: data.gender,
    }
  ]
}