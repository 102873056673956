import { Chip, List, ListItemButton, ListItemText, Popover } from "@mui/material";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValueLoadable } from "recoil";
import { citiesState, districtsState } from "../../common/state/location.state";
import { LocationType, WorkingLocation } from "../../common/types";
import { wlocsState } from "../states/new_member.state";

export function NewWlocButton() {
  const [wlocs, setWlocs] = useRecoilState(wlocsState)
  const [city, setCity] = useState<LocationType | null>(null)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const cityList = useRecoilValueLoadable(citiesState)
  const cities: LocationType[] = cityList.state === 'hasValue' ? cityList.contents : []
  const districtsAV = useRecoilValueLoadable(districtsState(city?.id ?? -1))
  const districts: LocationType[] = districtsAV.state === 'hasValue' ? districtsAV.contents : []
  const types = city == null ? cities : [
    {
      id: -1,
      name: `Toàn bộ ${city.name}`
    } as LocationType,
    ...districts
  ]

  useEffect(() => {
    setCity(null)
  }, [anchorEl])

  const handleNewClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  const handleItemSelected = (item: LocationType) => () => {
    if(city == null) {
      setCity(item)
    } else {
      setWlocs([
        ...wlocs,
        {
          city: city,
          district: item.id == -1 ? null : item
        } as WorkingLocation
      ])
      setAnchorEl(null);
    }
    
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return <>
    <Chip
      key={-1}
      label='Thêm kho hàng'
      onClick={handleNewClick}
    />
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      sx={{
        maxHeight: 300,
      }}
    >
      <List
        sx={{
          maxHeight: '100%',
          maxWidth: '100%',
          overflow: 'auto'
        }}
      >
        {types.map((e: any, i: number) => <ListItemButton
          key={i}
          onClick={handleItemSelected(e)}
        >
          <ListItemText>
            {e.name}
          </ListItemText>
        </ListItemButton>)}
      </List>
    </Popover>
  </>
}