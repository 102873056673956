import { Box, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { RecoilRoot, useRecoilRefresher_UNSTABLE, useRecoilState, useRecoilValue, useRecoilValueLoadable } from "recoil";
import { HgPaper } from "../../common/components/cards";
import DrawerHeader from "../../common/components/drawer.header";
import { ErrorBoundary } from "../../common/components/error_boundary";
import { HGAvatar } from "../../common/components/hg_avatar";
import { Suspense } from "../../common/components/suspense";
import { ViewTitle } from "../../common/components/views";
import { kEmptyData } from "../../common/util/constants";
import { DeptSearchPanel } from "../components/search_panel";
import { currentPageState, deptListState, deptRowsState, paginationState } from "../states/dept_list.state";

export function DeptListView() {

  return <RecoilRoot>
    <DrawerHeader />
    <ViewTitle>
      Danh sách khối/phòng
    </ViewTitle>
    <DeptSearchPanel
      sx={{
        mt: 2,
        mb: 2,
      }}
    />
    <ErrorBoundary>
        <Suspense>
          <DeptTable/>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              mb: 2,
              mt: 4
            }}
          >
            <DeptListPagination/>
          </Box>
        </Suspense>
      </ErrorBoundary>
  </RecoilRoot>
}

function DeptTable() {
  const depts = useRecoilValue(deptRowsState)
  const dataLoadable = useRecoilValueLoadable(deptListState)
  const refresh = useRecoilRefresher_UNSTABLE(deptListState)
  const tableRef = React.createRef<HTMLTableElement>()
  const navigate = useNavigate()

  const handleRowClick = (member: any) => () => {
    navigate(`/employee/dept/${member.id}`)
  }

  useEffect(() => {
    if(dataLoadable.state != 'loading') {
      refresh()
    }
  }, [])

  useEffect(() => {
    tableRef?.current?.scrollIntoView()
  }, [depts])

  return <HgPaper>
    <TableContainer
      style={{
        height: "calc(100vh - 340px)"
      }}
    >
    <Table
      stickyHeader 
      aria-label="sticky table"
      ref={tableRef}
    >
      <TableHead>
        <TableRow>
          {columns.map((column) => (
            <TableCell
              key={column.id}
              align={column.align}
              style={{ 
                minWidth: column.minWidth,
                fontSize: 14,
                fontWeight: 700,
              }}
            >
              {column.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody
      >
        {depts.map((row: any, index: number) => (
          <TableRow key={index} hover onClick={handleRowClick(row)}>
            {columns.map((col) => {
              const value = row[col.id]
              return (
                <TableCell 
                  key={col.id} 
                  align={col.align}
                  sx={{
                    fontSize: col.fontSize,
                    fontWeight: col.fontWeight
                  }}
                >
                    {col.id === 'manager' && value != null ? <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                      }}
                    >
                      <HGAvatar
                        fullName={value['name']}
                        avatarUrl={value['avatarUrl']}
                        sx={{
                          mr: 1
                        }}
                      />
                      <Typography>
                        {value.name}
                      </Typography>
                    </Box> : (value ?? kEmptyData)}
                </TableCell>
              )
            })}
          </TableRow>
        ))}
      </TableBody>
    </Table>
    </TableContainer>
  </HgPaper>
}

function DeptListPagination(props: any) {
  const pagin = useRecoilValue(paginationState)
  const [_, setCurrentPage] = useRecoilState(currentPageState)

  const handleOnPageChanged = (event: any, page: number) => {
    setCurrentPage(page)
  }

  return pagin.total === 0 ? null : <Pagination
    count={pagin.total}
    variant="outlined"
    shape="rounded"
    onChange={handleOnPageChanged}
  />
}

interface Column {
  id: 'order' | 'name' | 'totalMember' | 'manager'
  label: string
  minWidth?: number
  align?: 'right' | 'center' | 'left'
  fontSize: number
  fontWeight: number
}

const columns: readonly Column[] = [
  {
    id: 'order',
    label: 'STT',
    minWidth: 30,
    align: 'center',
    fontSize: 16,
    fontWeight: 500
  },
  {
    id: 'name',
    label: 'Tên khối/phòng',
    minWidth: 200,
    align: 'left',
    fontSize: 16,
    fontWeight: 600
  },
  {
    id: 'totalMember',
    label: 'Số thành viên',
    minWidth: 80,
    align: 'center',
    fontSize: 16,
    fontWeight: 500
  },
  {
    id: 'manager',
    label: 'Người phụ trách',
    minWidth: 140,
    align: 'left',
    fontSize: 16,
    fontWeight: 600
  },
]