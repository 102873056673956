export function formatNumber(num: number) {
  if(num == null) {
    return 'N/A'
  }

  return num.toLocaleString('vi-vn')
}

export function formatCurrency(num: number) {
  if(num == null) {
    return 'N/A'
  }

  return [
    num.toLocaleString('vi-vn'),
    'VNĐ'
  ].join(' ')
}

export function formatArea(num: number) {
  if(num == null) {
    return 'N/A'
  }

  return [
    num.toLocaleString('vi-vn'),
    'm²'
  ].join(' ')
}

export function formatMeter(num: number) {
  if(num == null) {
    return 'N/A'
  }

  return [
    num.toLocaleString('vi-vn'),
    'm'
  ].join(' ')
}

// const kb = 1000000000000000
const kb = 1000000000000
const b = 1000000000
const m = 1000000
const k = 1000

export function formatCurrencyByBillion(num: number) {
  return `${num/b} tỷ`
}

export function formatSTextCurrency(num: number) {
  return formatTextCurrency(num, '')
}

export function formatTextCurrency(num: number, currency: string = 'đồng'): string {
  let quotient = Math.floor(num/kb)
  let remainder = num%kb
  if(quotient > 0) {
    return `${quotient} nghìn tỷ ${formatTextCurrency(remainder, currency)}`
  }

  quotient = Math.floor(num/b)
  remainder = num%b
  if(quotient > 0) {
    return `${quotient} tỷ ${formatTextCurrency(remainder, currency)}`
  }

  quotient = Math.floor(num/m)
  remainder = num%m
  if(quotient > 0) {
    return `${quotient} triệu ${formatTextCurrency(remainder, currency)}`
  }

  quotient = Math.floor(num/k)
  remainder = num%k
  if(quotient > 0) {
    return `${quotient} nghìn ${formatTextCurrency(remainder, currency)}`
  }

  return num === 0 ? currency : `${num} ${currency}`
}

export function formatDate(date: Date) {
  return date.toLocaleDateString('vi-VN', {year: 'numeric', month: '2-digit', day: '2-digit'})
}

const monthInSeconds = 2592000
const weekInSeconds = 604800
const dayInSeconds = 86400
const hourInSeconds = 3600
const minInSeconds = 60

export function formatRecently(date: Date) {
  const seconds = ((new Date()).getTime() - date.getTime())/1000
  let quotient = Math.floor(seconds/monthInSeconds)
  if(quotient > 0) {
    if(quotient > 12) {
      quotient = Math.floor(quotient/12)
      return `${quotient} năm trước`
    }

    return `${quotient} tháng trước`
  }

  quotient = Math.floor(seconds/weekInSeconds)
  if(quotient > 0) {
    return `${quotient} tuần trước`
  }

  quotient = Math.floor(seconds/dayInSeconds)
  if(quotient > 0) {
    return `${quotient} ngày trước`
  }

  quotient = Math.floor(seconds/hourInSeconds)
  if(quotient > 0) {
    return `${quotient} giờ trước`
  }

  quotient = Math.floor(seconds/minInSeconds)
  if(quotient > 0) {
    return `${quotient} phút trước`
  }

  return 'Vừa mới cập nhật'
}

export function formatRemainingTime(seconds: number) {
  let quotient = Math.ceil(seconds/dayInSeconds)
  if(quotient > 0) {
    return `Còn ${quotient} ngày`
  }

  quotient = Math.ceil(seconds/hourInSeconds)
  if(quotient > 0) {
    return `Còn ${quotient} giờ`
  }

  quotient = Math.ceil(seconds/minInSeconds)
  return `Còn ${quotient} phút`
}