import {useRecoilValue} from "recoil";
import {propertyItemState} from "../state/property_list.state";
import {
  Box, CardActionArea,
  Stack,
  Typography
} from "@mui/material";
import {colorFromStatus, titleFromProperty} from "../utils";
import {
  AreaIcon,
  BathroomIcon,
  BedroomIcon,
  FlagIcon,
  FloorIcon,
  HouseFrontIcon, PersonIcon,
  RoadIcon,
} from "../../icons";
import {formatArea, formatDate, formatMeter, formatNumber, formatTextCurrency} from "../../common/util/format-utils";
import {AccessTime, Loyalty} from "@mui/icons-material";
import {fullNameFromMember} from "../../common/util/common-utils";
import { kProperyDefaultImageUrl } from "../../common/util/constants";
import { orange } from "@mui/material/colors";

export function PropertyRow(props: any) {
  const {index} = props
  const data = useRecoilValue(propertyItemState(index))

  return <CardActionArea
    href={`property/${data.id}`}
  >
    <Box
      sx={{
        flexDirection: 'row',
        display: 'flex',
      }}
    >
      <Box
        sx={{
          width: 340,
          height: 200
        }}
      >
        <ImageList imageUrls={data.imageUrls}/>
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: '10%',
          left: 0,
          bgcolor: colorFromStatus(data.status),
          boxShadow: '2px 0px 6px -1px rgba(0,0,0,0.82)',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          pl: 1, pr: 1,
          pt: 0.5, pb: 0.5
        }}
      >
        <Typography
          sx={{
            color: '#fff',
            fontSize: 12,
            fontWeight: 400
          }}
        >
          {data.statusDescription}
        </Typography>
      </Box>
      <Box
        sx={{
          flexDirection: 'column',
          alignItems: 'flex-start',
          display: 'flex',
          ml: 2,
          width: 1
        }}
      >
        <Box
          sx={{
            flexDirection: 'row',
            alignItems: 'flex-start',
            display: 'flex',
          }}
        >
          <FlagIcon
            height={20}
          />
          <Typography
            sx={{
              ml: 1,
              color: '#000',
              fontSize: 16,
              fontWeight: 700
            }}
          >
            {titleFromProperty(data).toUpperCase()}
          </Typography>
        </Box>
        <Box
          sx={{
            flexDirection: 'row',
            alignItems: 'flex-start',
            display: 'flex',
            mt: 1,
            p: 0,
            width: 1,
          }}
        >
          <InfoItem
            icon={<AreaIcon width={20} height={20}/>}
            label='Diện tích:'
            value={formatArea(data.acreage)}
            sx={{
              width: 0.3
            }}
          />
          <InfoItem
            icon={<HouseFrontIcon width={20}/>}
            label='Mặt tiền:'
            value={formatMeter(data.surfaceWide)}
            ml={{xs: 1, sm: 2, md: 5}}
            sx={{
              width: 0.3
            }}
          />
          <InfoItem
            icon={<RoadIcon width={20}/>}
            label='Đường vào:'
            value={formatMeter(data.roadWide)}
            ml={{xs: 1, sm: 2, md: 5}}
            sx={{
              width: 0.3
            }}
          />
        </Box>
        <Box
          sx={{
            flexDirection: 'row',
            alignItems: 'flex-start',
            display: 'flex',
            mt: 1,
            width: 1,
          }}
        >
          <InfoItem
            icon={<FloorIcon width={20}/>}
            label='Số tầng:'
            value={formatNumber(data.numFloor)}
            sx={{
              width: 0.3
            }}
          />
          <InfoItem
            icon={<BedroomIcon width={20}/>}
            label='Số phòng ngủ:'
            value={formatNumber(data.numBed)}
            ml={{xs: 1, sm: 2, md: 5}}
            sx={{
              width: 0.3
            }}
          />
          <InfoItem
            icon={<BathroomIcon width={20}/>}
            label='Số phòng toilet:'
            value={formatNumber(data.numToilet)}
            ml={{xs: 1, sm: 2, md: 5}}
            sx={{
              width: 0.3
            }}
          />
        </Box>
        <Box
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            display: 'flex',
            mt: 2,
          }}
        >
          <Loyalty sx={{ fontSize: 20, color: '#ff9300', }}/>
          <Typography
            sx={{
              fontSize: 17,
              fontWeight: 600,
              color: '#ff9300',
              ml: 1,
            }}
          >
            {formatTextCurrency(data.price)}
          </Typography>
        </Box>
        <Box sx={{flexGrow: 1}}></Box>
        <Box
          sx={{
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'center',
            mt: 1,
            width: 1
          }}
        >
          <PersonIcon height={20}/>
          <Typography
            sx={{
              ml: 1,
              fontSize: 14,
              fontWeight: 500,
            }}
          >
            Đăng bởi
          </Typography>
          <Typography
            sx={{
              ml: 1,
              fontSize: 16,
              fontWeight: 700,
            }}
          >
            {fullNameFromMember(data.createdMember)}
          </Typography>
          <Box sx={{flexGrow: 1}}/>
          <AccessTime sx={{ fontSize: 20 }}/>
          <Typography
            sx={{
              ml: 1,
              fontSize: 16,
              fontWeight: 700,
            }}
          >
            {formatDate(new Date(data.createdAt))}
          </Typography>
        </Box>
      </Box>
    </Box>
  </CardActionArea>
}

function ImageList(props: any) {
  const {imageUrls} = props

  if(imageUrls == null) {
    return <Box
      component='img'
      src={kProperyDefaultImageUrl}
      sx={{
        width: 1,
        height: 1,
        objectFit: 'cover'
      }}
      loading="lazy"
    />
  }

  if(imageUrls.length === 1) {
    return <Box
      component='img'
      src={`${imageUrls[0]}?fit=crop`}
      sx={{
        width: 1,
        height: 1,
        objectFit: 'cover'
      }}
      loading="lazy"
    />
  }

  return <Box
    sx={{
      width: 1,
      height: 1,
      display: 'flex',
      flexDirection: 'column'
    }}
  >
    <Box
      component='img'
      src={`${imageUrls[0]}?fit=crop`}
      sx={{
        width: 1,
        height: 0.6,
        objectFit: 'cover'
      }}
      loading="lazy"
    />
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        height: 0.4,
        mt: 0.5
      }}
    >
      <Box
        component='img'
        src={`${imageUrls[1]}?fit=crop`}
        sx={{
          width: 0.5,
          objectFit: 'cover'
        }}
        loading="lazy"
      />
      <Box
        sx={{
          width: 0.49,
          ml: 0.5,
          position: 'relative'
        }}
      >
        {imageUrls.length > 2 && <Box
          component='img'
          src={`${imageUrls[2]}?fit=crop`}
          sx={{
            width: 1,
            height: 1,
            objectFit: 'cover'
          }}
          loading="lazy"
        />}
        <Box
          sx={{
            width: 1,
            height: 1,
            position: "absolute",
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            bgcolor: 'rgba(0, 0, 0, 0.54)',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex'
          }}
        >
          <Typography
            sx={{
              color: '#fff'
            }}
          >
            {`${imageUrls.length} ảnh`}
          </Typography>
        </Box>
      </Box>
    </Box>
  </Box>
}

function InfoItem(props: any) {
  const {icon, label, value, sx} = props

  return <Box

    sx={[
      {
        flexDirection: 'row',
        alignItems: 'center',
        display: 'flex',
      },
      ...(Array.isArray(sx) ? sx : [sx])
    ]}
  >
    {icon}
    <Typography
      noWrap
      sx={{
        ml: 1,
        fontSize: 14,
        display: {sm: 'none', md: 'inline'}
      }}
    >
      {label}
    </Typography>
    <Typography
      noWrap
      sx={{
        ml: 1,
        fontSize: 16,
        fontWeight: 700
      }}
    >
      {value}
    </Typography>
  </Box>
}