import { TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { CardLabel } from "../../common/components/cards";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { vi } from 'date-fns/locale'

export function DateLabelButton(props: any) {
  const {id, value, handleChange, label, required, placeholder, error, helperText} = props

  return <Box
    display='flex'
    flexDirection='column'
  >
    <CardLabel>
      {label}
      {(required ?? false) && <Typography
        component='span'
        display='inline'
        sx={{
          ml: 0.5,
        }}
        style={{
          color: 'rgba(255, 0, 0, 1)'
        }}
      >*</Typography>}
    </CardLabel>
    <LocalizationProvider 
      dateAdapter={AdapterDateFns}
      adapterLocale={vi}
    >
      <DatePicker
        openTo="year"
        views={['year', 'month', 'day']}
        label={label}
        value={value}
        onChange={handleChange}
        renderInput={(params) => <TextField 
          {...params} 
          id={id}
          name={id}
          error={error}
          helperText={helperText} 
          size='small'
          label={null}
          sx={{
            mt: 0.8
          }}
          inputProps={{
            ...params.inputProps,
            placeholder: 'Ngày/Tháng/Năm'
          }}
        />}
        
      />  
    </LocalizationProvider>
  </Box>
}