import { Box, Typography } from "@mui/material"
import { SnackbarContent, SnackbarKey, SnackbarMessage } from "notistack"
import { forwardRef } from "react"

export const SnackMessage = forwardRef<HTMLDivElement, { id: string | number, message: string | React.ReactNode }>((props: any, ref: any) => {
  return (
    <SnackbarContent ref={ref}>
      <Box
        sx={{
          width: 400,
          borderRadius: 2,
          bgcolor: '#fff',
          boxShadow: '0px 0px 4px 2px rgba(0,0,0,0.1)',
          p: 2
        }}
      >
        <Typography
          sx={{
            fontWeight: 500
          }}
        >
          {props.message}
        </Typography>
      </Box>
    </SnackbarContent>
  )
})