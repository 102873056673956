import DrawerHeader from "../../common/components/drawer.header";
import * as yup from 'yup'
import { useFormik } from "formik";
import { PersonalInfoCard } from "../components/personal_info.card";
import { WorkInfoCard } from "../components/work_info.card";
import { Box, Button, CircularProgress } from "@mui/material";
import { Container } from "@mui/system";
import { tokenAxios } from "../../common/axios";
import { newMemberEndpoint } from "../../common/endpoints";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { InfoDialog } from "../../common/components/info.dialog";
import { AxiosError } from "axios";
import { kEmptyData } from "../../common/util/constants";
import { RecoilRoot, useRecoilState } from "recoil";
import { MemberFormType, memberInfoState, wlocsState } from "../states/new_member.state";
import { CardTitle, HgCard, HgCardContent } from "../../common/components/cards";

export function NewMemberView() {
  const navigate = useNavigate()
  const [memberInfo, setMemberInfo] = useRecoilState(memberInfoState)
  const [wlocs, _] = useRecoilState(wlocsState)
  const [loading, setLoading] = useState(false)
  
  const formik = useFormik<MemberFormType>({
    initialValues: memberInfo,
    validationSchema: validationSchema,
    onSubmit: async (values) => {   
      setMemberInfo(values)
      setLoading(true)
      const data = {
        fullName: values.fullName,
        email: values.email,
        phone: values.phone,
        address: values.address,
        wardsId: values.wards!.id,
        wards: values.wards!.name,
        districtId: values.district!.id,
        district: values.district!.name,
        cityId: values.city!.id,
        city: values.city!.name,
        titleId: values.title!.id,
        birthday: values.birthday?.toUTCString(),
        joinedAt: values.joinedDate?.toUTCString(),
        departmentId: values.dept?.id,
        workingLocations: wlocs.map(e => ({
          cityId: e.city.id,
          districtId: e.district?.id
        }))
      }
      
      try {
        const resp = await tokenAxios.request({
          ...newMemberEndpoint,
          data: data
        })
        setContentInfo('Đã thêm thành viên!')
        setOpenInfoDialog(true)
        setData(resp.data)
        
      } catch (e: any) {
        setData(null)
        if(e instanceof AxiosError) {
          setContentInfo(e.message)  
        } else {
          setContentInfo(kEmptyData)
        }
        
        setOpenInfoDialog(true)
      }
    }
  })

  const [openInfoDialog, setOpenInfoDialog] = useState(false)
  const [contentInfo, setContentInfo] = useState('')
  const [data, setData] = useState<any | null>(null)

  const handleDialogClosed = () => {
    if(data != null) {
      navigate(`/employee/member/${data.id}`)
    }
  }

  return <RecoilRoot>
    <DrawerHeader/>
    <Container maxWidth='md'>
      <form onSubmit={formik.handleSubmit}>
        <HgCard>
          <HgCardContent>
            <CardTitle>
              Thông tin công việc
            </CardTitle>
            <PersonalInfoCard formik={formik}/>
          </HgCardContent>
        </HgCard>
        <HgCard sx={{mt: 2, mb: 2}}>
          <HgCardContent>
            <CardTitle>
              Thông tin công việc
            </CardTitle>
            <WorkInfoCard formik={formik}/>
          </HgCardContent>
        </HgCard>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mt: 3,
            mb: 10
          }}
        >
          <SaveButton loading={loading}/>
        </Box>
      </form>
    </Container>
    <InfoDialog
      open={openInfoDialog}
      setOpen={setOpenInfoDialog}
      content={contentInfo}
      onClose={handleDialogClosed}
    />
  </RecoilRoot>
}

const validationSchema = yup.object({
  fullName: yup
    .string()
    .min(3, 'Họ và tên phải ít nhất từ 3 ký tự trở lên')
    .required('Họ và tên là bắt buộc'),
  email: yup
    .string()
    .email('Email không hợp lệ')
    .required('Email là bắt buộc'),
  phone: yup
    .string()
    .min(9, 'Số điện thoại phải từ 9 số trở lên')
    .max(11, 'Số điện thoại phải nhỏ hơn 12 số')
    .required('Số điện thoại là bắt buộc'),
  address: yup
    .string()
    .notRequired(),  
  wards: yup
    .object()
    .nullable()
    .required('Thông tin Xã/Phường là bắt buộc'),
  district: yup
    .object()
    .nullable()
    .required('Thông tin Quận/Huyện là bắt buộc'),
  city: yup
    .object()
    .nullable()
    .required('Thông tin Tỉnh/Thành phố là bắt buộc'),
  gender: yup
    .object()
    .required('Giới tính là bắt buộc'),
  birthday: yup
    .date()
    .nullable()
    .notRequired(),
  joinedAt: yup
    .date()
    .nullable()
    .notRequired(),
  title: yup
    .object()
    .nullable()
    .required('Chức danh là bắt buộc'),
  dept: yup
    .object()
    .nullable()
    .notRequired(),
})

interface SaveButtonProp {
  loading: boolean
}

function SaveButton({loading}: SaveButtonProp) {
  return <Button
    disabled={loading}
    variant="contained"
    type='submit'
    sx={{
      width: 200,
      height: 50,
    }}
  >
    {loading ? <CircularProgress size={30}/> : 'Xác nhận'}
  </Button>
}