import { Box } from "@mui/material";
import React, {ErrorInfo} from "react";
import { NoConnIcon } from "../../icons";

interface ErrorState {
  error: Error | null
  errorInfo: ErrorInfo | null
}

type ErrorProps = {
  children: any
}

export class ErrorBoundary extends React.Component<ErrorProps, ErrorState> {
  constructor(props: any) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <Box
        sx={{
          width: 1,
          height: '60vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <NoConnIcon/>
        <h2>
          Có lỗi xảy ra. Vui lòng tải lại
        </h2>
      </Box>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}