import {AppBar, Box, Toolbar, Typography} from "@mui/material";
import {ReactComponent as Logo} from "./icon-logo-yellow.svg";

function HgAppBar(props: any) {
  return (
    <AppBar 
      elevation={0}
      sx={{
        bgcolor: '#fff',
        borderBottom: 0.5,
        borderColor: '#aeaeae'
      }}
      >
      <Toolbar disableGutters>
        <Logo style={{width: 60, height: 60}}/>
        {/* <Typography
          variant="h6"
          noWrap
          component="a"
          href="/login"
          sx={{
            ml: 0,
            display: { xs: 'none', md: 'flex' },
            fontWeight: 700,
            color: '#fea900',
            textDecoration: 'none',
          }}
        >
          Hoàng Gia Land
        </Typography> */}
        <Box sx={{flexGrow: 1}}/>
        <Box sx={{flexGlow: 0}}>
          <Typography
            variant="subtitle2"
            noWrap
            component="a"
            href="/policy"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontWeight: 400,
              color: '#fea900',
              textDecoration: 'underline',
            }}
          >
            Chính sách bảo mật
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default HgAppBar