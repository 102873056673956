import { Divider, Stack } from "@mui/material"
import { AxiosError } from "axios"
import { useSnackbar } from "notistack"
import { useState } from "react"
import { AllowedTo, useAbac } from "react-abac"
import { useNavigate } from "react-router-dom"
import { Permission } from "../../authz"
import { tokenAxios } from "../../common/axios"
import { ButtonItem } from "../../common/components/button_indicator"
import { ConfirmDialog } from "../../common/components/confirm.dialog"
import { InfoDialog } from "../../common/components/info.dialog"
import { Panel } from "../../common/components/panel"
import { deletePropertyEndpoint, pushPropertyEndpoint } from "../../common/endpoints"
import { urlWithId } from "../../common/util/common-utils"
import { kUnknownError } from "../../common/util/constants"
import { formatRemainingTime } from "../../common/util/format-utils"
import { setAlert } from "../../redux/alert.slice"
import { useAppDispatch } from "../../redux/hooks"
import { PropertyDescriptionDialog } from "./property_description.dialog"
import { PropertyImageDialog } from "./property_image.dialog"
import { PropertyInfoDialog } from "./property_info.dialog"
import { PropertyLocationDialog } from "./property_location.dialog"
import { PropertyStatusDialog } from "./property_status.dialog"

export function EditPropertyPanel(props: any) {
  const {property, sx} = props
  const abac = useAbac()

  const canPush = abac.userHasPermissions(
    Permission.PUSH_PROPERTY,
    property
  )

  const canEdit = abac.userHasPermissions(
    Permission.EDIT_PROPERTY,
    property
  )

  const canDelete = abac.userHasPermissions(
    Permission.DELETE_PROPERTY,
    property
  )

  const children: React.ReactNode[] = []
  if(canPush) {
    children.push(<PushProperty key={1} property={property}/>)
  }
  if(canEdit) {
    children.push([
      <EditStatus key={2} property={property} />,
      <EditLocation key={3} property={property}/>,
      <EditInfo key={4} property={property}/>,
      <EditDescription key={5} property={property}/>,
      <EditImage key={6} property={property}/>,
    ])
  }
  if(canDelete) {
    children.push(<DeleteProperty key={7} property={property}/>)
  }

  const canAny = canPush || canDelete || canEdit

  if(!canAny) {
    return <></>
  }

  return <Panel
    title='Chức năng'
    sx={[
      ...(Array.isArray(sx) ? sx : [sx])
    ]}
  >
    <Stack
      spacing={1}
      divider={<Divider/>}
      sx={{
        m: 1
      }}
    >
      {children}
    </Stack>
  </Panel>
}

function PushProperty(props: any) {
  const {property} = props
  const { enqueueSnackbar } = useSnackbar()

  let canPush = property.pushedAt == null
  let label = canPush ? 'Đẩy tin' : ''
  if(property.pushedAt != null) {
    const date = new Date(property.pushedAt)
    const now = new Date()
    const diff = (now.getTime() - date.getTime())/1000
    canPush = diff > 172800
    label = canPush ? 'Đẩy tin' : `Đẩy tin (${formatRemainingTime(diff)})`
  }

  const handleClick = async () => {
    try {
      const {url, ...config} = pushPropertyEndpoint
      await tokenAxios.request({
        url: urlWithId(url, `${property.id}`),
        ...config
      })
      enqueueSnackbar('Đã đẩy tin lên đầu danh sách tin')
    } catch(e) {

    }
  }

  return <ButtonItem
    label={label}
    onClick={handleClick}
    disabled={!canPush}
  />
}

function DeleteProperty(props: any) {
  const {property} = props
  const [open, setOpen] = useState(false)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const handleClick = () => {
    setOpen(true)
  }

  const onDialogClosed = async (positive: boolean) => {
    if(positive) {
      try {
        const {url, ...config} = deletePropertyEndpoint
        await tokenAxios.request({
          url: urlWithId(url, `${property.id}`),
          ...config
        })
        
        enqueueSnackbar('Đã xóa tin')
        navigate(-1)
      } catch (e) {
        const msg = e instanceof AxiosError ? e.message : kUnknownError
        dispatch(setAlert({open: true, content: msg}))
      }
    }
  }

  return <>
    <ButtonItem
      label='Xóa tin'
      onClick={handleClick}
    />
    <ConfirmDialog
      open={open}
      setOpen={setOpen}
      onClose={onDialogClosed}
      title='Xóa tin'
      content='Bạn có chắc chắn xóa tin này không?'
    />
  </>
}

function EditImage(props: any) {
  const {property} = props
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(true)
  }

  return <>
    <ButtonItem
      label='Sửa ảnh thực địa'
      onClick={handleClick}
    />
    <PropertyImageDialog 
      property={property}
      open={open}
      setOpen={setOpen}
    />
  </>
}

function EditDescription(props: any) {
  const {property} = props
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(true)
  }

  return <>
    <ButtonItem
      label='Sửa mô tả BĐS'
      onClick={handleClick}
    />
    <PropertyDescriptionDialog 
      property={property}
      open={open}
      setOpen={setOpen}
    />
  </>
}

function EditInfo(props: any) {
  const {property} = props
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(true)
  }

  return <>
    <ButtonItem
      label='Sửa thông tin BĐS'
      onClick={handleClick}
    />
    <PropertyInfoDialog 
      property={property}
      open={open}
      setOpen={setOpen}
    />
  </>
}

function EditLocation(props: any) {
  const {property} = props
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(true)
  }

  return <>
    <ButtonItem
      label='Sửa địa chỉ'
      onClick={handleClick}
    />
    <PropertyLocationDialog 
      property={property}
      open={open}
      setOpen={setOpen}
    />
  </>
}

function EditStatus(props: any) {
  const {property} = props
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(true)
  }

  return <>
    <ButtonItem
      label='Thay đổi trạng thái'
      onClick={handleClick}
    />
    <PropertyStatusDialog 
      property={property}
      open={open}
      setOpen={setOpen}
    />
  </>
}