import { Box, Button, Dialog, DialogContent} from '@mui/material'
import { AxiosError } from 'axios'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import {  useRecoilRefresher_UNSTABLE, useSetRecoilState } from 'recoil'
import * as yup from 'yup'
import { tokenAxios } from '../../common/axios'
import { DialogTitleClose } from '../../common/components/title_close.dialog'
import { propertyLocationEndpoint } from '../../common/endpoints'
import { urlWithId } from '../../common/util/common-utils'
import { kUnknownError } from '../../common/util/constants'
import { setAlert } from '../../redux/alert.slice'
import { useAppDispatch } from '../../redux/hooks'
import { propertyDetailState } from '../state/property_detail.state'
import { LocationCard } from './location.card'

export function PropertyLocationDialog(props: any) {
  const { open, setOpen, property} = props
  const refreshDetail = useRecoilRefresher_UNSTABLE(propertyDetailState(`${property.id}`))
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const formik = useFormik({
    initialValues: {
      address: property.address,
      address1: property.address1,
      address2: property.address2,
      wards: {
        id: property.wardsId,
        name: property.wards
      },
      district: {
        id: property.districtId,
        name: property.district
      },
      city: {
        id: property.cityId,
        name: property.city
      },
      latlng: null,
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (formData) => {
      try {
        const {url, ...config} = propertyLocationEndpoint
        await tokenAxios.request({
          url: urlWithId(url, `${property.id}`),
          ...config,
          data: {
            address: formData.address,
            address1: formData.address1,
            address2: formData.address2,
            wardsId: formData.wards.id,
            wards: formData.wards.name,
            districtId: formData.district.id,
            district: formData.district.name,
            cityId: formData.city.id,
            city: formData.city.name,
            //@ts-ignore
            latitude: formData.latlng?.lat,
            //@ts-ignore
            longitude: formData.latlng?.lng,
          }
        })
        setOpen(false)
        enqueueSnackbar('Đã cập nhật thông tin vị trí')
        setTimeout(() => {
          refreshDetail()
        }, 300)
      } catch(e) {
        const msg = e instanceof AxiosError ? e.message : kUnknownError
        dispatch(setAlert({open: true, content: msg}))
      }
    }
  })
  
  const handleClose = () => {
    formik.resetForm()
    setOpen(false)
  }

  return <Dialog
    maxWidth='md'
    open={open}
  >
    <DialogContent>
      <DialogTitleClose
        title='Sửa thông tin vị trí'
        onClose={handleClose}
      />
    <form onSubmit={formik.handleSubmit}>
      <LocationCard formik={formik}/>
      <Box
        sx={{
          width: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mt: 2,
        }}
      >
        <Button
            variant="contained"
            type='submit'
            sx={{
              width: 200
            }}
          >
            Xác nhận
          </Button>
      </Box>
    </form>
    </DialogContent>
  </Dialog>
}

const validationSchema = yup.object({
  address: yup.string().required('Địa chỉ thực tế là bắt buộc'),
  address1: yup.string().required('Địa chỉ trên sổ là bắt buộc'),
  address2: yup.string().notRequired(),
  wards: yup
    .object()
    .nullable()
    .required('Thông tin Xã/Phường là bắt buộc'),
  district: yup
    .object()
    .nullable()
    .required('Thông tin Quận/Huyện là bắt buộc'),
  city: yup
    .object()
    .nullable()
    .required('Thông tin Tỉnh/Thành phố là bắt buộc'),
  latlng: yup.object().nullable().notRequired(),
})