import { Box, Drawer, List, ListItemButton, ListItemIcon } from "@mui/material";
import React from "react";
import { AllowedTo } from "react-abac";
import { NavLink, useLocation, useSearchParams } from "react-router-dom";
import { Permission } from "../../authz";
import useAppBarHeight from "../../common/hooks/use_appbar_height";
import { FavIcon, HomeIcon, LogoYellowIcon, MemberIcon, ReportIcon, SettingIcon, StarIcon, StoreIcon } from "../../icons";

export function Shortcut() {
  const selectedId = useShortcutId()

  return selectedId == 0 ? null : <Drawer
    variant="permanent"
    sx={{
      // display: {xs: 'none', sm: 'block', md: 'block', lg: 'block'}
      width: 60,
    }}
    style={{
      zIndex: 100,
    }}
  >
    <LogoYellowIcon width={60} height={60}/>
    <List>
      {tabs.map((tab, index) => (
        <AllowedTo
          key={index}
          perform={tab.permission}
        >
          <NavLink to={tab.route}>
            <ShortCutItem
              label={tab.label}
              icon={tab.icon}
              selected={selectedId == tab.id}
            />
          </NavLink>
        </AllowedTo>
      ))}
    </List>
  </Drawer>
}

interface ShortcutItemProps {
  label: string
  selected: boolean
  icon: JSX.Element
}

function ShortCutItem({label, icon, selected}: ShortcutItemProps) {
  return <ListItemButton
    key={label}
    sx={{
      minHeight: 60,
      justifyContent: 'center',
    }}
    selected={selected}
  >
    <ListItemIcon
      sx={{
        minWidth: 0,
        justifyContent: 'center',
      }}
    >
      {icon}
    </ListItemIcon>
  </ListItemButton>
}

export function useShortcutId() {
  const location = useLocation()
  const [searchParam] = useSearchParams()

  let id: number | undefined
  if(location.pathname.startsWith('/properties')) {
    if(searchParam.get('fav-only')) {
      id = 2
    } else {
      id = 3
    }
  } else if(location.pathname.startsWith('/employee/members')) {
    id = 4
  } else if(location.pathname.startsWith('/employee/depts')) {
    id = 5
  } else if(location.pathname.startsWith('/setting')) {
    id = 6
  } else if(location.pathname === '/') {
    id = 0
  }

  return id
}

const tabs = [
  {
    id: 0,
    label: 'Trang chủ',
    route: '/',
    icon: <HomeIcon />,
    permission: Permission.VIEW_HOME
  },

  {
    id: 1,
    label: 'Báo cáo',
    route: 'reports',
    icon: <ReportIcon/>,
    permission: Permission.VIEW_REPORT
  },

  {
    id: 2,
    label: 'Yêu thích',
    route: 'properties?fav-only=true',
    icon: <FavIcon/>,
    permission: Permission.VIEW_FAVORITE,
  },

  {
    id: 3,
    label: 'Kho',
    route: 'properties',
    icon: <StoreIcon/>,
    permission: Permission.VIEW_STORE
  },

  {
    id: 4,
    label: 'Danh sách nhân viên',
    route: '/employee/members',
    icon: <StarIcon/>,
    permission: Permission.VIEW_MEMBER
  },

  {
    id: 5,
    label: 'Danh sách khối',
    route: '/employee/depts',
    icon: <MemberIcon/>,
    permission: Permission.VIEW_DEPT
  },
  {
    id: 6,
    label: 'Thiết lập',
    route: '/setting',
    icon: <SettingIcon/>,
    permission: Permission.VIEW_SETTING,
  },
]