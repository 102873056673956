import { Box, Dialog, DialogContent } from '@mui/material'
import { AxiosError } from 'axios'
import { useFormik } from 'formik'
import { m } from 'framer-motion'
import { useSnackbar } from 'notistack'
import { useRecoilRefresher_UNSTABLE } from 'recoil'
import * as yup from 'yup'
import { simpleAxios, tokenAxios } from '../../common/axios'
import { DialogActionConfirm } from '../../common/components/confirm_action.dialog'
import { DialogTitleClose } from '../../common/components/title_close.dialog'
import { newPresignedUrlEndpoint, propertyInfoEndpoint } from '../../common/endpoints'
import { MediaAsset } from '../../common/types'
import { fileReader, imageUrlFromUrl, urlWithId } from '../../common/util/common-utils'
import { kUnknownError } from '../../common/util/constants'
import { setAlert } from '../../redux/alert.slice'
import { useAppDispatch } from '../../redux/hooks'
import { propertyDetailState } from '../state/property_detail.state'
import { InfoContent } from './info.content'

export function PropertyInfoDialog(props: any) {
  const { open, setOpen, property} = props
  const refreshDetail = useRecoilRefresher_UNSTABLE(propertyDetailState(`${property.id}`))
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const formik = useFormik({
    initialValues: {
      area: property.acreage,
      area1: property.acreage1,
      frontWidth: property.surfaceWide,
      roadWidth: property.roadWide,
      price: property.price,
      legalType: {
        value: property.legalType,
        description: property.legalName,
      },
      furnitureType: {
        value: property.furnitureType,
        description: property.furnitureName
      },
      legalDescription: property.legalDescription,
      furnitureDescription: property.furnitureDescription,
      numFloor: property.numFloor,
      numBed: property.numBed,
      numToilet: property.numToilet,
      legalImages: property.legalImageUrls
    },
    validationSchema: validationSchema,
    onSubmit: async (formData) => {
      try {
        let images = formData.legalImages
        let assets: MediaAsset[] = []
        for(let i = 0; i < images.length; i++) {
          if(images[i] instanceof File) {
            assets.push({
              name: `${property.id}-${i}-${(new Date()).getTime()}`,
              file: images[i],
              category: 'houseCert',
              contentType: 'image/jpeg'
            } as MediaAsset)
          }
        }
        const uploadedImageUrls: string[] = []
        if(assets.length > 0) {
          const resp = await tokenAxios.request({
            ...newPresignedUrlEndpoint,
            data: assets.map(e => ({
              fileName: e.name,
              contentType: e.contentType,
              category: e.category
            }))
          })
          const presignedUrls = resp.data

          for(const asset of assets) {
            const rawUrl = presignedUrls[asset.name]
            const url = new URL(rawUrl)   
            try {
              const binary = await fileReader(asset.file)
              await simpleAxios.put(rawUrl, binary, {
                headers: {
                  AWSAccessKeyId : url.searchParams.get('AWSAccessKeyId') ?? '',
                  Type: url.searchParams.get('Type') ?? '',
                  Expires: url.searchParams.get('Expires') ?? 0,
                  Signature: url.searchParams.get('Signature') ?? '',
                  'x-amz-acl': url.searchParams.get('x-amz-acl') ?? '',
                  'Content-Type': asset.contentType
                },
              })
            } catch(_) {}
            
            let imageUrl = imageUrlFromUrl(url)
            uploadedImageUrls.push(imageUrl)
          }
        }

        let imageUrls: string[] = []
        for(const image of images) {
          if(image instanceof File) {
            const url = uploadedImageUrls.shift()
            if(url != undefined) {
              imageUrls.push(url)
            }
          } else {
            imageUrls.push(image)
          }
        }

        const {url, ...config} = propertyInfoEndpoint
        await tokenAxios.request({
          url: urlWithId(url, `${property.id}`),
          ...config,
          data: {
            price: parseInt(formData.price),
            acreage: parseFloat(formData.area),
            acreage1: parseFloat(formData.area1),
            numFloor: parseInt(formData.numFloor),
            numBed: parseInt(formData.numBed),
            numToilet: parseInt(formData.numToilet),
            roadWide: formData.roadWidth && parseFloat(formData.roadWidth),
            surfaceWide: formData.frontWidth && parseFloat(formData.frontWidth),
            legalType: formData.legalType.value,
            legalName: formData.legalType.description,
            furnitureType: formData.furnitureType.value,
            furnitureName: formData.furnitureType.description,
            legalDescription: formData.legalDescription,
            furnitureDescription: formData.furnitureDescription,
            legalImageUrls: imageUrls
          }
        })
        setOpen(false)
        enqueueSnackbar('Đã cập nhật thông tin BĐS')
        setTimeout(() => {
          refreshDetail()
        }, 300)
      } catch(e) {
        const msg = e instanceof AxiosError ? e.message : kUnknownError
        dispatch(setAlert({open: true, content: msg}))
      }
    }
  })

  const handleClose = () => {
    formik.resetForm()
    setOpen(false)
  }

  return <Dialog
    maxWidth='md'
    open={open}
  >
    <DialogContent>
      <DialogTitleClose
        title='Sửa thông tin cơ bản BĐS'
        onClose={handleClose}
      />
      <form onSubmit={formik.handleSubmit}>
      <Box
        sx={{
          maxHeight: 'calc(90vh - 180px)',
          display: 'flex',
          flexDirection: 'column',
          overflow: "auto",
        }}
      >
        <InfoContent formik={formik} fromDialog={true}/>
      </Box>
      <DialogActionConfirm/>
    </form>
      </DialogContent>
  </Dialog>
}

const validationSchema = yup.object({
  area: yup.number().required('Diện tích thực tế là bắt buộc'),
  area1: yup.number().required('Diện tích trên sổ là bắt buộc'),
  frontWidth: yup.number().nullable().notRequired(),
  roadWidth: yup.number().nullable().notRequired(),
  price: yup.number().required('Mức giá là bắt buộc'),
  legalType: yup.object().required(),
  furnitureType: yup.object().required(),
  legalDescription: yup.string().notRequired(),
  furnitureDescription: yup.string().notRequired(),
  numFloor: yup.number().required(),
  numBed: yup.number().required(),
  numToilet: yup.number().required(),
  legalImaegs: yup.array().notRequired()
})